import React, { useState, useEffect, useContext } from "react";
import { HotelIdContext } from "../../settings/Store";
import fetchNotificationPopup from "./fetchNotificationPopup.js";
const NotificationPopup = () => {
  const [hotelId] = useContext(HotelIdContext);
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState();
  useEffect(() => {
    if (hotelId != 0) {
      fetchNotificationPopup(hotelId, setPopupOpen, setPopupContent);
    }
  }, [hotelId]);

  return (
    <div>
      {popupOpen && (
        <div className="modal-wrapper">
          <div
            id="model1"
            className="modal scroll z-value notification-modal-size"
          >
            <div className="card" style={{ margin: "0rem" }}>
              <div className="modal-content">
                <span
                  onClick={() => setPopupOpen(false)}
                  className="modalBtnClose promotionCloseBtn"
                />
                <div className="row notification--popup">
                  <div dangerouslySetInnerHTML={{ __html: popupContent }} />
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-overlay"
            style={{
              zIndex: "1002",
              display: "block",
              opacity: "0.2",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default NotificationPopup;
