import React, { useState, useContext, useEffect } from "react";

import fetchUserInfo from "./fetchUserInfo.js";
import { CompanyContext, HotelIdContext, CurrencySymbolContext, ApiKeyContext, CurrencyContext, GuestPermissionContext } from "../../settings/Store";
import cancelBookingRequest from "./cancelBookingRequest";

import cancelBooking from "./cancelBooking";
import moment from "moment";

import Lottie from "react-lottie";
import animationData from "./../../json/icon-animation.json";

import "react-dates/initialize";
import { DateRangePicker } from "react-dates";

import Responsive from "react-responsive";
import getCouponsForModify from "././ModifyBooking/getCouponsForModify";
import getInventorynRatesForModify from "././ModifyBooking/getInventorynRatesForModify";
// import getTaxRulesForModify from "././ModifyBooking/getTaxRulesForModify";
import modifyBookRequest from "././ModifyBooking/modifyBookRequest";
import fetchHotelInfo from "././ModifyBooking/fetchHotelInfo";
import getCancellationPolicy from "../User/getCancellationPolicy";
import getLocaleDetailsForModify from "./ModifyBooking/getLocaleDetailsForModify.js";

export const Mobile = (props) => <Responsive {...props} maxWidth={767} />;
export const Default = (props) => <Responsive {...props} minWidth={768} />;
const ManageBookings = () => {
  const [compState] = useContext(CompanyContext);

  const [hotelId] = useContext(HotelIdContext);
  const [userData, setUserData] = useState([]);
  const [apiKey] = useContext(ApiKeyContext);

  const [guestPermission] = useContext(GuestPermissionContext);

  let booking_status;

  const [currencySymbol] = useContext(CurrencySymbolContext);
  const [currency] = useContext(CurrencyContext);
  const [displayLoader, setDisplayLoader] = useState(true);
  const [showMessage, setShowMessage] = useState("");
  let current_year = new Date().getFullYear();
  let date_tday = new Date();
  let current_month = ("0" + (date_tday.getMonth() + 1)).slice(-2);

  const [monthFilter, setMonthFilter] = useState(current_month);
  const [yearFilter, setYearFilter] = useState(current_year);

  const [filteredValue, setFilteredValue] = useState(
    "01-" + current_month + "-" + current_year
  );
  const [lastPage, setLastPage] = useState(0);

  const [page, setPage] = useState(1);
  const [buttonArray, setButtonArray] = useState([1]);

  const [callUserDataAfterCancel, changeCallUserDataAfterCancel] = useState(
    false
  );

  const [focusedInput, setFocusedInput] = useState(null);
  const [modifyGuestFirstName, setModifyGuestFirstName] = useState();
  const [modifyGuestLastName, setModifyGuestLastName] = useState();
  const [datesSelected, setDatesSelected] = useState({
    startDate: moment(),
    endDate: moment().add(1, "days"),
  });
  const [inventoryForModify, setInventoryForModify] = useState();
  // const [taxRulesForModify, setTaxRulesForModify] = useState();

  const [overSeaFilter, setOverSeaFilter] = useState();

  const [isTaxable, setIsTaxable] = useState();


  const [callUserAPI, setCallUserAPI] = useState(false);
  //fetch hotel details
  useEffect(() => {
    if (hotelId != "" && apiKey != 0) {
      fetchHotelInfo(hotelId, apiKey, setOverSeaFilter, setIsTaxable);
    }
  }, [hotelId, apiKey]);

  const [localeDetails, setLocaleDetails] = useState();
  useEffect(() => {
    if (hotelId != "" && apiKey != 0) {
      getLocaleDetailsForModify(hotelId, setLocaleDetails);
    }
  }, [hotelId, apiKey]);



  //fetch bookings details
  useEffect(() => {
    setDisplayLoader(true);
    setUserData([]);
    setButtonArray([]);
    if (hotelId != 0) {
      fetchUserInfo(
        hotelId,
        filteredValue,
        setUserData,
        setLastPage,
        page,
        setButtonArray,
        setDisplayLoader,
        compState
      );
    }
  }, [
    hotelId,
    setUserData,
    filteredValue,
    page,
    confirmBookingCancel,
    callUserDataAfterCancel,
    compState,
    callUserAPI
  ]);

  const viewInvoice = (invoice) => {
    let printContents, popupWin;
    printContents = invoice;
    popupWin = window.open(
      "",
      "_blank",
      "top=100,left=100,height=100%,width=auto"
    );
    popupWin.document.open();
    popupWin.document.write(`
        <html>
            <head>
            <title>Print tab</title>
            <style>
            //........Customized style.......
            </style>
            </head>
        <body onload="window.print();window.close()">${printContents}</body>
        </html>`);
    popupWin.document.close();
  };

  const [openCancelBookingModal, setOpenCancelBookingModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displayCancelData, setDisplayCancelData] = useState({});
  const [displayDataOfCancelBooking, setDisplayDataOfCancelBooking] = useState(
    false
  );
  const [openModalForZeroRefund, setOpenModalZeroRefund] = useState(false);
  const [confirmCancelMessage, setConfirmCancelMessage] = useState("");
  const [confirmCancelIcon, setConfirmCancelIcon] = useState("");
  const [cancelLoader, setCancelLoader] = useState();

  const [openModifyBookingModal, setOpenModifyBookingModal] = useState(false);
  const [modifyBookingData, setModifyBookingData] = useState();
  const [availabilityModal, setAvailablityModal] = useState(false);
  const [availabilityLoader, setAvailablityLoader] = useState(false);
  const [otherTaxArray, setOtherTaxArray] = useState([]);
  const [otherTaxTotal, setOtherTaxTotal] = useState(0);

  const [modifyCart, setModifyCart] = useState([]);
  const [totalAmountPaid, setTotalAmountPaid] = useState();
  const [couponsForModify, setCouponsForModify] = useState();
  const [totalAmountToPay, setTotalAmountToPay] = useState();
  const [finalPrice, setFinalPrice] = useState({ message: "", price: 0, status: '' });
  const [modifyBookingLoader, setModifyBookingLoader] = useState(false)

  const [cancelBookingLoader, setCancelBookingLoader] = useState(false)


  const [updateUserDetails, setUpdateUserDetails] = useState({ status: '', message: '', class: '' });

  const [cancellationPolicy, setCancellationPolicy] = useState([]);
  const [note, setNote] = useState();
  const [openCancellationPolicyModal, setOpenCancellationPolicyModal] = useState(false)

  const requestCancelBooking = (canceluserData, status) => {
    if (status === true) {
      setLoading(true);
      if (hotelId != 0) {
        cancelBookingRequest(
          canceluserData,
          setDisplayCancelData,
          setOpenCancelBookingModal,
          setLoading
        );
      }
      setDisplayDataOfCancelBooking(true);
    }
  };

  const closeModal = () => {
    setDisplayDataOfCancelBooking(false);
    setOpenModalZeroRefund(false);
    setOpenCancelBookingModal(false);

    setConfirmCancelMessage("");
  };

  const confirmationForZeroRefund = (data) => {
    if (data.refund_amount === 0) {
      setDisplayDataOfCancelBooking(false);
      setOpenModalZeroRefund(true);
    } else {
      setCancelBookingLoader(true);
      cancelBooking(
        data.booking_id,
        setDisplayDataOfCancelBooking,
        setOpenModalZeroRefund,
        setConfirmCancelMessage,
        setConfirmCancelIcon,
        changeCallUserDataAfterCancel,

        setCancelBookingLoader

      );
    }
  };

  // Changes for OTDC for Cancel Booking
  const callCancelBookingFunction = (data) => {
    setCancelBookingLoader(true);
    cancelBooking(
      data.booking_id,
      setDisplayDataOfCancelBooking,
      setOpenModalZeroRefund,
      setConfirmCancelMessage,
      setConfirmCancelIcon,
      changeCallUserDataAfterCancel,

      setCancelBookingLoader

    );
  };
  // Changes for OTDC for Cancel Booking

  const closeModalForZeroRefund = () => {
    setDisplayDataOfCancelBooking(false);
    setOpenModalZeroRefund(false);
    setOpenCancelBookingModal(false);
  };

  let current_date = moment(moment()).format("YYYY-MM-DD");

  const changeMonth = (e) => {
    setPage(1);
    setMonthFilter(e);
    setFilteredValue("01-" + e + "-" + yearFilter);
  };

  const changeYear = (e) => {
    setPage(1);
    setYearFilter(e);
    setFilteredValue("01-" + monthFilter + "-" + e);
  };

  const previousButton = () => {
    if (page !== 1) {
      setPage(page - 1);
    }
    window.scrollTo(0, 0);
  };

  const nextButton = () => {
    if (page !== lastPage) {
      setPage(page + 1);
    }
    window.scrollTo(0, 0);
  };

  let year_array = [current_year];

  for (let i = 1; i < 15; i++) {
    year_array.unshift(current_year - i);
    year_array.push(current_year + i);
  }

  const confirmBookingCancel = (data) => {
    setCancelBookingLoader(true);
    cancelBooking(
      data.booking_id,
      setDisplayDataOfCancelBooking,
      setOpenModalZeroRefund,
      setConfirmCancelMessage,
      setConfirmCancelIcon,
      changeCallUserDataAfterCancel,

      setCancelBookingLoader

    );
  };

  const closeConfirmCancelPopup = () => {
    setOpenCancelBookingModal(false);
    setConfirmCancelMessage("");
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };


  const modifyBookingFunctionCall = (id, status) => {
    if (status === true) {
      setModifyCart([]);

      let start_date = new Date(userData[id].check_in);
      let end_date = new Date(userData[id].check_out);

      setDatesSelected({
        startDate: moment(start_date),
        endDate: moment(end_date)
      })

      setModifyGuestFirstName(userData[id].first_name);
      setModifyGuestLastName(userData[id].last_name);
      setOpenModifyBookingModal(true);

      setModifyBookingData([userData[id]])
      setTotalAmountToPay(userData[id].total_amount);
    }

  }

  useEffect(() => {
    if (modifyBookingData && modifyBookingData.length > 0 && totalAmountToPay) {
      let paid_amount = parseInt(modifyBookingData[0].paid_amount);
      let to_pay = parseInt(totalAmountToPay);

      if (paid_amount === to_pay) {
        setFinalPrice({ message: 'Proceed', price: '', status: true });
      }
      else if (paid_amount > to_pay) {
        let price = paid_amount - to_pay;
        setFinalPrice({ message: "Refund", price: parseFloat(price).toLocaleString('en-IN'), status: false })
      }
      else if (paid_amount < to_pay) {
        let price = to_pay - paid_amount;
        setFinalPrice({ message: "Pay", price: parseFloat(price).toLocaleString('en-IN'), status: false })
      }

    }
  }, [modifyBookingData, totalAmountToPay, setTotalAmountToPay])


  const newDateRange = (startDate, endDate) => {
    if (endDate === null) {
      endDate = moment(startDate).add(1, "days");
    }
    setDatesSelected({ startDate, endDate });
  };

  const totalNoOfNights = (dateRange) => {
    let date1 = new Date(dateRange.startDate);
    let date2 = new Date(dateRange.endDate);
    let timeDiff = Math.abs(date2.getTime() - date1.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays;
  };

  let no_nights = totalNoOfNights(datesSelected);

  const checkAvailablility = () => {
    // no_nights = totalNoOfNights(datesSelected);
    setAvailablityLoader(true);
    setShowMessage("");
    if (no_nights < 15 && no_nights > 0) {
      if (hotelId != 0 && currency != "" && apiKey != "") {
        getInventorynRatesForModify(
          hotelId,
          datesSelected,
          currency,
          apiKey,
          modifyBookingData,
          setAvailablityModal,
          setAvailablityLoader,
          setInventoryForModify
        );
      }

      // if (hotelId != 0 && compState != 0) {
      //   getTaxRulesForModify(compState, hotelId, setTaxRulesForModify);
      // }

      if (hotelId != 0) {
        getCouponsForModify(hotelId, setCouponsForModify, datesSelected, modifyBookingData);
      }

    } else {
      setAvailablityLoader(false);
      setShowMessage("Date range should be 15 nights!");
    }


  }

  const handleFirstNameChange = (e) => {
    setModifyGuestFirstName(e);
  };


  const handleLastNameChange = (e) => {
    setModifyGuestLastName(e);
  };

  useEffect(() => {
    if (modifyBookingData && modifyBookingData.length > 0 && inventoryForModify && inventoryForModify.length > 0) {
      let cart = [];
      inventoryForModify.map((inv, idx) => {
        let cartItem = {
          room_type: "",
          plan_type: "",
          room_type_id: 0,
          price: 0,
          rooms: [],
          tax: [],
          discounted_price: 0,
          paid_amount: 0,
          paid_amount_per: 0,

          rates_for_coupons: [],
        }; //Initialises  every time to avoid the object overwrite issue

        cartItem["room_type"] = inv.room_type;
        cartItem["room_type_id"] = inv.room_type_id;


        cartItem["appliedCoupons"] = inv.coupons;

        cartItem["added_to_cart"] = true;
        cartItem["add_room"] = false;
        cartItem["rate_plan_id"] = modifyBookingData[0].booking_object[idx].rate_plan_id;
        cartItem["max_room_capacity"] = inv.max_room_capacity;
        cartItem["max_child"] = inv.max_child;
        cartItem["max_people"] = inv.max_people;
        cartItem["extra_person"] = inv.extra_person;
        inv.rate_plans &&
          inv.rate_plans.map((rate_plan) => {
            if (modifyBookingData[0].booking_object[idx].rate_plan_id == rate_plan.rate_plan_id) {
              cartItem["plan_type"] = rate_plan.plan_type;
              cartItem["plan_name"] = rate_plan.plan_name;
              let no_of_rooms = parseInt(modifyBookingData[0].booking_object[idx].no_of_rooms)
              for (let i = 0; i < no_of_rooms; i++) {
                cartItem["rooms"][i] = {
                  room: "Room" + (i + 1),
                  selected_adult: modifyBookingData[0].adult[i],
                  selected_child: modifyBookingData[0].child[i],
                  rate_plan_id: modifyBookingData[0].booking_object[idx].rate_plan_id,
                  bar_price: getRoomPrice(
                    rate_plan.rate_plan_id,
                    idx
                  ),
                  extra_adult_price: 0,
                  extra_child_price: 0,
                  selected_infant: 0,
                };
              }
              //MyCode
              cartItem["rates_for_coupons"] = rate_plan.rates;
              //MyCode
            }
          });
        cart.push(cartItem);
      });

      let cart_with_extra_adult_child = extraAdultChildCalculation(cart)
      let cart_with_discount_price = couponPriceCalculation(cart_with_extra_adult_child);
      let cart_with_tax_price = gstPriceCalculation(cart_with_discount_price);

      // setOtherTaxTotal(
      //   otherTaxArray.length > 0
      //     ? parseFloat(
      //       otherTaxArray
      //         .reduce((tax_amount, tax_obect) => {
      //           return (tax_amount += tax_obect.tax_amount);
      //         }, 0)
      //         .toFixed(2)
      //     )
      //     : 0
      // );

      calculateTotalPay(cart_with_tax_price, modifyBookingData[0]);
    }
    // }, [modifyBookingData, inventoryForModify])
  }, [inventoryForModify])


  const getRoomPrice = (rate_plan_id, index) => {
    let curPrice = 0;
    inventoryForModify[index].rate_plans &&
      inventoryForModify[index].rate_plans.map((rate_plan) => {
        if (rate_plan_id == rate_plan.rate_plan_id) {
          rate_plan.rates &&
            rate_plan.rates.map((rate) => {
              curPrice += parseFloat(rate.bar_price);
            });
        }
      });

    return curPrice;
  };





  const extraAdultChildCalculation = (cartItems) => {
    let cart = [];
    if (cartItems.length > 0) {
      cartItems.map((cartItem, idx) => {
        let max_child = cartItem.max_child;
        let extra_adult_price = 0;
        let extra_child_price = 0;
        cartItem.rooms.map((room) => {
          if (cartItem.max_people !== 0 && room.selected_adult == cartItem.max_people) {
            max_child = 0
          }

          if (room.selected_adult > cartItem.max_people) {
            extra_adult_price = 0;
            let no_of_extra_adults = room.selected_adult - cartItem.max_people;
            extra_adult_price = no_of_extra_adults * inventoryForModify[idx].extra_adult_price;
            room.extra_adult_price = extra_adult_price
          }

          if (room.selected_child > max_child) {
            let no_of_extra_child = room.selected_child - max_child;
            extra_child_price = no_of_extra_child * inventoryForModify[idx].extra_child_price;
            room.extra_child_price = extra_child_price;
          }
          cartItem.price += room.bar_price
        });
        cartItem.display_price = cartItem.price + extra_adult_price + extra_child_price;
        cart.push(cartItem);
      });
    }
    return cart;
  }


  const couponPriceCalculation = (cartItems) => {
    let cart = [];
    cartItems &&
      cartItems.map((cartItem, idx) => {
        let applied_coupons = [];
        let appliedDates = [];
        let from_date = moment(datesSelected.startDate).format("YYYY-MM-DD");
        let to_date = moment(datesSelected.endDate).format("YYYY-MM-DD");
        let end_date = moment(to_date)
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        let dateArray = getDateArray(from_date, end_date);



        // let coupon_start_date = moment(cartItem.appliedCoupons[0].valid_from).format("YYYY-MM-DD");
        // let coupon_end_date = moment(cartItem.appliedCoupons[0].valid_to)
        //   .subtract(1, "days")
        //   .format("YYYY-MM-DD");
        // let couponArray = getDateArray(coupon_start_date, coupon_end_date);


        let discountPrice = 0;
        dateArray &&
          dateArray.map((value, index) => {
            couponsForModify &&
              couponsForModify.length > 0 &&
              couponsForModify.map((coupon, index) => {
                if (
                  value == coupon.date &&
                  cartItem.room_type_id === coupon.room_type_id
                ) {
                  discountPrice += calculateDiscountPrice(
                    value,
                    cartItem.rate_plan_id,
                    coupon,
                    cartItem.rooms,
                    idx
                  );
                  applied_coupons.push(coupon);
                  appliedDates.push(value);
                }
              })
          })


        dateArray &&
          dateArray.map((value, index) => {
            couponsForModify &&
              couponsForModify.length > 0 &&
              couponsForModify.map((coupon, index) => {
                if (
                  value == coupon.date &&
                  coupon.room_type_id === 0 &&
                  !appliedDates.includes(coupon.date)
                ) {
                  discountPrice += calculateDiscountPrice(
                    value,
                    cartItem.rate_plan_id,
                    coupon,
                    cartItem.rooms,
                    idx
                  );
                  applied_coupons.push(coupon);
                  appliedDates.push(value);
                }
              });
          });

        cartItem.appliedCoupons = applied_coupons
        cartItem.discounted_price = discountPrice;
        cart.push(cartItem);
      })
    return cart;

  }



  const getDateArray = (startDate, stopDate) => {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
      currentDate = moment(currentDate).add(1, "days");
    }
    return dateArray;
  };


  const calculateDiscountPrice = (
    date,
    rate_plan_id,
    coupon,
    rooms,
    idx
  ) => {
    var dsprice;

    inventoryForModify[idx].rate_plans.map((ratesplans, key) => {
      if (ratesplans.rate_plan_id == rate_plan_id) {
        if (ratesplans.rates) {
          ratesplans.rates.map((rates, key) => {
            if (rates.date == date) {
              var calculated_dsprice =
                (rates.bar_price * coupon.discount) / 100;
              dsprice = calculated_dsprice * rooms.length;
            }
          });
        }
      }
    });
    return dsprice;
  };





  const gstPriceCalculation = (cartItems) => {
    let cart = [];
    cartItems &&
      cartItems.map((cartItem, idx) => {

        let room_price = 0;
        let extra_adult_price = 0;
        let extra_child_price = 0;

        cartItem.rooms.map((room) => {
          extra_adult_price += room.extra_adult_price;
          extra_child_price += room.extra_child_price;
          room_price += parseFloat(room.bar_price);
        });

        let cart_with_tax = updateGstPrice(
          cartItem,
          cartItem.rooms.length,
          cartItem.room_type_id,
          cartItem.rate_plan_id,
          room_price +
          extra_adult_price +
          extra_child_price -
          cartItem.discounted_price,
          room_price + extra_adult_price + extra_child_price,
          room_price - cartItem.discounted_price
        );
        cart.push(cart_with_tax);
      })

    // otherTaxCalculation(cartItems);
    return cart;
  }


  const updateGstPrice = (cartItem, no_of_rooms, room_type_id, rate_plan_id, room_price, price_before_discount, price_for_individual) => {
    cartItem.tax.push({
      // gst_price:
      //   taxRulesForModify.tax_type == "GST"
      //     ? getGstPrice(no_of_rooms, room_price, price_before_discount)
      //     : 0,
      // other_tax:
      //   taxRulesForModify.tax_type == "NonGST"
      //     ? getOtherPrice(no_of_rooms, room_type_id, room_price)
      //     : [],


      gst_price: getGstPrice(no_of_rooms, room_price, price_before_discount, price_for_individual),
      other_tax: [],
    });





    return cartItem;
  };

  const getGstPrice = (no_of_rooms, price, price_before_discount, price_for_individual) => {
    // let gstPercent = checkGSTPercent(
    //   Math.round(price / no_nights / no_of_rooms)
    // );
    // let gstPrice = (price * gstPercent) / 100;

    let gstPrice = 0

    if (localeDetails && localeDetails.gst_slab_id === 1) {
      let gstPercent = checkGSTPercent(
        Math.round(price / no_nights / no_of_rooms)
      );
      gstPrice = (price * gstPercent) / 100;
    }

    if (localeDetails && localeDetails.gst_slab_id === 2) {
      let gstPercent = checkGSTPercent(
        Math.round(price_for_individual / no_nights / no_of_rooms)
      );
      gstPrice = (price * gstPercent) / 100;
    }


    return gstPrice;
  };

  // const getOtherPrice = (no_of_rooms, room_type_id, price) => {
  //   let taxes = [];
  //   if (taxRulesForModify && taxRulesForModify.tax_applicable) {
  //     for (let i = 0; i < taxRulesForModify.tax_rules.length; i++) {
  //       let tax_price = (price * taxRulesForModify.tax_rules[i]["tax_percent"]) / 100;
  //       let tax_object = {
  //         tax_name: taxRulesForModify.tax_rules[i]["tax_name"],
  //         tax_price: tax_price,
  //       };
  //       taxes.push(tax_object);
  //     }
  //   }
  //   return taxes;
  // };

  const checkGSTPercent = (price) => {

    let tax_percent = 0

    if (localeDetails && localeDetails.tax_range_type === 'range') {
      if (localeDetails.tax_value.length > 0) {
        localeDetails.tax_value.map((items) => {
          if (price >= parseFloat(items.start_range) && price <= parseFloat(items.end_range)) {
            tax_percent = parseFloat(items.value);
          }
        })
      }
    }


    if (localeDetails && localeDetails.tax_range_type === "single") {
      if (localeDetails.tax_value.length > 0) {
        localeDetails.tax_value.map((items) => {
          if (price >= parseFloat(items.start_range)) {
            tax_percent = parseFloat(items.value);
          }
        });
      }
    }



    return tax_percent;


    // if (price <= 1000) {
    //   return 0;
    // } else if (price > 1000 && price <= 7500) {
    //   return 12;
    // } else if (price > 7500) {
    //   return 18;
    // }
  };


  // const otherTaxCalculation = (cartItems) => {
  //   //Other Tax Calculation
  //   if (taxRulesForModify.hasOwnProperty("tax_rules")) {
  //     taxRulesForModify.tax_rules.map((tax_object, index) => {
  //       if (otherTaxArray.length < taxRulesForModify.tax_rules.length) {
  //         otherTaxArray.push({
  //           tax_name:
  //             typeof tax_object == "object" ? tax_object.tax_name : false,
  //           tax_amount: 0,
  //           display_tax_amount: 0,
  //         });
  //       }
  //     });
  //   }
  //   let newOtherTaxArray = [];
  //   cartItems &&
  //     cartItems.map((cartItem) => {
  //       cartItem.tax &&
  //         cartItem.tax.map((taxItem) => {
  //           taxItem.other_tax &&
  //             taxItem.other_tax.map((tax_object, index) => {
  //               otherTaxArray[index].tax_amount +=
  //                 otherTaxArray[index].tax_name == tax_object.tax_name
  //                   ? tax_object.tax_price
  //                   : 0;
  //               otherTaxArray[index].display_tax_amount = otherTaxArray[index].tax_amount.toFixed(2);
  //             });
  //         });
  //     });

  //   newOtherTaxArray = [...otherTaxArray];
  //   setOtherTaxArray(newOtherTaxArray);
  // }

  const calculateTotalPay = (cartItems, modify_be_data) => {
    let gst_total_price = 0;
    let totalPrice = 0;
    let totalDiscountPrice = 0;
    let totalPaidServicePrice = 0;
    cartItems &&
      cartItems.map((cartItem) => {
        let extra_adult_price = 0;
        let extra_child_price = 0;
        cartItem.rooms.map((room) => {
          extra_adult_price += room.extra_adult_price;
          extra_child_price += room.extra_child_price;
        });
        totalDiscountPrice += cartItem.discounted_price;

        if (isTaxable == 1) {
          gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
        }

        totalPrice += cartItem.price + extra_adult_price + extra_child_price;
      });

    totalPaidServicePrice += modify_be_data.total_paid_service_amount;


    cartItems &&
      cartItems.map((cartItem) => {
        cartItem.paid_amount =
          totalPrice +
          gst_total_price -
          totalDiscountPrice +
          totalPaidServicePrice;
        cartItem.paid_amount_per = 100;
      });

    setTotalAmountToPay(totalPrice + gst_total_price - totalDiscountPrice + totalPaidServicePrice);
    setModifyCart(cartItems);

  }


  const callModifyBooking = () => {
    let bool_value = callUserAPI;
    setModifyBookingLoader(true);
    modifyBookRequest(hotelId, modifyBookingData[0], datesSelected, modifyGuestFirstName, modifyGuestLastName, modifyCart, setUpdateUserDetails, totalAmountToPay, setModifyBookingLoader, bool_value, setCallUserAPI);
  }

  const closeAllModal = () => {
    setUpdateUserDetails({ status: '', message: '', class: '' })
    setOpenModifyBookingModal(false);
  }


  const callCancellationPolicy = () => {
    setOpenCancelBookingModal(false);
    if (hotelId != 0) {
      getCancellationPolicy(
        hotelId,
        setCancellationPolicy,
        setNote,
        setOpenCancellationPolicyModal
      );
    }
  }

  const closeCancellationPolicy = () => {
    setOpenCancellationPolicyModal(false);
    setOpenCancelBookingModal(true);

  }

  const [displayBookingModify, setDisplayBookingModify] = useState(true);

  useEffect(() => {
    if (compState === 2725 || compState === 2726 || compState === 2727 || compState === 2728 || compState === 2729 || compState === 2730 || compState === 2731) {
      setDisplayBookingModify(false);
    }
  }, [compState]);



  return (
    <div>
      {displayLoader && (
        <div className="lds-ellipsis">
          <div />
          <div />
          <div />
          <div />
        </div>
      )}

      {!displayLoader && (
        <div
          className="row"
          style={{
            border: "1px solid #aaa",
            borderTop: "none",
            paddingTop: "1%",
            paddingBottom: "1%",
          }}
        >
          <div className="row filter-booking-months">
            <div className="col s1 m6" />
            <div className="col s5 m3">
              <select
                value={monthFilter}
                onChange={(e) => changeMonth(e.target.value)}
              >
                <option value={"01"}>January</option>
                <option value={"02"}>February</option>
                <option value={"03"}>March</option>
                <option value={"04"}>April</option>
                <option value={"05"}>May</option>
                <option value={"06"}>June</option>
                <option value={"07"}>July</option>
                <option value={"08"}>August</option>
                <option value={"09"}>September</option>
                <option value={"10"}>October</option>
                <option value={"11"}>November</option>
                <option value={"12"}>December</option>
              </select>
            </div>

            <div className="col s5 m3">
              <select
                value={yearFilter}
                onChange={(e) => changeYear(e.target.value)}
              >
                {year_array.map((item, i) => {
                  return (
                    <option value={item} key={i}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          {userData && userData.length > 0 && (
            <>
              {userData.map((userData, index) => {
                let check_in = userData.check_in;
                check_in = check_in.split("-").reverse().join("-");

                let check_out = userData.check_out;
                check_out = check_out.split("-").reverse().join("-");

                let date1 = new Date(userData.check_in);
                let date2 = new Date(userData.check_out);
                let timeDiff = Math.abs(date2.getTime() - date1.getTime());
                let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));


                if (userData.booking_status === 1) {
                  booking_status = "Confirmed";
                }
                if (userData.booking_status === 2) {
                  booking_status = "Pending";
                }

                let check_in_date = moment(userData.check_in).format(
                  "YYYY-MM-DD"
                );
                let cancel_status;
                if (check_in_date >= current_date) {
                  cancel_status = true;
                } else {
                  cancel_status = false;
                }

                return (
                  <div className="col s12 m4" key={index}>
                    <div className="booking-summary-box mobile-margin-top manage-booking-card">
                      {/* <span
                        className="booking-status-button"
                        title={booking_status}
                      >
                        <i
                          className={
                            booking_status === "Confirmed"
                              ? "fa fa-check"
                              : "fa fa-clock-o"
                          }
                        />
                      </span> */}

                      <div className="room-booking-summary">
                        <h5 className="user-name">Booking Id: {userData.booking_id}</h5>
                        <div className="container booking-summary">
                          <div className="row">
                            <div className="room-summary-box cancel-summary-box">

                              <div className="user-bookings">
                                <ul className="clearfix">
                                  <li>Check-In</li>
                                  <li>Check-Out</li>
                                </ul>
                                <ul className="clearfix">
                                  <li>
                                    {check_in}
                                  </li>
                                  <li>
                                    {check_out}
                                  </li>
                                </ul>
                              </div>

                              <div className="user-bookings">
                                <ul className="clearfix">
                                  <li>No. of Nights</li>
                                  <li>
                                    {diffDays}
                                  </li>
                                </ul>
                              </div>


                              <div className="user-bookings">
                                <ul className="clearfix">
                                  <li>Total Amount</li>
                                  <li>Paid Amount</li>
                                </ul>

                                <ul className="clearfix">
                                  <li>
                                    <i className={currencySymbol} />{" "}
                                    {parseFloat(userData.total_amount).toLocaleString('en-IN')}
                                  </li>
                                  <li>
                                    <i className={currencySymbol} />{" "}
                                    {parseFloat(userData.paid_amount).toLocaleString('en-IN')}
                                  </li>
                                </ul>
                              </div>

                              <div className="user-bookings user-data-display">
                                <ul className="clearfix">
                                  <li>Room(s)/Package(s)</li>
                                </ul>

                                <ul className="clearfix">
                                  <li>{userData.room_type}</li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          {displayBookingModify ?
                            <div className="row">
                              <div
                                className="col s4 m4 user-modify-buttons"
                              >
                                <a
                                  onClick={() => viewInvoice(userData.invoice)}
                                  className="col s12 btn waves-effect apply-close"
                                >
                                  <i className="fa fa-download" /> Print
                                </a>
                              </div>

                              <div
                                className="col s4 m4 user-modify-buttons"
                              >
                                <a
                                  className={cancel_status === false ? "col s12 btn waves-effect apply-close disable" : "col s12 btn waves-effect apply-close"}
                                  onClick={() => modifyBookingFunctionCall(index, cancel_status)}

                                  title={cancel_status === false ? "You cannot modify this booking as the check-in date has passed." : ''}

                                >
                                  <i className="fa fa-pencil" /> Modify
                                </a>
                              </div>


                              <div
                                className="col s4 m4 user-modify-buttons"
                              >
                                <a title={cancel_status === false ? "You cannot cancel this booking as the check-in date has passed." : ''}
                                  className={cancel_status === false ? "col s12 btn waves-effect apply-close disable" : "col s12 btn waves-effect apply-close"}
                                  onClick={() => requestCancelBooking(userData, cancel_status)}
                                >
                                  <i className="fa fa-trash-o" /> Cancel
                                </a>
                              </div>
                            </div>

                            :

                            <div className="row">
                              <div
                                className="col s6 m6 user-modify-buttons"
                              >
                                <a
                                  onClick={() => viewInvoice(userData.invoice)}
                                  className="col s12 btn waves-effect apply-close"
                                >
                                  <i className="fa fa-download" /> Print
                                </a>
                              </div>

                              <div
                                className="col s6 m6 user-modify-buttons"
                              >
                                <a title={cancel_status === false ? "You cannot cancel this booking as the check-in date has passed." : ''}
                                  className={cancel_status === false ? "col s12 btn waves-effect apply-close disable" : "col s12 btn waves-effect apply-close"}
                                  onClick={() => requestCancelBooking(userData, cancel_status)}
                                >
                                  <i className="fa fa-trash-o" /> Cancel
                                </a>
                              </div>
                            </div>
                          }



                          {/* added for handling guest permissions */}
                          {/* {guestPermission && guestPermission.modify === 1 && guestPermission.cancel === 1 &&
                            <div className="row">
                              <div
                                className="col s4 m4 user-modify-buttons"
                              >
                                <a
                                  onClick={() => viewInvoice(userData.invoice)}
                                  className="col s12 btn waves-effect apply-close"
                                >
                                  <i className="fa fa-download" /> Print
                                </a>
                              </div>

                              <div
                                className="col s4 m4 user-modify-buttons"
                              >
                                <a
                                  className={cancel_status === false ? "col s12 btn waves-effect apply-close disable" : "col s12 btn waves-effect apply-close"}
                                  onClick={() => modifyBookingFunctionCall(index, cancel_status)}

                                  title={cancel_status === false ? "You cannot modify this booking as the check-in date has passed." : ''}

                                >
                                  <i className="fa fa-pencil" /> Modify
                                </a>
                              </div>


                              <div
                                className="col s4 m4 user-modify-buttons"
                              >
                                <a title={cancel_status === false ? "You cannot cancel this booking as the check-in date has passed." : ''}
                                  className={cancel_status === false ? "col s12 btn waves-effect apply-close disable" : "col s12 btn waves-effect apply-close"}
                                  onClick={() => requestCancelBooking(userData, cancel_status)}
                                >
                                  <i className="fa fa-trash-o" /> Cancel
                                </a>
                              </div>
                            </div>
                          }



                          {guestPermission && guestPermission.modify === 1 && guestPermission.cancel === 0 &&
                            <div className="row">
                              <div
                                className="col s6 m6 user-modify-buttons"
                              >
                                <a
                                  onClick={() => viewInvoice(userData.invoice)}
                                  className="col s12 btn waves-effect apply-close"
                                >
                                  <i className="fa fa-download" /> Print
                                </a>
                              </div>

                              <div
                                className="col s6 m6 user-modify-buttons"
                              >
                                <a
                                  className={cancel_status === false ? "col s12 btn waves-effect apply-close disable" : "col s12 btn waves-effect apply-close"}
                                  onClick={() => modifyBookingFunctionCall(index, cancel_status)}

                                  title={cancel_status === false ? "You cannot modify this booking as the check-in date has passed." : ''}

                                >
                                  <i className="fa fa-pencil" /> Modify
                                </a>
                              </div>
                            </div>
                          }


                          {guestPermission && guestPermission.modify === 0 && guestPermission.cancel === 1 &&
                            <div className="row">
                              <div
                                className="col s6 m6 user-modify-buttons"
                              >
                                <a
                                  onClick={() => viewInvoice(userData.invoice)}
                                  className="col s12 btn waves-effect apply-close"
                                >
                                  <i className="fa fa-download" /> Print
                                </a>
                              </div>

                              <div
                                className="col s6 m6 user-modify-buttons"
                              >
                                <a title={cancel_status === false ? "You cannot cancel this booking as the check-in date has passed." : ''}
                                  className={cancel_status === false ? "col s12 btn waves-effect apply-close disable" : "col s12 btn waves-effect apply-close"}
                                  onClick={() => requestCancelBooking(userData, cancel_status)}
                                >
                                  <i className="fa fa-trash-o" /> Cancel
                                </a>
                              </div>
                            </div>
                          }



                          {guestPermission && guestPermission.modify === 0 && guestPermission.cancel === 0 &&
                            <div className="row">
                              <div
                                className="col s12 m12 user-modify-buttons"
                              >
                                <a
                                  onClick={() => viewInvoice(userData.invoice)}
                                  className="col s12 btn waves-effect apply-close"
                                >
                                  <i className="fa fa-download" /> Print
                                </a>
                              </div>
                            </div>
                          } */}



                          {/* added for handling guest permissions */}

                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}

          {userData && userData.length === 0 && (
            <div className="col s12 center-align">
              {/* <p>Sorry no bookings avaliable for the month !</p> */}
              <Lottie options={defaultOptions} height={100} width={100} />
              <span className="no-booking-available">Missing !</span>
            </div>
          )}
        </div>
      )}

      {openCancelBookingModal && (
        <div className="modal-wrapper">
          <div className="modal scroll" style={{ background: "transparent" }}>
            <div className="modal-content">
              <div className="login-wrapper card z-depth-3">
                <span
                  className="modal-close waves-effect btnCloseRoom"
                  onClick={() => closeModal()}
                />
                {!openModalForZeroRefund && !confirmCancelMessage && (
                  <h3 className="center-align">
                    Booking Id: {displayCancelData.booking_id}
                  </h3>
                )}
                {!openModalForZeroRefund && !confirmCancelMessage && <hr />}

                {displayDataOfCancelBooking && (
                  <div className="row  mx-auto">
                    <div className="col center-align m12 ">
                      <div className="row">
                        <div className="col s12 m6 refund-amount-display">
                          {displayBookingModify ? "Total Amount" : "Booked Amount"}
                        </div>

                        <div className="col s12 m6">
                          <i className={currencySymbol} />{" "}
                          {parseFloat(displayCancelData.total_amount).toLocaleString('en-IN')}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col s12 m6 refund-amount-display">
                          Refund Amount
                        </div>

                        <div className="col s12 m6">
                          {displayBookingModify ?
                            <>
                              <i className={currencySymbol} />{" "}
                              {parseFloat(displayCancelData.refund_amount).toLocaleString('en-IN')}
                            </>
                            :
                            <p className="message-refund-amount">As per Cancellation Policy</p>
                          }
                        </div>
                      </div>

                      {displayBookingModify &&
                        <div className="row">
                          <div className="col s12 m12 cancellation-policy-message">
                            View<span onClick={() => callCancellationPolicy()}>  Cancellation Policy</span>
                          </div>
                        </div>}


                      <br />
                      <div className="row">
                        <div className="col s2 m2" />
                        <div className="col s8 m8">

                          {displayBookingModify ?
                            <button
                              type="submit"
                              className="col s12 btn waves-effect proceed-cancellation"
                              onClick={() =>
                                confirmationForZeroRefund(displayCancelData)
                              }
                            >
                              Proceed <span className={cancelBookingLoader ? "payLoader" : ""} />
                            </button>
                            :
                            <button
                              type="submit"
                              className="col s12 btn waves-effect proceed-cancellation"
                              onClick={() =>
                                callCancelBookingFunction(displayCancelData)
                              }
                            >
                              Proceed <span className={cancelBookingLoader ? "payLoader" : ""} />
                            </button>
                          }


                        </div>
                        <div className="col s2 m2" />
                      </div>
                    </div>
                  </div>
                )}

                {openModalForZeroRefund && (
                  <div className="row  mx-auto">
                    <div className="col center-align m12 ">
                      <p>
                        The Refund Amount is <i className={currencySymbol} />{" "}
                        {parseFloat(displayCancelData.refund_amount).toLocaleString('en-IN')}
                      </p>
                      <p>Are you sure you want to proceed ?</p>

                      <div className="row" />
                      <div className="row" />

                      <div className="row">
                        <div className="col s6 m6">
                          <button
                            type="submit"
                            className="col s12 btn waves-effect apply-close"
                            onClick={() =>
                              confirmBookingCancel(displayCancelData)
                            }
                          >
                            Confirm <span className={cancelBookingLoader ? "payLoader" : ""} />
                          </button>
                        </div>

                        <div className="col s6 m6">
                          <button
                            type="submit"
                            className="col s12 btn waves-effect apply-close"
                            onClick={() => closeModalForZeroRefund()}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {confirmCancelMessage && (
                  <div className="row  mx-auto">
                    <div className="col center-align m12 ">
                      <div>
                        <i className={confirmCancelIcon} />
                      </div>
                      <br />
                      <p>{confirmCancelMessage}</p>

                      <div className="row" />
                      <div className="row" />

                      <div className="row">
                        <div className="col s12 m12">
                          <button
                            type="submit"
                            className="col s12 btn waves-effect apply-close"
                            onClick={() => closeConfirmCancelPopup()}
                          >
                            OK
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="modal-overlay"
            style={{ zIndex: "1230", display: "block", opacity: "0.5" }}
          />
        </div>
      )}

      {userData && userData.length > 0 && (
        <div className="row pagination_button">
          <div className="col s12 m12">
            <div className="row">
              <div className="col">
                <button
                  type="button"
                  className="btn btn-info btn-xs"
                  onClick={() => {
                    setPage(1);
                    window.scrollTo(0, 0);
                  }}
                >
                  <i className="fa fa-angle-double-left" />
                </button>
              </div>
              <div className="col">
                <button
                  type="button"
                  className="btn btn-info btn-xs"
                  onClick={() => previousButton()}
                >
                  <i className="fa fa-angle-left" />
                </button>
              </div>

              <div className="col">
                {buttonArray.map((item, index) => {
                  return (
                    <button
                      className="btn btn-xs btn-success"
                      type="button"
                      key={index}
                      onClick={() => {
                        setPage(item);
                        window.scrollTo(0, 0);
                      }}
                    >
                      {item}
                    </button>
                  );
                })}
              </div>

              <div className="col">
                <button
                  type="button"
                  className="btn btn-info btn-xs"
                  onClick={() => nextButton()}
                >
                  <i className="fa fa-angle-right" />
                </button>
              </div>
              <div className="col">
                <button
                  type="button"
                  className="btn btn-info btn-xs"
                  onClick={() => {
                    setPage(lastPage);
                    window.scrollTo(0, 0);
                  }}
                >
                  <i className="fa fa-angle-double-right" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}


      {openModifyBookingModal && !availabilityModal && !updateUserDetails.status &&
        <div className="modal-wrapper">
          <div className="modal scroll z-value modify-modal-size">
            <div className="card" style={{ margin: "0rem" }}>
              <div className="modal-content" >
                <span
                  onClick={() => setOpenModifyBookingModal(false)}
                  className="modalBtnClose"
                />

                <div className="row">
                  <div className="col s12 m8">
                    <div className="modify-booking-display">
                      <Default>
                        <DateRangePicker
                          numberOfMonths={2}
                          orientation="horizontal"
                          startDateId="start"
                          endDateId="end"
                          startDate={datesSelected.startDate}
                          endDate={datesSelected.endDate}
                          onDatesChange={({ startDate, endDate }) => {
                            newDateRange(startDate, endDate);
                          }}
                          focusedInput={focusedInput}
                          onFocusChange={(focusedInput) =>
                            setFocusedInput(focusedInput)
                          }
                          displayFormat="DD MMM YYYY"
                        />
                      </Default>

                      <Mobile>
                        <DateRangePicker
                          numberOfMonths={2}
                          orientation="vertical"
                          startDateId="start"
                          endDateId="end"
                          startDate={datesSelected.startDate}
                          endDate={datesSelected.endDate}
                          onDatesChange={({ startDate, endDate }) => {
                            newDateRange(startDate, endDate);
                          }}
                          focusedInput={focusedInput}
                          onFocusChange={(focusedInput) =>
                            setFocusedInput(focusedInput)
                          }
                          displayFormat="DD MMM YYYY"
                        />
                      </Mobile>
                    </div>
                  </div>

                  <div className="col s12 m4">
                    <div className="input-field input-date-select">
                      <button className="waves-effect waves-light btn btn-search center-align" onClick={() => checkAvailablility()}>Check Availability <span className={availabilityLoader ? "cancelBookingLoader" : ""} /></button>
                    </div>
                  </div>
                  {showMessage && (
                    <div className="showMessage-modify">
                      <p className="show--message">
                        {showMessage}
                      </p>
                    </div>
                  )}

                  {!showMessage && (
                    <div className="modify-message">
                      <p className="show--message">Change of dates are applicable only for the dates with rates same as current booking rate.</p>
                    </div>
                  )}


                </div>

                <div className="row">
                  <div className="col s12 m6" >
                    <div className="modify-guest-name">
                      <label>Guest First Name</label>
                      <input value={modifyGuestFirstName} onChange={(e) => handleFirstNameChange(e.target.value)} type="text" />
                    </div>
                  </div>

                  <div className="col s12 m6" >
                    <div className="modify-guest-name">
                      <label>Guest Last Name</label>
                      <input value={modifyGuestLastName} onChange={(e) => handleLastNameChange(e.target.value)} type="text" />
                    </div>
                  </div>

                </div>

                {/* <div className="row">
                  <div className="col s9 m9" />
                  <div className="col s3 m3">
                    <button
                      type="submit"
                      className="col s12 btn waves-effect proceed-modify"
                    >
                      Proceed
                          </button>
                  </div>
                </div> */}


                <hr />
                <div className="row">
                  <div className="row">
                    <div className="col s12 m4">
                      <ul>
                        <li>Total Amount</li>
                        <li><i className={currencySymbol} />{" "}{parseFloat(totalAmountToPay).toLocaleString('en-IN')}</li>
                      </ul>
                    </div>

                    <div className="col s12 m4">
                      <ul>
                        <li>Paid Amount</li>
                        {modifyBookingData && modifyBookingData.length > 0 && <li><i className={currencySymbol} />{" "}{parseFloat(modifyBookingData[0].paid_amount).toLocaleString('en-IN')}</li>}
                      </ul>
                    </div>

                    <div className="col s12 m4">
                      <div className="pay-btn-wrap center-align">

                        {(finalPrice.status) ?
                          <a className="waves-effect waves-light btn-small pay-btn btn-modify-booking" onClick={() => callModifyBooking()}>
                            {finalPrice.message} {finalPrice.price && <i className={currencySymbol} />}{" "}{finalPrice.price}
                            <span className={modifyBookingLoader ? "payLoader" : ""} />
                          </a>
                          :
                          <a className="waves-effect waves-light btn-small pay-btn btn-modify-booking disable-modify-booking">
                            {finalPrice.message} {finalPrice.price && <i className={currencySymbol} />}{" "}{finalPrice.price}
                          </a>
                        }


                      </div>
                    </div>
                  </div>
                </div>

                {(!finalPrice.status) &&
                  <div className="row">
                    <div className="col s12 m12">
                      <div className="modify-info">
                        <p>Please contact to the respective property for modification.</p>
                      </div>
                    </div>
                  </div>
                }


              </div>
            </div>
          </div>
          <div
            className="modal-overlay"
            style={{
              zIndex: "1002",
              display: "block",
              opacity: "0.2",
            }}
          />
        </div>

      }

      {availabilityModal && (
        <div className="modal-wrapper">
          <div className="modal scroll" style={{ background: "transparent" }}>
            <div className="modal-content">
              <div className="login-wrapper card z-depth-3">
                <span
                  className="modal-close waves-effect btnCloseRoom"
                  onClick={() => setAvailablityModal(false)}
                />
                <div className="row  mx-auto">
                  <div className="col center-align m12 ">
                    <Lottie options={defaultOptions} height={100} width={100} />
                    <br />
                    <p>Sorry ! No Rooms Available</p>
                    <div className="row" />
                    <div className="row" />

                    <div className="row">
                      <div className="col s12 m12">
                        <button
                          type="submit"
                          className="col s12 btn waves-effect apply-close"
                          onClick={() => setAvailablityModal(false)}
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-overlay"
            style={{ zIndex: "1230", display: "block", opacity: "0.5" }}
          />
        </div>
      )}

      {updateUserDetails.status === true && (
        <div className="modal-wrapper">
          <div className="modal scroll" style={{ background: "transparent" }}>
            <div className="modal-content">
              <div className="login-wrapper card z-depth-3">
                <span
                  className="modal-close waves-effect btnCloseRoom"
                  onClick={() => closeAllModal()}
                />
                <div className="row  mx-auto">
                  <div className="col center-align m12 ">
                    <div>
                      <i className={updateUserDetails.class} />
                    </div>
                    <br />
                    <p>{updateUserDetails.message}</p>

                    <div className="row" />
                    <div className="row" />

                    <div className="row">
                      <div className="col s12 m12">
                        <button
                          type="submit"
                          className="col s12 btn waves-effect apply-close"
                          onClick={() => closeAllModal()}
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div
            className="modal-overlay"
            style={{ zIndex: "1230", display: "block", opacity: "0.5" }}
          />
        </div>
      )}




      {openCancellationPolicyModal &&
        <div className="modal-wrapper">
          <div className="modal scroll z-value notification-modal-size">
            <div className="card" style={{ margin: "0rem" }}>
              <div className="modal-content" >
                <span
                  onClick={() => closeCancellationPolicy()}
                  className="modalBtnClose"
                />

                <div className="row row-cancellation-policy">

                  <div className="col s12">
                    {cancellationPolicy && cancellationPolicy.length > 0 && (
                      <div>
                        <div className="row">
                          <div className="col s6 m6 policy-display">Days Before Check-In</div>
                          <div className="col s6 m6 policy-display">
                            Refund Percentage (%)
                          </div>
                        </div>
                        <hr />

                        {cancellationPolicy.map((item, i) => {
                          return (
                            <div key={i}>
                              <div className="row">
                                <div className="col s6 m6 policy-display">
                                  {item.days}
                                </div>
                                <div className="col s6 m6 policy-display">
                                  {item.refund_percentage}
                                </div>
                              </div>
                              <hr />
                            </div>
                          );
                        })}
                      </div>)}

                    <br />
                    {note && note.cancel_policy && (
                      <div className="row">
                        <div className="col s12 m12">
                          <fieldset>
                            <legend className="legend-header">NOTE</legend>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: " " + note.cancel_policy,
                              }}
                            />
                          </fieldset>
                        </div>
                      </div>
                    )}
                  </div>

                </div>


              </div>
            </div>
          </div>
          <div
            className="modal-overlay"
            style={{
              zIndex: "1002",
              display: "block",
              opacity: "0.2",
            }}
          />
        </div>

      }

    </div>
  );
};
//Export UserBookings
export default ManageBookings;
