import React, { useContext, useState, useEffect, useRef } from "react";
import bed from "../../../img/bed.png";
import {
  DateRangeContext,
  TotalPriceContext,
  TotalDiscountContext,
  AdvanceBookingContext,
  TempDateRangeContext,
  BeUrlContext,
} from "../../../settings/Store";
import moment from "moment";
import {
  HotelIdContext,
  ApiKeyContext,
  CurrencyContext,
  CurrencySymbolContext,
  BaseCurrencyContext,
  //Refund Protect
  RefundProtectContext,
  //Refund Protect
} from "../../../settings/Store";

import covid1 from "../../../img/covid1.jpg";
import covid2 from "../../../img/covid2.jpg";
import covid3 from "../../../img/covid3.jpg";

import getPaidServices from "./getPaidServices";
import userSignup from "./userSignUp";
import newBookRequest from "./newBookRequest";
import getCurrencyValue from "./getCurrencyValue";
import fetchHotelInfo from "../../HotelInfo/fetchHotelInfo";
import validateCupon from "./validateCupon";
import countryCodes from "./countryCodes.json";
import identity from "./identity.json";
import DatePicker from "react-date-picker";
import getLocaleDetails from "./getLocaleDetails";
import fetchAddonChargesData from "./fetchAddonChargesData";
import ReactPixel from "react-facebook-pixel";
import config from "../../../config";

// import react parser
import parse from "html-react-parser";

// Added React Scroll
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import ImageSlider from "../Rooms/RoomImageSlider";
import FetchNotificationSlider from "../../fetchNotificationSlider";
import { Snackbar } from "@material-ui/core";

import getCountry from "./getCountry";
import getState from "./getState";
import getCity from "./getCity";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import 'react-toastify/dist/react-toastify.esm.mjs';

const loadData = () => JSON.parse(JSON.stringify(countryCodes));
const loadIdentityData = () => JSON.parse(JSON.stringify(identity));
const Cart = (props) => {
  // let tax_pay_at_hotel = props && props.taxRulesObject && props.taxRulesObject.tax_pay_hotel;
  const bottomRef = useRef(null);
  const [appliedPublicCoupon, setAppliedPublicCoupon] = useState({});
  const [hotelId] = useContext(HotelIdContext);
  const [dateRange] = useContext(DateRangeContext);
  const [tempDateRange] = useContext(TempDateRangeContext);
  const [beUrl] = useContext(BeUrlContext);
  // const [otherTaxArray, setOtherTaxArray] = useState([]);
  const [totalGst, setTotalGst] = useState(0);
  // const [otherTaxTotal, setOtherTaxTotal] = useState(0);
  const [initialPublicCouponSet, setInitialPublicCouponSet] = useState(false);
  const [publicCouponApplied, setPublicCouponApplied] = useState(true);
  const [privateCouponApplied, setPrivateCouponApplied] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [allAppliedCoupons, setAllAppliedCoupons] = useState([]);
  // const [totalPrice, setTotalPrice] = useState(0);
  // const [totalDiscount, setTotalDiscount] = useState(0);
  const [couponsInCart, setCouponsInCart] = useState([]);

  const [allAppliedPublicCoupons, setAllAppliedPublicCoupons] = useState([]);
  const [
    manageAllAppliedPublicCoupons,
    setManageAllAppliedPublicCoupons,
  ] = useState([]);

  const [totalPrice, setTotalPrice] = useContext(TotalPriceContext);
  const [totalDiscount, setTotalDiscount] = useState(TotalDiscountContext);
  const [currencySymbol] = useContext(CurrencySymbolContext);
  const [baseCurrency] = useContext(BaseCurrencyContext);
  const [currency] = useContext(CurrencyContext);
  const [selectedCoupon, setSelectedCoupon] = useState(0);
  const [initialCoupon, setInitialCoupon] = useState(null);
  const [advanceDays, setAdvanceDays] = useContext(AdvanceBookingContext);
  const [applicableCoupons, setApplicableCoupons] = useState([]);
  const [cartRoomIds, setCartRoomIds] = useState([]);
  //private coupon
  const [privateCuponErr, setPrivateCuponErr] = useState("");
  const [validCoupon, setValidCoupon] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);

  const [cartItemsWithGST, setCartItemsWithGST] = useState([]);
  //User Registration

  //Refund Protect
  const [optedForRefund] = useContext(RefundProtectContext);

  // useEffect(() => {
  //   console.log(optedForRefund, " : optedForRefund");
  // }, [optedForRefund])

  // const [selectedValueForRefund, setSelectedValueForRefund] = useState(1);
  const [selectedValueForRefund, setSelectedValueForRefund] = useState(0);
  // const [selectedValueForRefund, setSelectedValueForRefund] = useState(3);

  // useEffect(() => {
  //   console.log(refundProtectPrice, " : refundProtectPrice");
  //   console.log(refundProtectTaxPrice, " : refundProtectTaxPrice");
  //   console.log(refundProtectPrice + refundProtectTaxPrice, " : Total refund protect price");
  //   console.log(props.cart, " : paid_amount");
  //   console.log(selectedValueForRefund, " : selectedValueForRefund");

  //   if (selectedValueForRefund === 0) {
  //     console.log("I am called!")
  //     let totalRefunfundProtect = refundProtectPrice + refundProtectTaxPrice;
  //     console.log(totalRefunfundProtect, " : totalRefunfundProtect");
  //     props.cart.length > 0 &&
  //       props.cart.map((cartItem) => {
  //         let basePrice = cartItem.paid_amount;
  //         console.log(basePrice, " : basePrice");
  //         cartItem.paid_amount = parseFloat(basePrice) - totalRefunfundProtect.toFixed(2);
  //       })
  //   }
  // }, [selectedValueForRefund]);

  const [totalProductPrice, setTotalProductPrice] = useState(0);
  //Refund Protect

  const [totalAddonPrice, setTotalAddonPrice] = useState(0);
  const [totalGstAddonPrice, setTotalGstAddonPrice] = useState(0);

  const [userState, setUserState] = useState({
    name: "",
    emailId: "",
    countryCode: "+91",
    mobile: "",
    companyName: "",
    GSTIN: "",
    identity: "",
    address: "",
    zipcode: "",
    city: "",
    state: "",
    countryName: "",
    identityno: "",
  });

  const [arrivalTime, setArrivalTime] = useState("");

  // state to handle the checkbox status 
  const [checkBoxStatus, setCheckBoxStatus] = useState(true);

  useEffect(() => {
    if (
      optedForRefund &&
      optedForRefund.status === 1 &&
      optedForRefund.mode !== "optional"
    ) {
      setSelectedValueForRefund(1);
    }
  }, [optedForRefund]);

  // handle refund protect

  //new scenario

  const [addOnChargesData, setAddOnChargesData] = useState([]);

  const [curCoupon, setCurCoupon] = useState(props.appliedCoupon);

  // New Coupon Display
  const [filteredCoupons, setFilteredCoupons] = useState([]);

  //Notification Slider
  const [sliderImages, setSliderImages] = useState([]);
  useEffect(() => {
    if (hotelId !== 0) {
      FetchNotificationSlider(hotelId, setSliderImages);
    }
  }, [hotelId]);
  //Notification Slider

  const [travelerLocation, SetTravelerLocation] = useState();
  const [countryID, setCountryID] = useState();
  const [stateID, setStateID] = useState();

  // Fetch Notel Label from get-hotel-info
  const [guestNoteLabelText, setGuestNoteLabelText] = useState("");
  const [noteMandatory, setIsNoteMandatory] = useState(null);
  const [policyData, setPolicyData] = useState("");
  const [guestNote, setGuestNote] = useState("");
  useEffect(() => {
    if (hotelId != "" && apiKey != 0) {
      getLabelTextGuestDetails();
    }
  }, [hotelId, apiKey]);

  // method to check if guest note is empty or not
  const checkGuestNote = (noteValue) => {
    setGuestNote(noteValue);
    if (noteValue && noteMandatory === 1) {
      setGuestNoteLabelText("");
    }
  };

  // method to get label
  const getLabelTextGuestDetails = () => {
    fetch(
      config.apiEndpoint +
      "bookingEngine/get-hotel-info/" +
      apiKey +
      "/" +
      hotelId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 1) {
          setGuestNoteLabelText(responseJson.data.note_text);
          setIsNoteMandatory(responseJson.data.is_note_mandatory);
          setPolicyData(responseJson.data);
          if (
            !responseJson.data.hotel_policy &&
            !responseJson.data.terms_and_cond &&
            !responseJson.data.child_policy &&
            !responseJson.data.cancel_policy
          ) {
            setTermsCondtionState(true);
          }
        }
      })
      .catch((error) => { });
  };

  //Fetch all country
  const [allCountry, setAllCountry] = useState([]);

  useEffect(() => {
    if (hotelId !== 0 && countryID) {
      getCountry(setAllCountry, SetTravelerLocation, countryID, setUserState);
    }
  }, [hotelId, countryID]);

  //Fetch all state
  const [allState, setAllState] = useState([]);
  useEffect(() => {
    if (hotelId !== 0 && countryID) {
      getState(countryID, setAllState);
    }
  }, [hotelId, countryID]);

  // state for sekectedPaymentOpt
  const [selectedPaymentOptn, setSelectedPaymentOptn] = useState("");

  //Fetch all city
  const [allCity, setAllCity] = useState([]);
  useEffect(() => {
    if (hotelId !== 0 && stateID) {
      getCity(stateID, setAllCity);
    }
  }, [hotelId, stateID]);

  useEffect(() => {
    setIsModalOpen(false);
  }, [publicCouponApplied]);

  // ----revoking the state for cart on render of the Bookingpanel---- //
  useEffect(() => {
    setUserFormOpen(false);
    if (optedForRefund && optedForRefund.status === 1) {
      optedForRefund.mode !== "optional"
        ? setSelectedValueForRefund(1)
        : setSelectedValueForRefund(0);
    }
    // if (optedForRefund && optedForRefund.status === 1 && optedForRefund.mode !== "optional") {
    //   setSelectedValueForRefund(1);
    // }
    // setSelectedPaymentOptn("prepaid");
  }, [props.cart, optedForRefund]);

  //Finding all the applied coupons (Minhaj's code)
  useEffect(() => {
    setAllAppliedCoupons(props.coupons);
  }, [props.coupons]);

  // capturing current coupons
  const getExactCoupon = (coupons, cartItem) => {
    // coupons.
    const itemWithCoupon = {
      room_data: {
        room_type: cartItem.room_type,
        room_type_id: cartItem.room_type_id,
      },
      applied_coupon: coupons.find((coupon) => {
        return coupon.room_type_id === cartItem.room_type_id;
      }),
    };
    if (itemWithCoupon.applied_coupon == undefined) {
      itemWithCoupon.applied_coupon = coupons.find((coupon) => {
        return coupon.room_type_id === 0;
      });
    }

    return itemWithCoupon;
  };

  useEffect(() => {
    const currentAppliedCoupons = [];

    props.cart.length > 0 &&
      props.cart.map((cartItem) => {
        const currentAppliedCoupon = getExactCoupon(props.coupons, cartItem);

        currentAppliedCoupons.push(currentAppliedCoupon);
      });
    setCouponsInCart(currentAppliedCoupons);
    sessionStorage.setItem(
      "new_cart_with_coupons",
      JSON.stringify(currentAppliedCoupons)
    );
  }, [props.coupons, props.cart]);

  useEffect(() => {
    let newCart = [];
    if (
      validCoupon[0] &&
      validCoupon[0].coupon_for === 2 &&
      validCoupon[0].room_type_id === 0
    ) {
      couponsInCart &&
        couponsInCart.map((cartItem) => {
          const newCartItem = {
            room_data: cartItem.room_data,
            applied_coupon: validCoupon[0],
          };
          newCart.push(newCartItem);
        });
    }

    if (
      validCoupon[0] &&
      validCoupon[0].coupon_for === 2 &&
      validCoupon[0].room_type_id !== 0
    ) {
      couponsInCart &&
        couponsInCart.map((cartItem) => {
          const newCartItem = {
            room_data: cartItem.room_data,
            applied_coupon:
              cartItem.room_data.room_type_id === validCoupon[0].room_type_id
                ? validCoupon[0]
                : cartItem.applied_coupon,
          };
          newCart.push(newCartItem);
        });
    }

    setCouponsInCart(newCart);
    sessionStorage.setItem("new_cart_with_coupons", JSON.stringify(newCart));
  }, [validCoupon]);

  useEffect(() => {
    let filtered_coupons = [];

    if (Array.isArray(props.coupons)) {
      props.coupons.map((coupon) => {
        props.cart.map((cartItem) => {
          // if (coupon.coupon_for === 1) {
          if (
            cartItem.room_type_id === coupon.room_type_id ||
            coupon.room_type_id === 0
            //  &&
            // coupon.coupon_code === props.appliedPublicCoupon.coupon_code)
          )
            filtered_coupons.push(coupon);
          // }
        });
      });
    }

    //New Code  Minhaj

    //New Code  Minhaj

    // const uniqueCoupons = [...new Set(filtered_coupons)];
    const uniqueCoupons = Array.from(
      new Set(filtered_coupons.map((fc) => fc.coupon_id))
    ).map((id) => {
      return filtered_coupons.find((fc) => fc.coupon_id === id);
    });
    // const uniqueCoupons = [...new Set(unique)];

    setFilteredCoupons(uniqueCoupons);
    // console.log('Filtered: ', filteredCoupons);
  }, [
    props.cart,
    // , props.appliedCoupon
  ]);

  useEffect(() => {
    const cartRoomIds = props.cart.map((cartItem) => cartItem.room_type_id);
    setCartRoomIds(cartRoomIds);
    const couponsApplicable = props.coupons.map((coupon) => {
      if (
        cartRoomIds.includes(coupon.room_type_id) ||
        coupon.room_type_id === 0
      )
        return coupon;
    });

    const newCouponArr = couponsApplicable.filter((coupon) => {
      if (coupon) return coupon;
    });
    setApplicableCoupons(newCouponArr);
    // console.log('coupons applicable' + JSON.stringify(couponsApplicable));
  }, [props.cart, props.coupons]);

  useEffect(() => {
    setUserState((oldState) => {
      return {
        ...oldState,
        name: sessionStorage.getItem("name"),
        emailId: sessionStorage.getItem("email"),
        mobile: sessionStorage.getItem("phone"),
      };
    });
  }, [
    sessionStorage.getItem("name"),
    sessionStorage.getItem("email"),
    sessionStorage.getItem("phone"),
  ]);

  let startDate = moment(dateRange.startDate).format("Do MMM");
  let endDate = moment(dateRange.endDate).format("Do MMM");

  let dateForExpiryDatepicker = new Date(dateRange.endDate); //-------date for minDate in expiry date datepicker-------//
  //-----set date for maxdate in dob datepicker------------//
  let dobDatePickerDate = moment().subtract(18, "years");
  dobDatePickerDate = new Date(dobDatePickerDate);
  //-----end of set date for maxdate in dob datepicker------------//

  //Minhaj's code
  const checkGSTPercent = (price) => {
    let tax_percent = 0;

    if (localeDetails && localeDetails.tax_range_type === "range") {
      if (localeDetails.tax_value.length > 0) {
        localeDetails.tax_value.map((items) => {
          if (
            price >= parseFloat(items.start_range) &&
            price <= parseFloat(items.end_range)
          ) {
            tax_percent = parseFloat(items.value);
          }
        });
      }
    }

    if (localeDetails && localeDetails.tax_range_type === "single") {
      if (localeDetails.tax_value.length > 0) {
        localeDetails.tax_value.map((items) => {
          if (price >= parseFloat(items.start_range)) {
            tax_percent = parseFloat(items.value);
          }
        });
      }
    }

    return tax_percent;

    // if (price <= 1000) {
    //   return 0;
    // } else if (price > 1000 && price <= 7500) {
    //   return 12;
    // } else if (price > 7500) {
    //   return 18;
    // }
    // else if (currency !== baseCurrency) return 0;
  };
  //Minhaj's code

  const noOfNights = () => {
    let dt1 = new Date(dateRange.startDate);
    let dt2 = new Date(dateRange.endDate);
    // let timeDiff = Math.abs(date2.getTime() - date1.getTime());
    let diffDays = Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
    );
    // let diffDays = Math.ceil(302400000 / (1000 * 3600 * 24));

    return diffDays;
  };
  let no_nights = noOfNights();

  // useEffect(() => {
  //   if (props.taxRulesObject.hasOwnProperty("tax_rules")) {
  //     props.taxRulesObject.tax_rules.map((tax_object, index) => {
  //       if (otherTaxArray.length < props.taxRulesObject.tax_rules.length) {
  //         otherTaxArray.push({
  //           tax_name:
  //             typeof tax_object == "object" ? tax_object.tax_name : false,
  //           tax_amount: 0,
  //           display_tax_amount: 0,
  //         });
  //       }
  //     });
  //   }
  //   let newOtherTaxArray = [];
  //   props.cart &&
  //     props.cart.map((cartItem) => {
  //       cartItem.tax &&
  //         cartItem.tax.map((taxItem) => {
  //           taxItem.other_tax &&
  //             taxItem.other_tax.map((tax_object, index) => {
  //               otherTaxArray[index].tax_amount +=
  //                 otherTaxArray[index].tax_name == tax_object.tax_name
  //                   ? tax_object.tax_price
  //                   : 0;
  //               otherTaxArray[index].display_tax_amount =
  //                 currency !== baseCurrency
  //                   ? otherTaxArray[index].tax_amount * currencyValue.toFixed(2)
  //                   : otherTaxArray[index].tax_amount.toFixed(2);
  //             });
  //         });
  //     });
  //   console.log('Cart:', props.cart);
  //   newOtherTaxArray = [...otherTaxArray];
  //   setOtherTaxArray(newOtherTaxArray);
  // }, [props.cart, props.taxRulesObject, validCoupon]);

  //set expiry date and date of birth
  const [expiry_date, setExpiryData] = useState();
  const [dob, setDob] = useState();

  //Initial Paid services
  const [paidServices, setPaidServices] = useState([]);
  useEffect(() => {
    if (hotelId !== 0) {
      getPaidServices(hotelId, setPaidServices);
    }
  }, [hotelId]);

  //Update Total
  useEffect(() => {
    getTotal(props.cart);
    setCheckedStatus(false);
  }, [props.cart, currency, dateRange, tempDateRange, validCoupon]);

  //Get the currency value
  const [currencyValue, setCurrencyValue] = useState(1);
  useEffect(() => {
    if (baseCurrency !== null && baseCurrency !== "" && currency !== null) {
      if (currency != "INR") {
        setUserInfoStep("step0");
      }
      getCurrencyValue(currency, baseCurrency, setCurrencyValue);
    }
  }, [currency, baseCurrency, setCurrencyValue]);

  // retain the value of private coupon
  useEffect(() => {
    if (validCoupon.length > 0) {
      if (validCoupon[0].coupon_for == 2) {
        sessionStorage.setItem("privateCoupon", validCoupon[0].coupon_code);
      } else sessionStorage.removeItem("privateCoupon");
    } else sessionStorage.removeItem("privateCoupon");
  }, [validCoupon]);

  //Update Display paid services
  useEffect(() => {
    let display_amount = 0;
    for (var i = 0; i < paidServices.length; i++) {
      display_amount =
        currency !== baseCurrency
          ? parseFloat(
            (paidServices[i].service_amount * currencyValue).toFixed(2)
          )
          : paidServices[i].service_amount;
      paidServices[i]["display_amt"] = display_amount;
    }
  }, [paidServices, currency, currencyValue, baseCurrency, advanceDays]);

  //Get Total GST
  const [discountPriceDisplay, setDiscountPriceDisplay] = useContext(
    TotalDiscountContext
  );

  const [gstTotalPriceDisplay, setGstTotalPriceDisplay] = useState(0);
  // const [otherTaxTotalPriceDisplay, setOtherTaxTotalPriceDisplay] = useState(0);
  const [TotalPriceDisplay, setTotalPriceDisplay] = useContext(
    TotalPriceContext
  );

  const [overSeaFilter, setOverSeaFilter] = useState();
  const [isTaxable, setIsTaxable] = useState();

  const [allTotalPriceDisplay, setAllTotalPriceDisplay] = useState(0);
  const getTotal = (cart) => {
    let gst_total_price = 0;
    let totalPrice = 0;
    let totalDiscountPrice = 0;
    cart &&
      cart.length > 0 &&
      cart.map((cartItem) => {
        let extra_adult_price = 0;
        let extra_child_price = 0;
        cartItem.rooms.map((room) => {
          extra_adult_price += room.extra_adult_price;
          extra_child_price += room.extra_child_price;
        });
        totalDiscountPrice += cartItem.discounted_price;
        gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
        totalPrice += cartItem.price + extra_adult_price + extra_child_price;
      });
    setTotalDiscount(totalDiscountPrice);
    setTotalPrice(totalPrice);

    setTotalGst(gst_total_price);
    // setOtherTaxTotal(
    //   otherTaxArray.length > 0
    //     ? parseFloat(
    //       otherTaxArray
    //         .reduce((tax_amount, tax_obect) => {
    //           return (tax_amount += tax_obect.tax_amount);
    //         }, 0)
    //         .toFixed(2)
    //     )
    //     : 0
    // );

    setDiscountPriceDisplay(totalDiscountPrice);
    if (isTaxable == 1) {
      setGstTotalPriceDisplay(gst_total_price);
      setTotalPriceDisplay(totalPrice);
    }
    // setOtherTaxTotalPriceDisplay(otherTaxTotal);

    // setDiscountPriceDisplay(
    //   currency !== baseCurrency
    //     ? parseFloat((totalDiscountPrice * currencyValue).toFixed(2))
    //     : totalDiscountPrice
    // );
    // if (baseCurrency == "INR") {
    //   setGstTotalPriceDisplay(
    //     currency !== baseCurrency
    //       ? parseFloat((gst_total_price * currencyValue).toFixed(2))
    //       : gst_total_price
    //   );
    // }

    // setOtherTaxTotalPriceDisplay(
    //   currency !== baseCurrency
    //     ? parseFloat((otherTaxTotal * currencyValue).toFixed(2))
    //     : otherTaxTotal
    // );
    // if (baseCurrency == "INR") {
    //   setTotalPriceDisplay(
    //     currency !== baseCurrency
    //       ? parseFloat((totalPrice * currencyValue).toFixed(2))
    //       : totalPrice
    //   );
    // }
  };

  //Minhaj's Code
  const calculateDiscountedPrice = (
    cartItem,
    discounted_price,
    applicableCoupon
  ) => {
    if (
      validCoupon.length > 0 &&
      applicableCoupon &&
      applicableCoupon.coupon_for === 2
    ) {
      return (
        (cartItem.display_price * applicableCoupon.discount) /
        100
      ).toFixed(2);
    } else if (applicableCoupon && applicableCoupon.coupon_for === 2) {
      // console.log('Private');
      const couponIWant = applicableCoupon || validCoupon[0];
      return ((cartItem.display_price * couponIWant.discount) / 100).toFixed(2);
    } else return discounted_price;
  };

  //private specific
  const calculateForPrivateSpecific = (cartItem) => {
    // console.log('Hello there');

    return ((cartItem.display_price * validCoupon[0].discount) / 100).toFixed(
      2
    );
  };

  // const calculateGstAmount = (rates, days, rooms, roomTypeId, discounted) => {
  const calculateGstAmount = (
    days,
    rooms,
    roomTypeId,
    discounted,
    max_people,
    cartItem,
    addOnChargesData_val
  ) => {
    let from_date = moment(dateRange.startDate).format("YYYY-MM-DD");
    let to_date = moment(dateRange.endDate).format("YYYY-MM-DD");

    let start = moment(dateRange.startDate);
    let end = moment(dateRange.endDate);
    let no_of_days = Math.round(moment.duration(end.diff(start)).days());

    let end_date = moment(to_date)
      .subtract(1, "days")
      .format("YYYY-MM-DD");

    let dateArray = getDateArray(from_date, end_date);
    let gstAmount = 0;
    let totalAddonPrice = 0;
    let gstaddonPrice = 0;

    if (
      validCoupon[0] &&
      (validCoupon[0].room_type_id === 0 ||
        validCoupon[0].room_type_id === roomTypeId)
    ) {
      let coupon_start_date = moment(validCoupon[0].valid_from).format(
        "YYYY-MM-DD"
      );

      let coupon_to_date = moment(validCoupon[0].valid_to).format("YYYY-MM-DD");
      let coupon_end_date = moment(coupon_to_date)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      // let couponArray = getDateArray(coupon_start_date, coupon_end_date);

      let couponArray = [];

      validCoupon.map((item) => {
        couponArray.push(item.date);
      });

      dateArray &&
        dateArray.map((value, index) => {
          if (couponArray && couponArray.length > 0) {
            if (couponArray.includes(value)) {
              //Codes by Hafiz solving privateCouponCaluculation
              rooms.map((rates_for_discount) => {
                let discountPrice = 0;
                let total_bar_price = 1;
                let gst_price;
                let bar_price_for_discount = 1;

                let bar_price_for_individual_gst_calc = 1;

                // total_bar_price = (rates_for_discount.bar_price + rates_for_discount.extra_adult_price + rates_for_discount.extra_child_price) / no_of_days
                // bar_price_for_discount = rates_for_discount.bar_price / no_of_days
                // discountPrice += (bar_price_for_discount / 100) * validCoupon[0].discount;
                // gst_price = total_bar_price - discountPrice;
                // const gstPercent = checkGSTPercent(gst_price);
                // gstAmount += gst_price * gstPercent / 100;

                rates_for_discount.day_wise_rates &&
                  rates_for_discount.day_wise_rates.map((day_rate) => {
                    if (value === day_rate.date) {
                      // changes for fixing price calculation for single,multiple occupancy
                      if (rates_for_discount.selected_adult < max_people) {
                        if (
                          day_rate.multiple_occupancy[
                          rates_for_discount.selected_adult - 1
                          ]
                        ) {
                          total_bar_price = parseFloat(
                            day_rate.multiple_occupancy[
                            rates_for_discount.selected_adult - 1
                            ]
                          );
                          bar_price_for_discount = parseFloat(
                            day_rate.multiple_occupancy[
                            rates_for_discount.selected_adult - 1
                            ]
                          );

                          bar_price_for_individual_gst_calc = parseFloat(
                            day_rate.multiple_occupancy[
                            rates_for_discount.selected_adult - 1
                            ]
                          );
                        } else {
                          total_bar_price = day_rate.bar_price;
                          bar_price_for_discount = day_rate.bar_price;

                          bar_price_for_individual_gst_calc =
                            day_rate.bar_price;
                        }
                      } else {
                        total_bar_price = day_rate.bar_price;
                        bar_price_for_discount = day_rate.bar_price;

                        bar_price_for_individual_gst_calc = day_rate.bar_price;
                      }

                      // changes for fixing price calculation for single,multiple occupancy

                      // total_bar_price = day_rate.bar_price

                      if (
                        rates_for_discount.extra_adult_price > 0 &&
                        rates_for_discount.no_of_extra_adult &&
                        rates_for_discount.no_of_extra_adult > 0
                      ) {
                        total_bar_price =
                          total_bar_price +
                          day_rate.extra_adult_price *
                          rates_for_discount.no_of_extra_adult;
                      }

                      if (
                        rates_for_discount.extra_child_price > 0 &&
                        rates_for_discount.no_of_extra_child &&
                        rates_for_discount.no_of_extra_child > 0
                      ) {
                        total_bar_price =
                          total_bar_price +
                          day_rate.extra_child_price *
                          rates_for_discount.no_of_extra_child;
                      }

                      // bar_price_for_discount = day_rate.bar_price
                      discountPrice +=
                        (bar_price_for_discount / 100) *
                        validCoupon[0].discount;
                      gst_price = total_bar_price - discountPrice;
                      if (addOnChargesData_val.length > 0) {
                        let addOnPrice = calculateAddOnPrice(
                          gst_price,
                          addOnChargesData_val[0]
                        );
                        gstaddonPrice += calculateGstAddOnPrice(
                          addOnPrice,
                          addOnChargesData_val[0]
                        );
                        totalAddonPrice += addOnPrice;
                      }

                      if (localeDetails && localeDetails.gst_slab_id === 1) {
                        const gstPercent = checkGSTPercent(gst_price);
                        gstAmount += (gst_price * gstPercent) / 100;
                      }

                      if (localeDetails && localeDetails.gst_slab_id === 2) {
                        const gstPercent = checkGSTPercent(
                          bar_price_for_individual_gst_calc - discountPrice
                        );
                        gstAmount += (gst_price * gstPercent) / 100;
                      }

                      // const gstPercent = checkGSTPercent(gst_price);
                      // gstAmount += gst_price * gstPercent / 100;
                    }
                  });
              });
              //Codes by Hafiz solving privateCouponCaluculation
            }
          }
        });
    } else {
      dateArray &&
        dateArray.map((value, index) => {
          rooms.map((rates_for_discount) => {
            let gst_price = 0;
            let gst_price_for_individual = 0;
            let total_bar_price = 1;
            let total_pay_price;

            let bar_price_for_individual_gst_calc = 1;
            let bar_price_for_individual_gst_calc_after_disc = 1;

            rates_for_discount.day_wise_rates &&
              rates_for_discount.day_wise_rates.map((day_rate) => {
                if (value === day_rate.date) {
                  // changes for fixing price calculation for single,multiple occupancy
                  if (rates_for_discount.selected_adult < max_people) {
                    if (
                      day_rate.multiple_occupancy[
                      rates_for_discount.selected_adult - 1
                      ]
                    ) {
                      total_bar_price = parseFloat(
                        day_rate.multiple_occupancy[
                        rates_for_discount.selected_adult - 1
                        ]
                      );

                      bar_price_for_individual_gst_calc = parseFloat(
                        day_rate.multiple_occupancy[
                        rates_for_discount.selected_adult - 1
                        ]
                      );
                    } else {
                      total_bar_price = day_rate.bar_price;

                      bar_price_for_individual_gst_calc = day_rate.bar_price;
                    }
                  } else {
                    total_bar_price = day_rate.bar_price;

                    bar_price_for_individual_gst_calc = day_rate.bar_price;
                  }
                  // changes for fixing price calculation for single,multiple occupancy

                  if (
                    rates_for_discount.extra_adult_price > 0 &&
                    rates_for_discount.no_of_extra_adult &&
                    rates_for_discount.no_of_extra_adult > 0
                  ) {
                    total_bar_price =
                      total_bar_price +
                      day_rate.extra_adult_price *
                      rates_for_discount.no_of_extra_adult;
                  }

                  if (
                    rates_for_discount.extra_child_price > 0 &&
                    rates_for_discount.no_of_extra_child &&
                    rates_for_discount.no_of_extra_child > 0
                  ) {
                    total_bar_price =
                      total_bar_price +
                      day_rate.extra_child_price *
                      rates_for_discount.no_of_extra_child;
                  }

                  total_pay_price = total_bar_price;
                  bar_price_for_individual_gst_calc_after_disc = bar_price_for_individual_gst_calc;
                  // changes for fixing price calculation for single,multiple occupancy
                  if (
                    rates_for_discount.public_coupon_discount_price_array &&
                    rates_for_discount.public_coupon_discount_price_array
                      .length > 0
                  ) {
                    rates_for_discount.public_coupon_discount_price_array.map(
                      (pb_coupon_price) => {
                        if (
                          day_rate.date === pb_coupon_price.date &&
                          pb_coupon_price.room === rates_for_discount.room
                        ) {
                          total_pay_price =
                            total_bar_price -
                            pb_coupon_price.public_coupon_discount_price;

                          bar_price_for_individual_gst_calc_after_disc =
                            bar_price_for_individual_gst_calc -
                            pb_coupon_price.public_coupon_discount_price;
                        }
                      }
                    );
                  }
                  // changes for fixing price calculation for single,multiple occupancy

                  gst_price += total_pay_price;
                  gst_price_for_individual += bar_price_for_individual_gst_calc_after_disc;
                  if (addOnChargesData_val.length > 0) {
                    let addOnPrice = calculateAddOnPrice(
                      gst_price,
                      addOnChargesData_val[0]
                    );
                    gstaddonPrice += calculateGstAddOnPrice(
                      addOnPrice,
                      addOnChargesData_val[0]
                    );
                    totalAddonPrice += addOnPrice;
                  }

                  if (localeDetails && localeDetails.gst_slab_id === 1) {
                    const gstPercent = checkGSTPercent(gst_price);
                    gstAmount += (gst_price * gstPercent) / 100;
                  }

                  if (localeDetails && localeDetails.gst_slab_id === 2) {
                    const gstPercent = checkGSTPercent(
                      gst_price_for_individual
                    );
                    gstAmount += (gst_price * gstPercent) / 100;
                  }

                  // const gstPercent = checkGSTPercent(gst_price);
                  // gstAmount += gst_price * gstPercent / 100;
                }
              });
          });
        });
    }

    cartItem.add_on_price = totalAddonPrice;
    cartItem.tax_add_on_price = gstaddonPrice;

    return gstAmount;
  };

  const calculateAddOnPrice = (value, fee_value) => {
    let price = 0;
    price = (value * fee_value.add_on_charges_percentage) / 100;
    return price;
  };

  const calculateGstAddOnPrice = (value, fee_value) => {
    let price = 0;
    price = (value * fee_value.add_on_tax_percentage) / 100;
    return price;
  };

  useEffect(() => {
    const cartIncludedgst = [];

    //changes for solving gst after private coupon
    let gst_total_price = 0;
    let addon_total_price = 0;
    let gst_addon_total_price = 0;

    //changes for solving gst after private coupon

    props.cart.length > 0 &&
      props.cart.map((cartItem) => {
        let applicableCoupon = filteredCoupons.find((ac) => {
          return ac.room_type_id === cartItem.room_type_id;
        });
        if (!applicableCoupon) {
          applicableCoupon = filteredCoupons.find((ac) => {
            return ac.room_type_id === 0;
          });
        }

        if (
          validCoupon[0] &&
          validCoupon[0].coupon_for === 2 &&
          validCoupon[0].room_type_id === 0
        ) {
          applicableCoupon = validCoupon[0];
        }

        let discounted_price;
        if (
          validCoupon[0] &&
          validCoupon[0].coupon_for === 2 &&
          validCoupon[0].room_type_id !== 0 &&
          cartItem.room_type_id == validCoupon[0].room_type_id
        ) {
          discounted_price = calculateForPrivateSpecific(cartItem);
        } else {
          discounted_price = calculateDiscountedPrice(
            cartItem,
            cartItem.discounted_price,
            applicableCoupon
          );
        }
        // console.log('Discount: ', discounted_price);
        const start = moment(dateRange.startDate);
        const end = moment(dateRange.endDate);
        const no_of_days = Math.round(moment.duration(end.diff(start)).days());

        let gstAmount;

        if (cartItem.rooms.length > 1) {
          gstAmount = calculateGstAmount(
            // cartItem.rates_for_coupons,
            no_of_days,
            cartItem.rooms,
            cartItem.room_type_id,

            cartItem.discounted_price,

            cartItem.max_people,

            cartItem,
            addOnChargesData
          ).toFixed(2);
        } else {
          gstAmount = calculateGstAmount(
            // cartItem.rates_for_coupons,
            no_of_days,
            cartItem.rooms,
            cartItem.room_type_id,

            cartItem.discounted_price,
            cartItem.max_people,

            cartItem,
            addOnChargesData
          ).toFixed(2);
        }

        cartItem.gst_price_after_coupon = parseFloat(gstAmount);

        //changes for solving gst after private coupon
        if (isTaxable != 0) {
          cartItem.tax[0].gst_price = parseFloat(gstAmount);
          gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
        }
        //changes for solving gst after private coupon

        addon_total_price += cartItem.add_on_price;
        gst_addon_total_price += cartItem.tax_add_on_price;

        const ItemWithGst = { ...cartItem, gstAmount, no_of_days };
        cartIncludedgst.push(ItemWithGst);
      });

    setTotalAddonPrice(addon_total_price);
    setTotalGstAddonPrice(gst_addon_total_price);

    //changes for solving gst after private coupon
    setTotalGst(gst_total_price);
    if (isTaxable == 1) {
      setGstTotalPriceDisplay(gst_total_price);
    }
    //changes for solving gst after private coupon

    setCartItemsWithGST(cartIncludedgst);
  }, [
    discountPriceDisplay,
    gstTotalPriceDisplay,
    props.cart,
    props.appliedCoupon,
    TotalPriceDisplay,
    filteredCoupons,
    dateRange,
    publicCouponApplied,
    addOnChargesData,
  ]);

  //Commented out codes

  // useEffect(() => {
  //   if (cartItemsWithGST.length > 0) {
  //     const totalGstAmount = cartItemsWithGST.reduce(
  //       (total, gst) => total + parseFloat(gst.gstAmount),
  //       0
  //     );
  //     let totalGst = 0;
  //     props.cart.length > 0 &&
  //       props.cart.map((cartItem) => {
  //         totalGst += cartItem.tax[0].gst_price;
  //       });

  //     let SomeCouponsApplied = props.cart.some((cartItem) => {
  //       if (cartItem.appliedCoupons) return true;
  //     });

  //     console.log(totalGst);
  //     console.log(totalGstAmount);

  //     if (publicCouponApplied && SomeCouponsApplied) {
  //       setGstTotalPriceDisplay(totalGst);
  //       setTotalGst(totalGst);

  //     } else {
  //       setGstTotalPriceDisplay(totalGstAmount);
  //       setTotalGst(totalGstAmount);
  //     }

  //   }
  // }, [cartItemsWithGST, props.cart, dateRange]);
  //Minhaj's Code

  //Remove the Room from cart
  const removeRoom = (cartItem) => {
    //Changes for private coupon discount rates display
    setValidCoupon([]);
    cartItem.private_discount_price = 0;
    cartItem.discounted_price = 0;
    //Changes for private coupon discount rates display

    props.callbackCart(cartItem);
    setPublicCouponApplied(true);
    setPrivateCouponApplied(false);
    sessionStorage.setItem("privateCoupon", "");
  };
  //Set Modal is open Or Not
  //Intially Modal is Open false
  const [isModalOpen, setIsModalOpen] = useState(false);

  //Modal Show or Hide
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    // setSelectedPaymentOptn("prepaid");
    // handleCouponApplyAfterCart();

    if (!isModalOpen) {
      if (validCoupon.length === 0) {
        setAppliedPublicCoupon(props.appliedPublicCoupon);
        setInitialPublicCouponSet(true);
      } else setAppliedPublicCoupon({});
      if (initialPublicCouponSet) {
        setAppliedPublicCoupon(props.appliedPublicCoupon);
      }
    }

    //MY Code
    setPrivateCoupon("");
    setPrivateCuponErr("");
    //MY Code
  };

  const [modalTermsCondition, setModalTermsCondition] = useState(false);

  // Modal to show the terms and condition
  const toggleTermsConditionModal = () => {
    setModalTermsCondition(!modalTermsCondition);
  };

  // Modal Show or Hide for Non Refund Opt
  const toggleModalNonRefund = () => {
    setIsModalOpenNonRefund(!isModalOpenNonRefund);
  };

  // Reconsider Handler
  const reconsiderHandler = () => {
    setSelectedValueForRefund(1);
    setIsModalOpenNonRefund(!isModalOpenNonRefund);
  };

  // proceed with non-refund policy
  const proceedNonRefund = () => {
    setIsModalOpenNonRefund(!isModalOpenNonRefund);
    showUserForm();
  };

  //Intial service Modal
  const [isAddOnServiceModelOpen, setIsAddOnServiceModelOpen] = useState(false);
  const addonServicetoggleModal = () => {
    setIsAddOnServiceModelOpen(!isAddOnServiceModelOpen);
    setServiceAddMessage("");
  };

  const [firstUserInfo, setFirstUserInfo] = useState(true);
  const [userInfo, setUserInfo] = useState(false);
  const [userInfoStep, setUserInfoStep] = useState("step0");
  const addUserInfo = () => {
    setUserInfo(true);
    setFirstUserInfo(false);
    setUserInfoStep("step2");
  };
  const continueAddInfo = () => {
    setStateErr({});
    let textRegex = /^[A-Za-z ]*$/; // validation for guest name
    let emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (!userState.name || userState.name === "null") {
      setStateErr({ nameErr: "Guest name is required!" });
      return false;
    }
    // validation for guest name
    if (!textRegex.test(userState.name)) {
      setStateErr({ nameErr: "Name should contain only letters!" });
      return false;
    }
    //end of validation for guest name
    if (
      !userState.emailId ||
      userState.emailId === "null" ||
      !emailRegex.test(userState.emailId)
    ) {
      setStateErr({ emailIdErr: "Email id should be valid" });
      return false;
    }
    if (!userState.countryCode || userState.countryCode === "null") {
      setStateErr({ countryCodeErr: "Country code should be valid" });
      return false;
    }
    if (
      !userState.mobile ||
      userState.mobile === "null" ||
      userState.mobile.length !== 10
    ) {
      setStateErr({ mobileErr: "Mobile no should be valid" });
      return false;
    }
    if (personalTab && businessTab) {
      if (!userState.companyName || userState.companyName === "null") {
        setStateErr({ companyNameErr: "Company Name should be valid" });
        return false;
      }
      if (!userState.GSTIN || userState.GSTIN === "null") {
        setStateErr({ GSTINErr: "GSTIN should be valid" });
        return false;
      }
    }
    setUserInfoStep("step1");
  };
  const continueAddDetails = () => {
    // console.log(userState);
    setStateErr({});
    if (!userState.address || userState.address === "null") {
      setStateErr({ addressErr: "Address should not be empty" });
      return false;
    }
    if (!userState.countryName || userState.countryName === "null") {
      setStateErr({ countryNameErr: "Country name should not be empty" });
      return false;
    }
    // if (!userState.state || userState.state === "null") {
    //   setStateErr({ stateErr: "State should not be empty" });
    //   return false;
    // }
    // if (!userState.city || userState.city === "null") {
    //   setStateErr({ cityErr: "City should not be empty" });
    //   return false;
    // }
    let zipCodeRegx = /^[a-zA-Z0-9]/;
    if (!userState.zipcode || userState.zipcode === "null") {
      setStateErr({ zipCodeErr: "Zipcode should not be empty" });
      return false;
    }
    if (!zipCodeRegx.test(userState.zipcode)) {
      setStateErr({ zipCodeErr: "Zipcode should be a number" });
      return false;
    }

    if (personalTab && businessTab) {
      if (!userState.companyName || userState.companyName === "null") {
        setStateErr({ companyNameErr: "Company Name should be valid" });
        return false;
      }
      if (!userState.GSTIN || userState.GSTIN === "null") {
        setStateErr({ GSTINErr: "GSTIN should be valid" });
        return false;
      }
    }
    setUserInfoStep("step4");
  };

  // Add paid service
  //Initial paidServiceCart
  const [paidServiceCart, setPaidServiceCart] = useState([]);
  const [limitMessage, setLimitMessage] = useState("");
  const [serviceAddMessage, setServiceAddMessage] = useState("");
  const addService = (service) => {
    // console.log(service, " : **** Service");
    setSelectedPaymentOptn("prepaid");
    let noPaidServiceCart = true;
    let newPaidserviceCart = [];
    paidServiceCart &&
      paidServiceCart.map((paidService) => {
        if (paidService.service_no == service.paid_service_id) {
          if (paidService.qty < 3) {
            paidService.qty += 1;
            noPaidServiceCart = false;
            setLimitMessage("");
          } else {
            noPaidServiceCart = false;
            setLimitMessage("You cannot add more than 3 add-on services!");
          }
        }
      });
    newPaidserviceCart = [...paidServiceCart];
    if (noPaidServiceCart) {
      let paidServiceObject = {
        service_no: service.paid_service_id,
        service_name: service.service_name,
        price:
          currency != baseCurrency
            ? Math.ceil(
              parseFloat((service.service_amount * currencyValue).toFixed(2))
            )
            : service.service_amount,
        display_price: service.service_amount,
        qty: 1,
        service_tax: service.service_tax,
        service_tax_price: service.service_tax_price,
      };
      newPaidserviceCart = [...paidServiceCart];
      newPaidserviceCart.push(paidServiceObject);
    }
    setPaidServiceCart(newPaidserviceCart);
    setServiceAddMessage("A service has been added !");
    //totalPaidServicePrice()
  };
  const removeService = (paidService) => {
    setSelectedPaymentOptn("prepaid");
    paidServiceCart &&
      paidServiceCart.map((paidServiceIncart, index) => {
        if (paidServiceIncart.service_no === paidService.service_no) {
          paidServiceCart.splice(index, 1);
        }
      });
    let newPaidserviceCart = [...paidServiceCart];
    setPaidServiceCart(newPaidserviceCart);
    setServiceAddMessage("");
  };
  //Set the Total Price  After the paid service
  const [totalPaidService, setTotalPaidService] = useState(0);
  const [displayTotalPaidService, setDisplayTotalPaidService] = useState(0);
  // const totalPaidServicePrice = () => {
  //   let paid_service_total = 0;
  //   let display_paid_service_total = 0;
  //   setTotalPaidService(paid_service_total);
  //   paidServiceCart &&
  //     paidServiceCart.map((paidService) => {
  //       paid_service_total += paidService.price * paidService.qty;
  //       display_paid_service_total +=
  //         paidService.display_price * paidService.qty;
  //     });
  //   setTotalPaidService(paid_service_total);
  //   setDisplayTotalPaidService(display_paid_service_total);
  // };

  const totalPaidServicePrice = () => {
    let paid_service_total = 0;
    let display_paid_service_total = 0;
    let total_gst_price_for_paid_service = 0;
    let total_paid_service_price = 0;
    setTotalPaidService(paid_service_total);
    paidServiceCart &&
      paidServiceCart.map((paidService) => {
        paid_service_total += paidService.price * paidService.qty;
        display_paid_service_total +=
          paidService.display_price * paidService.qty;
        total_gst_price_for_paid_service +=
          ((paidService.price * paidService.service_tax) / 100) *
          paidService.qty;
      });
    if (isTaxable == 1) {
      total_paid_service_price =
        paid_service_total + total_gst_price_for_paid_service;
    } else {
      total_paid_service_price = paid_service_total;
    }

    setTotalPaidService(total_paid_service_price);
    setDisplayTotalPaidService(display_paid_service_total);
  };

  //Total Paid Service
  useEffect(() => {
    totalPaidServicePrice();
    setCheckedStatus(false);
    getTotal(props.cart);
  }, [paidServiceCart, props.cart]);

  //Partial Payment Option
  //API CALL to fetch Partial amount pay status and percent
  const [hotelData, setHotelData] = useState();
  const [localeDetails, setLocaleDetails] = useState();

  useEffect(() => {
    if (hotelId != "" && apiKey != 0) {
      fetchHotelInfo(
        hotelId,
        apiKey,
        setHotelData,
        setOverSeaFilter,
        setCountryID,
        setIsTaxable
      );
      getLocaleDetails(hotelId, setLocaleDetails);

      fetchAddonChargesData(hotelId, setAddOnChargesData);
    }
  }, [hotelId, apiKey, setHotelData]);

  const [partialPayPercent, setPartialPayPercent] = useState(100);
  const [partialPayStatus, setPartialPayStatus] = useState(false);
  const [payatHotelStatus, setPayatHotelStatus] = useState(false);
  useEffect(() => {
    // console.log(hotelData, " : hotelData");
    hotelData &&
      hotelData.hasOwnProperty("partial_pay_amt") &&
      setPartialPayPercent(hotelData.partial_pay_amt);
    if (
      hotelData &&
      hotelData.hasOwnProperty("partial_pay_amt") &&
      0 < parseFloat(hotelData.partial_pay_amt) &&
      parseFloat(hotelData.partial_pay_amt) < 100
    ) {
      setPartialPayStatus(true);
    }
    // if (
    //   hotelData &&
    //   hotelData.hasOwnProperty("partial_payment") &&
    //   hotelData.partial_payment === 1
    // ) {
    //   setPartialPayStatus(true);
    // }
    // pay_at_hotel
    if (
      hotelData &&
      hotelData.hasOwnProperty("partial_payment") &&
      hotelData.pay_at_hotel === 1
    ) {
      setPayatHotelStatus(true);
    }
    // console.log(hotelData, " : ** hotelData");
  }, [hotelData]);

  const [partialPay, setPartialPay] = useState(
    totalPrice +
    totalGst -
    totalDiscount +
    totalPaidService +
    totalAddonPrice +
    totalGstAddonPrice
  );
  const [partialPaydisplay, setPartialPayDisplay] = useState(
    currency != baseCurrency
      ? parseFloat(
        (
          (totalPrice +
            totalGst -
            totalDiscount +
            totalPaidService +
            totalAddonPrice +
            totalGstAddonPrice) *
          currencyValue
        ).toFixed(2)
      )
      : (
        totalPrice +
        totalGst -
        totalDiscount +
        totalPaidService +
        totalAddonPrice +
        totalGstAddonPrice
      ).toFixed(2)
  );
  const [checkedStatus, setCheckedStatus] = useState(false);

  const [refundProtectPrice, setRefundProtectPrice] = useState(0);
  const [refundProtectTaxPrice, setRefundProtectTaxPrice] = useState(0);

  let check_in_date = moment(dateRange.startDate).format("YYYY-MM-DD");
  let today_date = moment().format("YYYY-MM-DD");

  // Handler function for Cart creation - Modified/customized to support opyion for Refund Protect
  useEffect(() => {
    let refund_protect_price = 0;
    let refund_protect_tax_price = 0;

    if (parseFloat(partialPayPercent) === parseFloat(selectedPaymentOptn)) {
      // console.log("Partial pay status is  selected");
      if (isTaxable == 1) {
        // Refund Protect
        let insurance_price = 0;
        let insurance_tax_price = 0;

        let paid_amount = partialPay;

        if (
          optedForRefund &&
          optedForRefund.status === 1 &&
          selectedValueForRefund === 1 &&
          check_in_date !== today_date &&
          checkedStatus === false
        ) {
          // let paid_amount = totalPrice + totalGst - totalDiscount + totalPaidService + totalAddonPrice + totalGstAddonPrice;
          if (optedForRefund.mode === "optional") {
            insurance_price = (paid_amount * 9) / 100;
          }
          if (optedForRefund.mode === "mandatory") {
            insurance_price = (paid_amount * 7) / 100;
          }
        }
        refund_protect_price = insurance_price;

        insurance_tax_price = (insurance_price * 18) / 100;
        refund_protect_tax_price = insurance_tax_price;
        // Refund Protect

        setAllTotalPriceDisplay(
          currency != baseCurrency
            ? parseFloat(
              (
                (totalPrice +
                  totalGst -
                  totalDiscount +
                  totalPaidService +
                  insurance_price +
                  insurance_tax_price +
                  totalAddonPrice +
                  totalGstAddonPrice) *
                currencyValue
              ).toFixed(2)
            )
            : (
              totalPrice +
              totalGst -
              totalDiscount +
              totalPaidService +
              insurance_price +
              insurance_tax_price +
              totalAddonPrice +
              totalGstAddonPrice
            ).toFixed(2)
        );

        setPartialPay(
          ((totalPrice +
            totalGst -
            totalDiscount +
            totalPaidService +
            // insurance_price +
            // insurance_tax_price +
            totalAddonPrice +
            totalGstAddonPrice) *
            parseFloat(selectedPaymentOptn)) /
          100 +
          insurance_price +
          insurance_tax_price
          // paid_amount + insurance_price + insurance_tax_price
        );

        setPartialPayDisplay(
          currency != baseCurrency
            ? parseFloat(
              (
                (((totalPrice +
                  totalGst -
                  totalDiscount +
                  totalPaidService +
                  // insurance_price +
                  // insurance_tax_price +
                  totalAddonPrice +
                  totalGstAddonPrice) *
                  parseFloat(selectedPaymentOptn)) /
                  100 +
                  insurance_price +
                  insurance_tax_price) *
                currencyValue
              )
                // (paid_amount + insurance_price + insurance_tax_price) * currencyValue
                .toFixed(2)
            )
            : (
              ((totalPrice +
                totalGst -
                totalDiscount +
                totalPaidService +
                // insurance_price +
                // insurance_tax_price +
                totalAddonPrice +
                totalGstAddonPrice) *
                parseFloat(selectedPaymentOptn)) /
              100 +
              insurance_price +
              insurance_tax_price
            )
              // paid_amount + insurance_price + insurance_tax_price
              .toFixed(2)
        );
      }

      if (isTaxable == 0) {
        // Refund Protect
        let insurance_price = 0;
        let insurance_tax_price = 0;

        let paid_amount = partialPay;

        if (
          optedForRefund &&
          optedForRefund.status === 1 &&
          selectedValueForRefund === 1 &&
          check_in_date !== today_date &&
          checkedStatus === false
        ) {
          // let paid_amount = totalPrice - totalDiscount + totalPaidService + totalAddonPrice + totalGstAddonPrice;
          if (optedForRefund.mode === "optional") {
            insurance_price = (paid_amount * 9) / 100;
          }
          if (optedForRefund.mode === "mandatory") {
            insurance_price = (paid_amount * 7) / 100;
          }
        }
        refund_protect_price = insurance_price;

        insurance_tax_price = (insurance_price * 18) / 100;
        refund_protect_tax_price = insurance_tax_price;

        // Refund Protect

        setAllTotalPriceDisplay(
          currency != baseCurrency
            ? parseFloat(
              (
                (totalPrice -
                  totalDiscount +
                  totalPaidService +
                  insurance_price +
                  insurance_tax_price +
                  totalAddonPrice +
                  totalGstAddonPrice) *
                currencyValue
              ).toFixed(2)
            )
            : (
              totalPrice -
              totalDiscount +
              totalPaidService +
              insurance_price +
              insurance_tax_price +
              totalAddonPrice +
              totalGstAddonPrice
            ).toFixed(2)
        );

        setPartialPay(
          ((totalPrice -
            totalDiscount +
            totalPaidService +
            // insurance_price +
            // insurance_tax_price +
            totalAddonPrice +
            totalGstAddonPrice) *
            parseFloat(selectedPaymentOptn)) /
          100 +
          insurance_price +
          insurance_tax_price
          // paid_amount + insurance_price + insurance_tax_price
        );

        setPartialPayDisplay(
          currency != baseCurrency
            ? parseFloat(
              (
                (((totalPrice -
                  totalDiscount +
                  totalPaidService +
                  // insurance_price +
                  // insurance_tax_price +
                  totalAddonPrice +
                  totalGstAddonPrice) *
                  parseFloat(selectedPaymentOptn)) /
                  100 +
                  insurance_price +
                  insurance_tax_price) *
                currencyValue
              )
                // (paid_amount + insurance_price + insurance_tax_price) * currencyValue
                .toFixed(2)
            )
            : (
              ((totalPrice -
                totalDiscount +
                totalPaidService +
                insurance_price +
                insurance_tax_price +
                totalAddonPrice +
                totalGstAddonPrice) *
                parseFloat(selectedPaymentOptn)) /
              100 +
              insurance_price +
              insurance_tax_price
            )
              // paid_amount + insurance_price + insurance_tax_price
              .toFixed(2)
        );
      }

      props.cart.map((cartItem) => {
        // let paid_amount = totalPrice +
        //   totalGst -
        //   totalDiscount +
        //   totalPaidService +
        //   refund_protect_price + refund_protect_tax_price + totalAddonPrice + totalGstAddonPrice;
        let paid_amount =
          partialPay + refund_protect_price + refund_protect_tax_price;

        parseFloat(partialPayPercent) === 0
          ? (cartItem.paid_amount = 0)
          : (cartItem.paid_amount = paid_amount.toFixed(2));

        // cartItem.paid_amount =
        //   totalPrice +
        //   totalGst -
        //   totalDiscount +
        //   totalPaidService +
        //   refund_protect_price;
        parseFloat(partialPayPercent) === 0
          ? (cartItem.paid_amount_per = 0)
          : (cartItem.paid_amount_per = parseFloat(partialPayPercent));

        if (cartItem.private_discount_price) {
          cartItem.discounted_price = cartItem.private_discount_price;
        }

        //changes for solving gst after private coupon
        // if (overSeaFilter != 1) {
        //   cartItem.tax[0].gst_price = cartItem.gst_price_after_coupon;
        // }
        //changes for solving gst after private coupon

        // setTotalProductPrice(totalPrice + totalGst - totalDiscount + totalPaidService + refund_protect_price);
        // setRefundProtectPrice(refund_protect_price)
      });
    } else {
      if (isTaxable == 1) {
        // Refund Protect
        let insurance_price = 0;
        let insurance_tax_price = 0;
        if (
          optedForRefund &&
          optedForRefund.status === 1 &&
          selectedValueForRefund === 1 &&
          check_in_date !== today_date &&
          checkedStatus === false
        ) {
          let paid_amount =
            totalPrice +
            totalGst -
            totalDiscount +
            totalPaidService +
            totalAddonPrice +
            totalGstAddonPrice;
          if (optedForRefund.mode === "optional") {
            insurance_price = (paid_amount * 9) / 100;
          }
          if (optedForRefund.mode === "mandatory") {
            insurance_price = (paid_amount * 7) / 100;
          }
        }
        refund_protect_price = insurance_price;

        insurance_tax_price = (insurance_price * 18) / 100;
        refund_protect_tax_price = insurance_tax_price;
        // Refund Protect

        setAllTotalPriceDisplay(
          currency != baseCurrency
            ? parseFloat(
              (
                (totalPrice +
                  totalGst -
                  totalDiscount +
                  totalPaidService +
                  insurance_price +
                  insurance_tax_price +
                  totalAddonPrice +
                  totalGstAddonPrice) *
                currencyValue
              ).toFixed(2)
            )
            : (
              totalPrice +
              totalGst -
              totalDiscount +
              totalPaidService +
              insurance_price +
              insurance_tax_price +
              totalAddonPrice +
              totalGstAddonPrice
            ).toFixed(2)
        );

        setPartialPay(
          totalPrice +
          totalGst -
          totalDiscount +
          totalPaidService +
          insurance_price +
          insurance_tax_price +
          totalAddonPrice +
          totalGstAddonPrice
        );

        setPartialPayDisplay(
          currency != baseCurrency
            ? parseFloat(
              (
                (totalPrice +
                  totalGst -
                  totalDiscount +
                  totalPaidService +
                  insurance_price +
                  insurance_tax_price +
                  totalAddonPrice +
                  totalGstAddonPrice) *
                currencyValue
              ).toFixed(2)
            )
            : (
              totalPrice +
              totalGst -
              totalDiscount +
              totalPaidService +
              insurance_price +
              insurance_tax_price +
              totalAddonPrice +
              totalGstAddonPrice
            ).toFixed(2)
        );
      }

      if (isTaxable == 0) {
        // Refund Protect
        let insurance_price = 0;
        let insurance_tax_price = 0;
        if (
          optedForRefund &&
          optedForRefund.status === 1 &&
          selectedValueForRefund === 1 &&
          check_in_date !== today_date &&
          checkedStatus === false
        ) {
          let paid_amount =
            totalPrice -
            totalDiscount +
            totalPaidService +
            totalAddonPrice +
            totalGstAddonPrice;
          if (optedForRefund.mode === "optional") {
            insurance_price = (paid_amount * 9) / 100;
          }
          if (optedForRefund.mode === "mandatory") {
            insurance_price = (paid_amount * 7) / 100;
          }
        }
        refund_protect_price = insurance_price;

        insurance_tax_price = (insurance_price * 18) / 100;
        refund_protect_tax_price = insurance_tax_price;

        // Refund Protect

        setAllTotalPriceDisplay(
          currency != baseCurrency
            ? parseFloat(
              (
                (totalPrice -
                  totalDiscount +
                  totalPaidService +
                  insurance_price +
                  insurance_tax_price +
                  totalAddonPrice +
                  totalGstAddonPrice) *
                currencyValue
              ).toFixed(2)
            )
            : (
              totalPrice -
              totalDiscount +
              totalPaidService +
              insurance_price +
              insurance_tax_price +
              totalAddonPrice +
              totalGstAddonPrice
            ).toFixed(2)
        );

        setPartialPay(
          totalPrice -
          totalDiscount +
          totalPaidService +
          insurance_price +
          insurance_tax_price +
          totalAddonPrice +
          totalGstAddonPrice
        );

        setPartialPayDisplay(
          currency != baseCurrency
            ? parseFloat(
              (
                (totalPrice -
                  totalDiscount +
                  totalPaidService +
                  insurance_price +
                  insurance_tax_price +
                  totalAddonPrice +
                  totalGstAddonPrice) *
                currencyValue
              ).toFixed(2)
            )
            : (
              totalPrice -
              totalDiscount +
              totalPaidService +
              insurance_price +
              insurance_tax_price +
              totalAddonPrice +
              totalGstAddonPrice
            ).toFixed(2)
        );
      }

      props.cart.map((cartItem) => {
        let paid_amount =
          totalPrice +
          totalGst -
          totalDiscount +
          totalPaidService +
          refund_protect_price +
          refund_protect_tax_price +
          totalAddonPrice +
          totalGstAddonPrice;

        // parseFloat(partialPayPercent) === 0 ? cartItem.paid_amount = 0 : cartItem.paid_amount = paid_amount.toFixed(2);
        cartItem.paid_amount = paid_amount.toFixed(2);
        // cartItem.paid_amount =
        //   totalPrice +
        //   totalGst -
        //   totalDiscount +
        //   totalPaidService +
        //   refund_protect_price;
        // parseFloat(partialPayPercent) === 0 ? cartItem.paid_amount_per = 0 : cartItem.paid_amount_per = 100;
        selectedPaymentOptn === "prepaid"
          ? (cartItem.paid_amount_per = 100)
          : (cartItem.paid_amount_per = 0);

        if (cartItem.private_discount_price) {
          cartItem.discounted_price = cartItem.private_discount_price;
        }

        //changes for solving gst after private coupon
        // if (overSeaFilter != 1) {
        //   cartItem.tax[0].gst_price = cartItem.gst_price_after_coupon;
        // }
        //changes for solving gst after private coupon

        // setTotalProductPrice(totalPrice + totalGst - totalDiscount + totalPaidService + refund_protect_price);
        // setRefundProtectPrice(refund_protect_price)
      });
    }
    setTotalProductPrice(
      totalPrice +
      totalGst -
      totalDiscount +
      totalPaidService +
      refund_protect_price +
      refund_protect_tax_price +
      totalAddonPrice +
      totalGstAddonPrice
    );
    setRefundProtectPrice(refund_protect_price);
    setRefundProtectTaxPrice(refund_protect_tax_price);
  }, [
    totalPrice,
    totalDiscount,
    totalGst,
    // otherTaxTotal,
    totalDiscount,
    totalPaidService,
    props.cart,
    // Refund Protect
    selectedValueForRefund,
    // Refund Protect
  ]);

  const [termsCondtionState, setTermsCondtionState] = useState(false);

  // handler function for terms and condition
  const handleTermsCondition = (e) => {
    if (e.target.checked) {
      setTermsCondtionState(true);
    } else {
      setTermsCondtionState(false);
    }
  };

  // Handler function the Partial payment
  const handlePartialPay = (e) => {
    let partial_amount = 0;
    let partialPayPercent = 100;

    setSelectedValueForRefund(0);

    setCheckBoxStatus(false);

    if (e.target.value === "prepaid") {
      setSelectedPaymentOptn("prepaid");
      if (isTaxable == 1) {
        setAllTotalPriceDisplay(
          currency != baseCurrency
            ? parseFloat(
              (
                (totalPrice +
                  totalGst -
                  totalDiscount +
                  totalPaidService +
                  refundProtectPrice +
                  refundProtectTaxPrice +
                  totalAddonPrice +
                  totalGstAddonPrice) *
                currencyValue
              ).toFixed(2)
            )
            : (
              totalPrice +
              totalGst -
              totalDiscount +
              totalPaidService +
              refundProtectPrice +
              refundProtectTaxPrice +
              totalAddonPrice +
              totalGstAddonPrice
            ).toFixed(2)
        );

        partial_amount =
          totalPrice +
          totalGst -
          totalDiscount +
          totalPaidService +
          refundProtectPrice +
          refundProtectTaxPrice +
          totalAddonPrice +
          totalGstAddonPrice;
        // setPartialPay(partial_amount);
        setPartialPayDisplay(
          currency != baseCurrency
            ? parseFloat((partial_amount * currencyValue).toFixed(2))
            : partial_amount.toFixed(2)
        );
      }
      if (isTaxable == 0) {
        setAllTotalPriceDisplay(
          currency != baseCurrency
            ? parseFloat(
              (
                (totalPrice -
                  totalDiscount +
                  totalPaidService +
                  refundProtectPrice +
                  refundProtectTaxPrice +
                  totalAddonPrice +
                  totalGstAddonPrice) *
                currencyValue
              ).toFixed(2)
            )
            : (
              totalPrice -
              totalDiscount +
              totalPaidService +
              refundProtectPrice +
              refundProtectTaxPrice +
              totalAddonPrice +
              totalGstAddonPrice
            ).toFixed(2)
        );

        partial_amount =
          totalPrice -
          totalDiscount +
          totalPaidService +
          refundProtectPrice +
          refundProtectTaxPrice +
          totalAddonPrice +
          totalGstAddonPrice;
        // setPartialPay(partial_amount);
        setPartialPayDisplay(
          currency != baseCurrency
            ? parseFloat((partial_amount * currencyValue).toFixed(2))
            : partial_amount.toFixed(2)
        );
      }
      partialPayPercent = 100;
      // partialPayPercent
    } else if (e.target.value === "payathotel") {
      setSelectedPaymentOptn("payathotel");
      if (isTaxable == 1) {
        setAllTotalPriceDisplay(
          currency != baseCurrency
            ? parseFloat(
              (
                (totalPrice +
                  totalGst -
                  totalDiscount +
                  totalPaidService +
                  refundProtectPrice +
                  refundProtectTaxPrice +
                  totalAddonPrice +
                  totalGstAddonPrice) *
                currencyValue
              ).toFixed(2)
            )
            : (
              totalPrice +
              totalGst -
              totalDiscount +
              totalPaidService +
              refundProtectPrice +
              refundProtectTaxPrice +
              totalAddonPrice +
              totalGstAddonPrice
            ).toFixed(2)
        );

        partial_amount =
          totalPrice +
          totalGst -
          totalDiscount +
          totalPaidService +
          refundProtectPrice +
          refundProtectTaxPrice +
          totalAddonPrice +
          totalGstAddonPrice;
        // setPartialPay(partial_amount);
        setPartialPayDisplay(
          currency != baseCurrency
            ? parseFloat((partial_amount * currencyValue).toFixed(2))
            : partial_amount.toFixed(2)
        );
      }
      if (isTaxable == 0) {
        setAllTotalPriceDisplay(
          currency != baseCurrency
            ? parseFloat(
              (
                (totalPrice -
                  totalDiscount +
                  totalPaidService +
                  refundProtectPrice +
                  refundProtectTaxPrice +
                  totalAddonPrice +
                  totalGstAddonPrice) *
                currencyValue
              ).toFixed(2)
            )
            : (
              totalPrice -
              totalDiscount +
              totalPaidService +
              refundProtectPrice +
              refundProtectTaxPrice +
              totalAddonPrice +
              totalGstAddonPrice
            ).toFixed(2)
        );

        partial_amount =
          totalPrice -
          totalDiscount +
          totalPaidService +
          refundProtectPrice +
          refundProtectTaxPrice +
          totalAddonPrice +
          totalGstAddonPrice;
        // setPartialPay(partial_amount);
        setPartialPayDisplay(
          currency != baseCurrency
            ? parseFloat((partial_amount * currencyValue).toFixed(2))
            : partial_amount.toFixed(2)
        );
      }
      partialPayPercent = 0;
      partial_amount = 0;
    } else {
      // Logic for manipulating cart on partial pay
      setSelectedPaymentOptn("partialpayment");
      if (e.target.checked) {
        if (isTaxable == 1) {
          setAllTotalPriceDisplay(
            currency != baseCurrency
              ? parseFloat(
                (
                  (totalPrice +
                    totalGst -
                    totalDiscount +
                    totalPaidService +
                    totalAddonPrice +
                    totalGstAddonPrice) *
                  currencyValue
                ).toFixed(2)
              )
              : (
                totalPrice +
                totalGst -
                totalDiscount +
                totalPaidService +
                totalAddonPrice +
                totalGstAddonPrice
              ).toFixed(2)
          );

          partial_amount =
            ((totalPrice +
              totalGst -
              totalDiscount +
              totalPaidService +
              totalAddonPrice +
              totalGstAddonPrice) *
              e.target.value) /
            100;
          setPartialPayDisplay(
            currency != baseCurrency
              ? parseFloat((partial_amount * currencyValue).toFixed(2))
              : partial_amount.toFixed(2)
          );
          setPartialPay(partial_amount);
        }

        if (isTaxable == 0) {
          setAllTotalPriceDisplay(
            currency != baseCurrency
              ? parseFloat(
                (
                  (totalPrice -
                    totalDiscount +
                    totalPaidService +
                    totalAddonPrice +
                    totalGstAddonPrice) *
                  currencyValue
                ).toFixed(2)
              )
              : (
                totalPrice -
                totalDiscount +
                totalPaidService +
                totalAddonPrice +
                totalGstAddonPrice
              ).toFixed(2)
          );

          partial_amount =
            ((totalPrice -
              totalDiscount +
              totalPaidService +
              totalAddonPrice +
              totalGstAddonPrice) *
              e.target.value) /
            100;
          setPartialPayDisplay(
            currency != baseCurrency
              ? parseFloat((partial_amount * currencyValue).toFixed(2))
              : partial_amount.toFixed(2)
          );
          setPartialPay(partial_amount);
        }

        partialPayPercent = e.target.value;
        // setCheckedStatus(true);
      }
      // else {
      // if (isTaxable == 1) {
      //   setAllTotalPriceDisplay(
      //     currency != baseCurrency
      //       ? parseFloat(
      //         (
      //           (totalPrice + totalGst - totalDiscount + totalPaidService + refundProtectPrice + refundProtectTaxPrice + totalAddonPrice + totalGstAddonPrice) *
      //           currencyValue
      //         ).toFixed(2)
      //       )
      //       : (
      //         totalPrice +
      //         totalGst -
      //         totalDiscount +
      //         totalPaidService +
      //         refundProtectPrice + refundProtectTaxPrice + totalAddonPrice + totalGstAddonPrice
      //       ).toFixed(2)
      //   );

      //   partial_amount =
      //     totalPrice +
      //     totalGst -
      //     totalDiscount +
      //     totalPaidService +
      //     refundProtectPrice + refundProtectTaxPrice + totalAddonPrice + totalGstAddonPrice;
      //   setPartialPay(partial_amount);
      //   setPartialPayDisplay(
      //     currency != baseCurrency
      //       ? parseFloat(
      //         (
      //           (partial_amount) *
      //           currencyValue
      //         ).toFixed(2)
      //       )
      //       : (
      //         partial_amount
      //       ).toFixed(2)
      //   );
      // }
      // if (isTaxable == 0) {
      //   setAllTotalPriceDisplay(
      //     currency != baseCurrency
      //       ? parseFloat(
      //         (
      //           (totalPrice - totalDiscount + totalPaidService + refundProtectPrice + refundProtectTaxPrice + totalAddonPrice + totalGstAddonPrice) *
      //           currencyValue
      //         ).toFixed(2)
      //       )
      //       : (
      //         totalPrice -
      //         totalDiscount +
      //         totalPaidService +
      //         refundProtectPrice + refundProtectTaxPrice + totalAddonPrice + totalGstAddonPrice
      //       ).toFixed(2)
      //   );

      //   partial_amount =
      //     totalPrice -
      //     totalDiscount +
      //     totalPaidService +
      //     refundProtectPrice + refundProtectTaxPrice + totalAddonPrice + totalGstAddonPrice;
      //   setPartialPay(partial_amount);
      //   setPartialPayDisplay(
      //     currency != baseCurrency
      //       ? parseFloat(
      //         (
      //           (partial_amount) *
      //           currencyValue
      //         ).toFixed(2)
      //       )
      //       : (
      //         partial_amount
      //       ).toFixed(2)
      //   );
      // }
      // setCheckedStatus(false);
      // }
    }

    props.cart.map((cartItem) => {
      cartItem.paid_amount = partial_amount.toFixed(2);
      cartItem.paid_amount_per = parseInt(partialPayPercent);
    });
  };

  // Handler function for converting option to prepaid when applied coupon to prevent logic mutation
  const handleCouponApplyAfterCart = () => {
    let partial_amount = 0;
    let partialPayPercent = 100;
    setSelectedValueForRefund(0);
    setSelectedPaymentOptn("prepaid");
    if (isTaxable == 1) {
      setAllTotalPriceDisplay(
        currency != baseCurrency
          ? parseFloat(
            (
              (totalPrice +
                totalGst -
                totalDiscount +
                totalPaidService +
                refundProtectPrice +
                refundProtectTaxPrice +
                totalAddonPrice +
                totalGstAddonPrice) *
              currencyValue
            ).toFixed(2)
          )
          : (
            totalPrice +
            totalGst -
            totalDiscount +
            totalPaidService +
            refundProtectPrice +
            refundProtectTaxPrice +
            totalAddonPrice +
            totalGstAddonPrice
          ).toFixed(2)
      );

      partial_amount =
        totalPrice +
        totalGst -
        totalDiscount +
        totalPaidService +
        refundProtectPrice +
        refundProtectTaxPrice +
        totalAddonPrice +
        totalGstAddonPrice;
      // setPartialPay(partial_amount);
      setPartialPayDisplay(
        currency != baseCurrency
          ? parseFloat((partial_amount * currencyValue).toFixed(2))
          : partial_amount.toFixed(2)
      );
    }
    if (isTaxable == 0) {
      setAllTotalPriceDisplay(
        currency != baseCurrency
          ? parseFloat(
            (
              (totalPrice -
                totalDiscount +
                totalPaidService +
                refundProtectPrice +
                refundProtectTaxPrice +
                totalAddonPrice +
                totalGstAddonPrice) *
              currencyValue
            ).toFixed(2)
          )
          : (
            totalPrice -
            totalDiscount +
            totalPaidService +
            refundProtectPrice +
            refundProtectTaxPrice +
            totalAddonPrice +
            totalGstAddonPrice
          ).toFixed(2)
      );

      partial_amount =
        totalPrice -
        totalDiscount +
        totalPaidService +
        refundProtectPrice +
        refundProtectTaxPrice +
        totalAddonPrice +
        totalGstAddonPrice;
      // setPartialPay(partial_amount);
      setPartialPayDisplay(
        currency != baseCurrency
          ? parseFloat((partial_amount * currencyValue).toFixed(2))
          : partial_amount.toFixed(2)
      );
    }
    props.cart.map((cartItem) => {
      cartItem.paid_amount = partial_amount.toFixed(2);
      cartItem.paid_amount_per = parseInt(partialPayPercent);
    });
  };

  //Country code
  const [countryCodes, setCountryCodes] = useState([]);
  useEffect(() => {
    setCountryCodes(loadData());
  }, [setCountryCodes]);

  //user identity code
  const [identityData, setIdentityData] = useState([]);
  useEffect(() => {
    setIdentityData(loadIdentityData());
  }, [setIdentityData]);
  //Set the user details form Initially hidden
  const [userFormOpen, setUserFormOpen] = useState(false);

  let userLoggedIn = sessionStorage.getItem("user_logged_in") && true;

  const [isModalOpenNonRefund, setIsModalOpenNonRefund] = useState(false);

  // optForRefund Validation added before showUseForm
  const showUseFormValidation = () => {
    if (
      optedForRefund &&
      optedForRefund.mode === "optional" &&
      optedForRefund.status === 1 &&
      check_in_date !== today_date
    ) {
      if (
        selectedValueForRefund === 0 &&
        selectedPaymentOptn !== "payathotel"
      ) {
        setIsModalOpenNonRefund(true);
      } else {
        showUserForm();
      }
    } else {
      showUserForm();
    }
  };

  // Toggel refund prtect modal while userform open
  // useEffect(() => {
  //   if (userFormOpen && selectedValueForRefund === 0) {
  //     // setUserFormOpen(!userFormOpen)
  //     setIsModalOpenNonRefund(true);
  //   } else {
  //     setIsModalOpenNonRefund(false);
  //   }
  // }, [selectedValueForRefund])

  // Handler for closing user form on total amount change - while opt/deselect for refund-protect
  useEffect(() => {
    setUserFormOpen(false);
    setUserInfoStep("step0");
  }, [partialPaydisplay, selectedValueForRefund]);

  //Show User form
  const showUserForm = () => {
    if (token && userLoggedIn && props.cart.length > 0) {
      let facebook_pixel_data = JSON.parse(
        sessionStorage.getItem("facebook_pixel_data")
      );
      if (facebook_pixel_data && facebook_pixel_data.initiate_checkout) {
        ReactPixel.fbq("track", "InitiateCheckout");
      }

      setLoading(true);
      newBookRequest(
        hotelId,
        dateRange,
        props.cart,
        props.appliedCoupon,
        paidServiceCart,
        apiKey,
        token,
        //Refund Protect
        currency,
        totalProductPrice,
        selectedValueForRefund,
        optedForRefund,
        refundProtectPrice,
        //Refund Protect
        totalAddonPrice,
        totalGstAddonPrice,
        addOnChargesData,
        guestNote,
        arrivalTime,
        checkedStatus,
        refundProtectTaxPrice,
        //prepaid check
        partialPayPercent,
        selectedPaymentOptn,
        hotelData,
        userState
      );
    } else {
      setUserFormOpen(true);
    }
  };

  //User Personal orBusiness
  const [personalTab, setPersonalTab] = useState(true);
  const [businessTab, setBusinessTab] = useState(false);

  //Activate User or Business
  const activateTab = (type) => {
    setUserInfoStep("step0");
    if (type == "personal") {
      setPersonalTab(true);
      setBusinessTab(false);
    } else {
      setPersonalTab(true);
      setBusinessTab(true);
    }
  };

  const [
    {
      nameErr,
      emailIdErr,
      countryCodeErr,
      mobileErr,
      companyNameErr,
      GSTINErr,
      identityErr,
      addressErr,
      countryNameErr,
      zipCodeErr,
      cityErr,
      stateErr,
      identityNoErr,
      expiryDateErr,
      dobErr,
    },
    setStateErr,
  ] = useState("");

  const handleChange = (e, name) => {
    if (name === "countryName") {
      setAllState([]);
      setAllCity([]);
      setUserState((prevState) => ({
        ...prevState,
        state: "",
        city: "",
      }));

      let country_id;
      allCountry.map((item) => {
        if (item.country_name === e) {
          country_id = item.country_id;
        }
      });
      setCountryID(country_id);
    }

    if (name === "state") {
      setAllCity([]);
      setUserState((prevState) => ({
        ...prevState,
        city: "",
      }));

      let state_id;
      allState.map((item) => {
        if (item.state_name === e) {
          state_id = item.state_id;
        }
      });
      setStateID(state_id);
    }

    setUserState((prevState) => ({ ...prevState, [name]: e }));
  };
  useEffect(() => {
    if (currency == "BDT") {
      setUserState((prevState) => ({
        ...prevState,
        countryName: "Bangladesh",
      }));
      setUserState((prevState) => ({ ...prevState, countryCode: "+880" }));
    }
    if (currency == "INR") {
      // setUserState(prevState => ({ ...prevState, countryName: 'India' }));
      setUserState((prevState) => ({ ...prevState, countryCode: "+91" }));
    }
  }, [currency]);

  useEffect(() => {
    const initialDiscountValue = Math.min.apply(
      Math,
      props.coupons.map((coup) => coup.discount)
    );

    const leastCoupon = props.coupons.find(
      (coup) => coup.discount === initialDiscountValue
    );
    // console.log('Coupons: ', props.coupons);

    setInitialCoupon(leastCoupon);

    setLeastPublicCoupon(initialDiscountValue);
    setDiscountPriceDisplay(initialDiscountValue);
    setTotalDiscount(initialDiscountValue);
  }, [props.coupons]);

  //user identity information
  const [userIdentityState, setUserIdentityState] = useState({
    identity: "select",
  });
  //Payment process
  const [token, setToken] = sessionStorage.getItem("token")
    ? useState(sessionStorage.getItem("token"))
    : useState("");
  const [loading, setLoading] = useState(false);
  const payNow = () => {
    setStateErr({});
    // if (userInfoStep === "step1" && currency === "INR") {
    if (userInfoStep === "step1") {
      if (!userState.address || userState.address === "null") {
        setStateErr({ addressErr: "Address should not be empty" });
        return false;
      }
      if (!userState.countryName || userState.countryName === "null") {
        setStateErr({ countryNameErr: "Country name should not be empty" });
        return false;
      }
      // if (!userState.state || userState.state === "null") {
      //   setStateErr({ stateErr: "State should not be empty" });
      //   return false;
      // }
      // if (!userState.city || userState.city === "null") {
      //   setStateErr({ cityErr: "City should not be empty" });
      //   return false;
      // }
      let zipCodeRegx = /^[a-zA-Z0-9]/;
      if (!userState.zipcode || userState.zipcode === "null") {
        setStateErr({ zipCodeErr: "Zipcode should not be empty" });
        return false;
      }
      if (!zipCodeRegx.test(userState.zipcode)) {
        setStateErr({ zipCodeErr: "Zipcode should be a number" });
        return false;
      }

      if (!guestNote && noteMandatory === 1) {
        return false;
      }

      if (personalTab && businessTab) {
        if (!userState.companyName || userState.companyName === "null") {
          setStateErr({ companyNameErr: "Company Name should be valid" });
          return false;
        }
        if (!userState.GSTIN || userState.GSTIN === "null") {
          setStateErr({ GSTINErr: "GSTIN should be valid" });
          return false;
        }
      }

      setLoading(true);
      userSignup(userState, expiry_date, dob, setToken, callBackFromSignup);
    }
    if (userInfoStep == "step4") {
      // if (dob === undefined) {
      //   setStateErr({ dobErr: "Date of birth should be valid" });
      //   return false;
      // }

      setLoading(true);
      userSignup(userState, expiry_date, dob, setToken, callBackFromSignup);
    }
  };

  const [apiKey] = useContext(ApiKeyContext);
  //CallBackFrom user sign up
  const callBackFromSignup = (token) => {
    if (token && token !== "" && props.cart.length > 0) {
      sessionStorage.setItem("token", token);

      let facebook_pixel_data = JSON.parse(
        sessionStorage.getItem("facebook_pixel_data")
      );
      if (facebook_pixel_data && facebook_pixel_data.initiate_checkout) {
        ReactPixel.fbq("track", "InitiateCheckout");
      }

      newBookRequest(
        hotelId,
        dateRange,
        props.cart,
        props.appliedCoupon,
        paidServiceCart,
        apiKey,
        token,
        //Refund Protect
        currency,
        totalProductPrice,
        selectedValueForRefund,
        optedForRefund,
        refundProtectPrice,
        //Refund Protect
        totalAddonPrice,
        totalGstAddonPrice,
        addOnChargesData,
        guestNote,
        arrivalTime,
        checkedStatus,
        refundProtectTaxPrice,
        //prepaid check
        partialPayPercent,
        selectedPaymentOptn,
        hotelData,
        userState
      );
    }
  };
  //Handle Private Coupon
  const [privateCoupon, setPrivateCoupon] = useState("sdflk");

  // useEffect(() => {
  //     if (baseCurrency != "INR") setTotalGst(0);
  // }, [totalGst]);

  //Handle public coupon
  const [leastPublicCoupon, setLeastPublicCoupon] = useState(0);

  //apply public coupon
  const applyPublicCoupon = (coupon, index) => {
    handleCouponApplyAfterCart();

    sessionStorage.setItem("privateCoupon", "");

    manageAllAppliedPublicCoupons[index].status = true;
    setManageAllAppliedPublicCoupons([...manageAllAppliedPublicCoupons]);

    setPublicCouponApplied(true);
    setPrivateCouponApplied(false);

    let totaldiscountprice = 0;
    totaldiscountprice = checkForDatesForPublicCoupon(coupon);

    setDiscountPriceDisplay(totaldiscountprice);
    setTotalDiscount(totaldiscountprice);
  };

  const checkForDatesForPublicCoupon = (coupon) => {
    let from_date = moment(dateRange.startDate).format("YYYY-MM-DD");
    let to_date = moment(dateRange.endDate).format("YYYY-MM-DD");

    let start = moment(dateRange.startDate);
    let end = moment(dateRange.endDate);
    let no_of_days = Math.round(moment.duration(end.diff(start)).days());

    let end_date = moment(to_date)
      .subtract(1, "days")
      .format("YYYY-MM-DD");

    let dateArray = getDateArray(from_date, end_date);

    let coupon_start_date = moment(coupon.valid_from).format("YYYY-MM-DD");

    let coupon_to_date = moment(coupon.valid_to).format("YYYY-MM-DD");
    let coupon_end_date = moment(coupon_to_date)
      .subtract(1, "days")
      .format("YYYY-MM-DD");

    let couponArray = getDateArray(coupon_start_date, coupon_end_date);

    let discountPrice = 0;
    props.cart &&
      props.cart.map((cartItem) => {
        if (coupon.room_type_id === 0) {
          dateArray &&
            dateArray.map((value, index) => {
              if (couponArray && couponArray.length > 0) {
                if (couponArray.includes(value)) {
                  cartItem.rooms.map((rates_for_discount) => {
                    // let total_bar_price = 1;
                    // total_bar_price = rates_for_discount.bar_price / no_of_days
                    // discountPrice +=(total_bar_price / 100) * coupon.discount;

                    let total_bar_price = 1;
                    rates_for_discount.day_wise_rates &&
                      rates_for_discount.day_wise_rates.map((day_rate) => {
                        if (value === day_rate.date) {
                          // changes for fixing price calculation for single,multiple occupancy

                          if (
                            rates_for_discount.selected_adult <
                            cartItem.max_people
                          ) {
                            if (
                              day_rate.multiple_occupancy[
                              rates_for_discount.selected_adult - 1
                              ]
                            ) {
                              total_bar_price = parseFloat(
                                day_rate.multiple_occupancy[
                                rates_for_discount.selected_adult - 1
                                ]
                              );
                            } else {
                              total_bar_price = day_rate.bar_price;
                            }
                          } else {
                            total_bar_price = day_rate.bar_price;
                          }

                          // changes for fixing price calculation for single,multiple occupancy

                          // total_bar_price = day_rate.bar_price
                          discountPrice +=
                            (total_bar_price / 100) * coupon.discount;
                        }
                      });
                  });
                }
              }
            });
        } else {
          if (coupon.room_type_id === cartItem.room_type_id) {
            dateArray &&
              dateArray.map((value, index) => {
                if (couponArray && couponArray.length > 0) {
                  if (couponArray.includes(value)) {
                    cartItem.rooms.map((rates_for_discount) => {
                      // let total_bar_price = 1;
                      // total_bar_price = rates_for_discount.bar_price / no_of_days
                      // discountPrice +=(total_bar_price / 100) * coupon.discount;

                      let total_bar_price = 1;
                      rates_for_discount.day_wise_rates &&
                        rates_for_discount.day_wise_rates.map((day_rate) => {
                          if (value === day_rate.date) {
                            // changes for fixing price calculation for single,multiple occupancy
                            if (
                              rates_for_discount.selected_adult <
                              cartItem.max_people
                            ) {
                              if (
                                day_rate.multiple_occupancy[
                                rates_for_discount.selected_adult - 1
                                ]
                              ) {
                                total_bar_price = parseFloat(
                                  day_rate.multiple_occupancy[
                                  rates_for_discount.selected_adult - 1
                                  ]
                                );
                              } else {
                                total_bar_price = day_rate.bar_price;
                              }
                            } else {
                              total_bar_price = day_rate.bar_price;
                            }
                            // changes for fixing price calculation for single,multiple occupancy

                            // total_bar_price = day_rate.bar_price
                            discountPrice +=
                              (total_bar_price / 100) * coupon.discount;
                          }
                        });
                    });
                  }
                }
              });
          } else {
            discountPrice += cartItem.discounted_price;
          }
        }
      });
    return discountPrice;
  };

  //handle Input
  const handlePrivateCoupon = (value) => {
    setPrivateCoupon(value);
    // console.log('private' + value);
  };
  //Apply coupon if valid

  const applyPrivateCupon = () => {
    handleCouponApplyAfterCart();
    if (privateCoupon !== "") {
      let all_selected_room_type_id = [];
      //MyCode
      props.cart &&
        props.cart.map((items) => {
          all_selected_room_type_id.push(items.room_type_id);
        });
      //MyCode

      validateCupon(
        privateCoupon,
        hotelId,
        dateRange,
        setIsModalOpen,
        setPrivateCuponErr,
        setValidCoupon,

        //Mycode
        all_selected_room_type_id,
        setAppliedPublicCoupon,
        setPublicCouponApplied,
        setPrivateCouponApplied,
        manageAllAppliedPublicCoupons,
        setManageAllAppliedPublicCoupons
      );
    }
  };

  useEffect(() => {
    if (props.cart.length > 0) {
      let allAppliedCoupons = [];
      props.cart.map((cartItem) => {
        cartItem.appliedCoupons &&
          cartItem.appliedCoupons.map((ac) => {
            allAppliedCoupons.push(ac);
          });
      });
      let uniqueArr = [];
      if (allAppliedCoupons.length > 0) {
        uniqueArr = [
          ...new Set(allAppliedCoupons.map((ac) => ac.coupon_id)),
        ].map((aac) => {
          return allAppliedCoupons.find((a) => a.coupon_id === aac);
        });
      }

      let all_p_coupon = [];
      uniqueArr.map((item) => {
        all_p_coupon.push({ status: true, room_type_id: item.room_type_id });
      });
      setManageAllAppliedPublicCoupons(all_p_coupon);

      setAllAppliedPublicCoupons(uniqueArr);
    }

    setPublicCouponApplied(true);
    setPrivateCouponApplied(false);
    sessionStorage.setItem("privateCoupon", "");
  }, [props.cart]);

  // useEffect(() => {
  //   console.log('Cart: ', props.cart);
  // }, [props.cart]);

  useEffect(() => {
    setValidCoupon([]);
    setPrivateCouponApplied(false);
    setPublicCouponApplied(true);
  }, [
    dateRange,
    // , isCartEmpty
  ]);

  //Call Back to rooms component to apply coupon
  useEffect(() => {
    let totaldiscountprice = 0;
    props.callbackCupon(validCoupon);
    if (validCoupon[0]) {
      totaldiscountprice = checkForDates(validCoupon);
      setDiscountPriceDisplay(totaldiscountprice);
      setTotalDiscount(totaldiscountprice);
      setPrivateCoupon("");
      //MyCode
    }
  }, [validCoupon]);

  //My Code
  const checkForDates = (coupon) => {
    let from_date = moment(dateRange.startDate).format("YYYY-MM-DD");
    let to_date = moment(dateRange.endDate).format("YYYY-MM-DD");

    let start = moment(dateRange.startDate);
    let end = moment(dateRange.endDate);
    let no_of_days = Math.round(moment.duration(end.diff(start)).days());

    let end_date = moment(to_date)
      .subtract(1, "days")
      .format("YYYY-MM-DD");

    let dateArray = getDateArray(from_date, end_date);

    let coupon_start_date = moment(coupon[0].valid_from).format("YYYY-MM-DD");

    let coupon_to_date = moment(coupon[0].valid_to).format("YYYY-MM-DD");
    let coupon_end_date = moment(coupon_to_date)
      .subtract(1, "days")
      .format("YYYY-MM-DD");

    // let couponArray = getDateArray(coupon_start_date, coupon_end_date);

    let couponArray = [];

    coupon.map((item) => {
      couponArray.push(item.date);
    });

    let discountPrice = 0;
    let cartWithDiscounts = [];
    props.cart &&
      props.cart.map((cartItem) => {
        let private_discount_price = 0;
        if (coupon[0].room_type_id === 0) {
          dateArray &&
            dateArray.map((value, index) => {
              if (couponArray && couponArray.length > 0) {
                if (couponArray.includes(value)) {
                  //Codes by Hafiz solving privateCouponCaluculation
                  cartItem.rooms.map((rates_for_discount) => {
                    // let total_bar_price = 1;
                    // total_bar_price = rates_for_discount.bar_price / no_of_days
                    // discountPrice +=(total_bar_price / 100) * coupon[0].discount;
                    // private_discount_price += (total_bar_price / 100) * coupon[0].discount;

                    let total_bar_price = 1;
                    rates_for_discount.day_wise_rates &&
                      rates_for_discount.day_wise_rates.map((day_rate) => {
                        if (value === day_rate.date) {
                          // changes for fixing price calculation for single,multiple occupancy

                          if (
                            rates_for_discount.selected_adult <
                            cartItem.max_people
                          ) {
                            if (
                              day_rate.multiple_occupancy[
                              rates_for_discount.selected_adult - 1
                              ]
                            ) {
                              total_bar_price = parseFloat(
                                day_rate.multiple_occupancy[
                                rates_for_discount.selected_adult - 1
                                ]
                              );
                            } else {
                              total_bar_price = day_rate.bar_price;
                            }
                          } else {
                            total_bar_price = day_rate.bar_price;
                          }

                          // changes for fixing price calculation for single,multiple occupancy

                          // total_bar_price = day_rate.bar_price
                          discountPrice +=
                            (total_bar_price / 100) * coupon[0].discount;
                          private_discount_price +=
                            (total_bar_price / 100) * coupon[0].discount;
                        }
                      });

                    //Minhaj's code
                    cartWithDiscounts.push({
                      cartItem,
                      discount: coupon[0].discount,
                    });
                    //Minhaj's code
                  });
                  //Codes by Hafiz solving privateCouponCaluculation
                }
              }
            });
        } else {
          if (coupon[0].room_type_id === cartItem.room_type_id) {
            dateArray &&
              dateArray.map((value, index) => {
                if (couponArray && couponArray.length > 0) {
                  if (couponArray.includes(value)) {
                    //Codes by Hafiz solving privateCouponCaluculation
                    cartItem.rooms.map((rates_for_discount) => {
                      // let total_bar_price = 1;
                      // total_bar_price = rates_for_discount.bar_price / no_of_days
                      // discountPrice +=(total_bar_price / 100) * coupon[0].discount;
                      // private_discount_price += (total_bar_price / 100) * coupon[0].discount;

                      let total_bar_price = 1;
                      rates_for_discount.day_wise_rates &&
                        rates_for_discount.day_wise_rates.map((day_rate) => {
                          if (value === day_rate.date) {
                            // changes for fixing price calculation for single,multiple occupancy

                            if (
                              rates_for_discount.selected_adult <
                              cartItem.max_people
                            ) {
                              if (
                                day_rate.multiple_occupancy[
                                rates_for_discount.selected_adult - 1
                                ]
                              ) {
                                total_bar_price = parseFloat(
                                  day_rate.multiple_occupancy[
                                  rates_for_discount.selected_adult - 1
                                  ]
                                );
                              } else {
                                total_bar_price = day_rate.bar_price;
                              }
                            } else {
                              total_bar_price = day_rate.bar_price;
                            }

                            // changes for fixing price calculation for single,multiple occupancy

                            // total_bar_price = day_rate.bar_price
                            discountPrice +=
                              (total_bar_price / 100) * coupon[0].discount;
                            private_discount_price +=
                              (total_bar_price / 100) * coupon[0].discount;
                          }
                        });

                      //Minhaj's code
                      cartWithDiscounts.push({
                        cartItem,
                        discount: coupon[0].discount,
                      });
                      //Minhaj's code
                    });
                    //Codes by Hafiz solving privateCouponCaluculation
                  }
                }
              });
          } else {
            discountPrice += cartItem.discounted_price;

            private_discount_price += cartItem.discounted_price;

            //Minhaj's code
            cartWithDiscounts.push({
              cartItem,
              discount: coupon[0].discount,
            });
            //Minhaj's code
          }
        }
        cartItem.private_discount_price = private_discount_price;
      });
    // console.log('Discounts: ', cartWithDiscounts);
    return discountPrice;
  };

  const getDateArray = (startDate, stopDate) => {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
      currentDate = moment(currentDate).add(1, "days");
    }
    return dateArray;
  };

  //My Code

  const guestTab = {
    paddingLeft: "0px",
    paddingRight: "0px",
    marginTop: "4%",
  };

  const guestInput = {
    paddingLeft: "0px",
    paddingRight: "0px",
  };

  const paddingLeft = {
    paddingLeft: "0px",
  };

  const paddingRight = {
    paddingRight: "0px",
  };

  const paymentOptionToaster = () => {
    toast("Please Select a Payment Option to Continue!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      zIndex: 5
    });
  };

  const [stickyDisable, setStickyDisable] = useState(false);

  const goTobottomCart = () => {
    setStickyDisable(true);
    const bottomRefPosition = bottomRef.current.offsetTop;
    const bottomRefHeight = bottomRef.current.scrollHeight;

    const scrollToPosition = bottomRefPosition + bottomRefHeight;

    window.scrollTo({
      top: scrollToPosition,
      behavior: 'smooth',
    });
  }


  return (
    <>
      <div
        className={`room-box-right ${props.cart.length === 0 &&
          "covid-slider-zIndex"}`} style={stickyDisable ? { top: '0px', position: 'relative' } : {}}>
        <div className="room-box-right-scroll">
          <span>
            <Element id="addroom" />
          </span>
          <div className="row">
            <Snackbar
              message={`Private Coupon Applied!`}
              setPopupOpen={setPopupOpen}
            />
            <div
              className="booking-summary-box mobile-margin-top"
              style={{ padding: props.cart.length === 0 && "0px" }}
            >
              {props.cart && props.cart.length === 0 && (
                <div className="card" style={{ margin: "0px" }}>
                  {sliderImages && sliderImages.length > 0 ? (
                    <div className="imgSlider">
                      <ImageSlider images={sliderImages} customImages />
                    </div>
                  ) : (
                    <div className="imgSlider">
                      <ImageSlider
                        images={[covid1, covid2, covid3]}
                        customImages
                      />
                    </div>
                  )}
                </div>
              )}

              {props.cart && props.cart.length > 0 && (
                <div className="room-booking-summary">
                  <div className="room-select cart__next__btn">
                    <a className="waves-effect waves-light btn-small select-btn" onClick={goTobottomCart} >Next</a>
                  </div>
                  <br />
                  <div id="day-list" className="row bottom-space">
                    <div className="day-list">
                      <div className="row">
                        <div className="col s12 m4 check-in">{startDate}</div>

                        <div className="col s12 m4">
                          <span className="num-day-show flow-text">
                            {no_nights}
                            <br />
                            Nights
                          </span>
                        </div>

                        <div className="col s12 m4 check-out">{endDate}</div>
                      </div>
                    </div>
                  </div>
                  {props.cart &&
                    props.cart.map((cartItem, index) => (
                      <div key={index} className="container booking-summary">
                        <div id="room-summary-box1" className="row">
                          <div className="room-summary-box">
                            <span
                              className="roomBtnclose"
                              onClick={() => removeRoom(cartItem)}
                            />
                            <p>
                              {cartItem.room_type}({cartItem.plan_type})
                            </p>
                            <p>
                              <span>{cartItem.bookable_name}: {cartItem.rooms.length}, </span>
                              <span>
                                Adults:{" "}
                                {cartItem.rooms &&
                                  cartItem.rooms.reduce((init, room) => {
                                    return init + room.selected_adult;
                                  }, 0)}
                                ,{" "}
                              </span>
                              <span>
                                Child:{" "}
                                {cartItem.rooms &&
                                  cartItem.rooms.reduce((init, room) => {
                                    return init + room.selected_child;
                                  }, 0)}
                                ,
                              </span>
                              <span>
                                kids:{" "}
                                {cartItem.rooms &&
                                  cartItem.rooms.reduce((init, room) => {
                                    return init + room.selected_infant;
                                  }, 0)}
                              </span>
                            </p>
                            <p>
                              {cartItem.bookable_name} Price: <i className={currencySymbol} />{" "}
                              {parseFloat(
                                (cartItem.display_price * currencyValue).toFixed(
                                  2
                                )
                              ).toLocaleString("en-IN")}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}

                  {/* refund protect codes */}
                  {optedForRefund &&
                    optedForRefund.status === 1 &&
                    check_in_date !== today_date &&
                    checkedStatus === false &&
                    selectedPaymentOptn !== "payathotel" && (
                      <div className="refund-details-checkbox">
                        <a
                          className={
                            selectedValueForRefund === 1
                              ? "waves-effect waves-light btn-small text-left-align pay-btn active-refund-details-btn"
                              : "waves-effect waves-light btn-small text-left-align pay-btn not-active-refund-details-btn"
                          }
                        >
                          <label>
                            <input
                              className="with-gap"
                              name="group1"
                              type="radio"
                              value="true"
                              checked={selectedValueForRefund === 1 && true}
                              onChange={() => setSelectedValueForRefund(1)}
                            />
                            <span
                              style={
                                selectedValueForRefund === 1
                                  ? { color: "#ffffff" }
                                  : { color: "#555555" }
                              }
                            >
                              <i className="shield-icon" />
                              <span className="refundable-text">
                                Refundable Booking
                              </span>
                            </span>
                          </label>
                        </a>

                        {optedForRefund.mode === "optional" && (
                          <div className="center-align recomended-text">
                            Recommended
                          </div>
                        )}
                        {optedForRefund.mode !== "optional" && (
                          <div style={{ width: "100%", height: "20px" }} />
                        )}

                        <div className="refunded-text-size">
                          <i className="fa fa-check" aria-hidden="true" />{" "}
                          <b>Covid-19</b> infection, isolation and family illness,{" "}
                          <a
                            href=" https://refundable.me/covid"
                            className="link-terms-condition"
                            target="_blank"
                          >
                            see details
                          </a>
                          .
                        </div>
                        <div className="refunded-text-size">
                          <i className="fa fa-check" aria-hidden="true" /> Public
                          vehicle failure
                        </div>
                        <div className="refunded-text-size">
                          <i className="fa fa-check" aria-hidden="true" />{" "}
                          Emergency services recall
                        </div>
                        <div className="refunded-text-size">
                          <i className="fa fa-check" aria-hidden="true" /> Home
                          emergency
                        </div>
                        <div className="refunded-text-size">
                          <i className="fa fa-check" aria-hidden="true" />{" "}
                          Immediate relative sickness
                        </div>
                        <div className="refunded-text-size">
                          <i className="fa fa-check" aria-hidden="true" />{" "}
                          Pre-existing medical condition
                        </div>

                        <div className="refund-terms-and-conditions">
                          Upgrade your booking and receive a 100% refund if you
                          cannot attend and can evidence one of the many reasons
                          in our{" "}
                          <a
                            href="https://www.refundable.me/extended/en/"
                            className="link-terms-condition"
                            target="_blank"
                          >
                            Terms and Conditions
                          </a>
                          , which you accept when you select a Refundable Booking.
                        </div>

                        {selectedValueForRefund === 1 && (
                          <>
                            <div className="refundable-booking-amount-text">
                              Refundable Booking Charges:
                            </div>

                            <div className="refundable-booking-amount-price">
                              <i className={currencySymbol} />{" "}
                              {currency == baseCurrency
                                ? parseFloat(
                                  parseFloat(
                                    refundProtectPrice + refundProtectTaxPrice
                                  ).toFixed(2)
                                ).toLocaleString("en-IN")
                                : (
                                  parseFloat(
                                    refundProtectPrice + refundProtectTaxPrice
                                  ).toFixed(2) * currencyValue
                                ).toFixed(2)}
                            </div>
                          </>
                        )}

                        {optedForRefund.mode === "optional" && (
                          <div className="non-refundable-section">
                            <a
                              className={
                                selectedValueForRefund === 0
                                  ? "waves-effect active-refund-details-btn waves-light btn-small text-left-align pay-btn"
                                  : "waves-effect not-active-refund-details-btn waves-light btn-small text-left-align pay-btn"
                              }
                            >
                              <label>
                                <input
                                  className="with-gap"
                                  name="group1"
                                  type="radio"
                                  value="false"
                                  checked={selectedValueForRefund === 0 && true}
                                  onChange={() => setSelectedValueForRefund(0)}
                                />
                                <span
                                  style={
                                    selectedValueForRefund === 0
                                      ? { color: "#ffffff" }
                                      : { color: "#555555" }
                                  }
                                >
                                  <i className="non-shield-icon" />
                                  <span className="non-refundable-text">
                                    Non-refundable Booking
                                  </span>
                                </span>
                              </label>
                            </a>
                          </div>
                        )}
                      </div>
                    )}
                  {/* refund protect codes */}

                  <div className="room-services">
                    <span onClick={() => setIsExpanded(!isExpanded)}>
                      {isExpanded ? "Hide Breakup" : "View Breakup"}{" "}
                    </span>
                    <br />
                  </div>

                  {isExpanded && (
                    <React.Fragment>
                      <div className="container booking-summary">
                        <div id="room-price-summary" className="row">
                          <div className="room-price-summary">
                            <ul>
                              <li>Total Price:</li>
                              <li>
                                <i className={currencySymbol} />{" "}
                                {currency == baseCurrency
                                  ? parseFloat(
                                    TotalPriceDisplay.toFixed(2)
                                  ).toLocaleString("en-IN")
                                  : (
                                    TotalPriceDisplay.toFixed(2) * currencyValue
                                  ).toFixed(2)}
                              </li>
                            </ul>

                            {discountPriceDisplay > 0 && (
                              <ul className="discount">
                                <li>Discount:</li>
                                <li>
                                  - <i className={currencySymbol} />{" "}
                                  {currency == baseCurrency
                                    ? parseFloat(
                                      parseFloat(discountPriceDisplay).toFixed(
                                        2
                                      )
                                    ).toLocaleString("en-IN")
                                    : (
                                      parseFloat(discountPriceDisplay).toFixed(
                                        2
                                      ) * currencyValue
                                    ).toFixed(2)}
                                  {/* {parseFloat(discountPriceDisplay).toFixed(2)} */}
                                </li>
                              </ul>
                            )}
                            {totalGst > 0 &&
                              gstTotalPriceDisplay > 0 &&
                              isTaxable === 1 && (
                                <ul>
                                  <li>Tax:</li>
                                  <li>
                                    <i className={currencySymbol} />{" "}
                                    {currency == baseCurrency
                                      ? parseFloat(
                                        parseFloat(
                                          gstTotalPriceDisplay
                                        ).toFixed(2)
                                      ).toLocaleString("en-IN")
                                      : (
                                        parseFloat(
                                          gstTotalPriceDisplay
                                        ).toFixed(2) * currencyValue
                                      ).toFixed(2)}
                                  </li>
                                </ul>
                              )}
                            {/* {otherTaxArray.length > 0 &&
                          otherTaxArray.map((otherTax, index) => (
                            <ul key={index}>
                              <li>{otherTax.tax_name}:</li>
                              <li>
                                <i className={currencySymbol} />{" "}

                                {currency == baseCurrency ? (otherTax.display_tax_amount).toLocaleString('en-IN') : (otherTax.display_tax_amount * currencyValue).toFixed(2)}


                              </li>
                            </ul>
                          ))} */}

                            {addOnChargesData.length > 0 && (
                              <ul>
                                <li>
                                  {addOnChargesData[0].add_on_charges_name}:
                                </li>
                                <li>
                                  <i className={currencySymbol} />{" "}
                                  {currency == baseCurrency
                                    ? parseFloat(
                                      parseFloat(
                                        totalAddonPrice + totalGstAddonPrice
                                      ).toFixed(2)
                                    ).toLocaleString("en-IN")
                                    : (
                                      parseFloat(
                                        totalAddonPrice + totalGstAddonPrice
                                      ).toFixed(2) * currencyValue
                                    ).toFixed(2)}
                                </li>
                              </ul>
                            )}

                            {/* Refund Protect */}
                            {optedForRefund &&
                              optedForRefund.status === 1 &&
                              selectedValueForRefund === 1 &&
                              check_in_date !== today_date &&
                              checkedStatus === false && (
                                <ul>
                                  <li>Refundable Booking Charges:</li>
                                  <li>
                                    <i className={currencySymbol} />{" "}
                                    {currency == baseCurrency
                                      ? parseFloat(
                                        parseFloat(
                                          refundProtectPrice +
                                          refundProtectTaxPrice
                                        ).toFixed(2)
                                      ).toLocaleString("en-IN")
                                      : (
                                        parseFloat(
                                          refundProtectPrice +
                                          refundProtectTaxPrice
                                        ).toFixed(2) * currencyValue
                                      ).toFixed(2)}
                                  </li>
                                  <li className="clearfix" />
                                </ul>
                              )}
                            {/* Refund Protect */}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="room-services">
                          <p>Have a coupon code ?</p>
                          <span
                            className="modal-trigger"
                            onClick={() => toggleModal()}
                            data-target="modal1"
                          >
                            Click here
                          </span>
                        </div>
                      </div>

                      {/* toast container */}
                      <ToastContainer icon={false} />
                      <hr />

                      <div className="row">
                        <div className="room-services">
                          <p className="modal-trigger" data-target="modal12">
                            {" "}
                            <span onClick={() => addonServicetoggleModal()}>
                              Click here
                            </span>{" "}
                            for addon services
                          </p>
                        </div>
                      </div>

                      {/* Addon Services */}
                      {paidServiceCart &&
                        paidServiceCart.length > 0 &&
                        paidServiceCart.map((paidServiceIncart, index) => (
                          <div key={index} className="container booking-summary">
                            <div id="addon-room-services" className="row">
                              <div className="addon-room-services">
                                <span
                                  onClick={() => removeService(paidServiceIncart)}
                                  className="btnCloseRoom"
                                />
                                <p>
                                  <span className="row">
                                    {/* <b> */}
                                    {paidServiceIncart.qty}&nbsp; x &nbsp;
                                    {paidServiceIncart.service_name}
                                    {/* &nbsp; - &nbsp; */}
                                    {/* </b> */}
                                  </span>
                                  <span className="row">
                                    <div className="addon-room-services-content">
                                      <span>Price&nbsp; : &nbsp;</span>
                                      <span>
                                        <i className={currencySymbol} />{" "}
                                        {currency == baseCurrency
                                          ? (
                                            paidServiceIncart.price *
                                            paidServiceIncart.qty
                                          ).toLocaleString("en-IN")
                                          : (
                                            paidServiceIncart.price *
                                            paidServiceIncart.qty *
                                            currencyValue
                                          ).toFixed(2)}
                                      </span>
                                    </div>
                                  </span>
                                </p>

                                {isTaxable == 1 &&
                                  paidServiceIncart.service_tax !== 0 && (
                                    <p>
                                      <div className="addon-room-services-content">
                                        <span>GST&nbsp; : &nbsp;</span>
                                        <span>
                                          <i className={currencySymbol} />{" "}
                                          {currency == baseCurrency
                                            ? (
                                              ((paidServiceIncart.price *
                                                paidServiceIncart.service_tax) /
                                                100) *
                                              paidServiceIncart.qty
                                            ).toLocaleString("en-IN")
                                            : (
                                              ((paidServiceIncart.price *
                                                paidServiceIncart.service_tax) /
                                                100) *
                                              paidServiceIncart.qty *
                                              currencyValue
                                            ).toFixed(2)}
                                        </span>
                                      </div>
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                        ))}
                      {/*End Addon Services */}
                      <div id="total-room-pay" className="row">
                        <div className="total-room-pay">
                          <ul>
                            <li>Total</li>
                            <li>
                              <i className={currencySymbol} />
                              {parseFloat(
                                parseFloat(allTotalPriceDisplay).toFixed(2)
                              ).toLocaleString("en-IN")}
                            </li>
                          </ul>
                        </div>
                      </div>

                      {/* <div className="col">
                    <div className="row">
                      <label for={"payment-opt-text"}>
                        {(optedForRefund && optedForRefund.status === 1 && check_in_date !== today_date && selectedValueForRefund === 1) ?
                          (
                            <input
                              value={"prepaid"}
                              checked={selectedPaymentOptn === "prepaid"}
                              onChange={(e) => handlePartialPay(e)}
                              type="checkbox"
                              className="filled-in disable-partial-checkbox"
                              disabled={true}
                            />
                          )
                          :
                          (
                            <input
                              value={"prepaid"}
                              checked={selectedPaymentOptn === "prepaid"}
                              onChange={(e) => handlePartialPay(e)}
                              type="checkbox"
                              className="filled-in"
                            />
                          )
                        }
                        <span>{`\u00A0`}</span>
                      </label>
                      <div className="col payment-opt-text"><p>Prepaid</p></div>
                    </div>
                    <div className="row">
                      <label>
                        {(optedForRefund && optedForRefund.status === 1 && check_in_date !== today_date && selectedValueForRefund === 1) ?
                          (
                            <input
                              value={partialPayPercent}
                              // checked={checkedStatus}
                              checked={parseFloat(partialPayPercent) === parseFloat(selectedPaymentOptn)}
                              onChange={(e) => handlePartialPay(e)}
                              type="checkbox"
                              className="filled-in disable-partial-checkbox"
                              disabled={true}
                            />
                          )
                          :
                          (
                            <input
                              value={partialPayPercent}
                              // checked={checkedStatus}
                              checked={parseFloat(partialPayPercent) === parseFloat(selectedPaymentOptn)}
                              onChange={(e) => handlePartialPay(e)}
                              type="checkbox"
                              className="filled-in"
                            />
                          )
                        }
                        <span>{`\u00A0`}</span>
                      </label>
                      <div className="col payment-opt-text"><p>Partial (45%)</p></div>
                    </div>
                    <div className="row">
                      <label>
                        {(optedForRefund && optedForRefund.status === 1 && check_in_date !== today_date && selectedValueForRefund === 1) ?
                          (
                            <input
                              value={"payathotel"}
                              checked={selectedPaymentOptn === "payathotel"}
                              onChange={(e) => handlePartialPay(e)}
                              type="checkbox"
                              className="filled-in disable-partial-checkbox"
                              disabled={true}
                            />
                          )
                          :
                          (
                            <input
                              value={"payathotel"}
                              checked={selectedPaymentOptn === "payathotel"}
                              onChange={(e) => handlePartialPay(e)}
                              type="checkbox"
                              className="filled-in"
                            />
                          )
                        }
                        <span>{`\u00A0`}</span>
                      </label>
                      <div className="col payment-opt-text"><p>At Hotel</p></div>
                    </div>
                  </div> */}
                      {/* <div className="row">
                    <div className="col"><input type="checkbox" /></div>
                    <div className="col payment-opt-text">Prepaid</div>
                    <div className="col"><input type="checkbox" /></div>
                    <div className="col payment-opt-text">Partial (15%)</div>
                    <div className="col"><input type="checkbox" /></div>
                    <div className="col payment-opt-text">At Hotel</div>
                  </div> */}

                      {/* <div className="row">Payment Option</div> */}
                      <div className="room-services">
                        <span>
                          {isExpanded ? "Payment Option" : "Payment Option"}{" "}
                        </span>
                        <br />
                      </div>

                      {/* <form action="#">
                    <div className={(!partialPayStatus && !payatHotelStatus) ? "single-payment-option-wrapper" : "payment-option-wrapper"}>
                      <div className="prepaid-wrapper">
                        <label>
                          {(optedForRefund && optedForRefund.status === 1 && check_in_date !== today_date && selectedValueForRefund === 1) ?
                            (
                              <input
                                value={"prepaid"}
                                checked={selectedPaymentOptn === "prepaid"}
                                onChange={(e) => handlePartialPay(e)}
                                className="with-gap"
                                name="prepaid"
                                type="radio"
                                disabled={true}
                              />
                            ) : (
                              <input
                                value={"prepaid"}
                                checked={selectedPaymentOptn === "prepaid"}
                                onChange={(e) => handlePartialPay(e)}
                                className="with-gap"
                                name="prepaid"
                                type="radio"
                              />
                            )
                          }
                          <span>Prepaid</span>
                        </label>
                      </div>

                      {partialPayStatus &&
                        <div className="partial-wrapper">
                          <label>
                            {(optedForRefund && optedForRefund.status === 1 && check_in_date !== today_date && selectedValueForRefund === 1) ?
                              (
                                <input
                                  value={partialPayPercent}
                                  checked={parseFloat(partialPayPercent) === parseFloat(selectedPaymentOptn)}
                                  onChange={(e) => handlePartialPay(e)}
                                  className="with-gap"
                                  name="partial"
                                  type="radio"
                                  disabled={true}
                                />
                              ) : (
                                <input
                                  value={partialPayPercent}
                                  checked={parseFloat(partialPayPercent) === parseFloat(selectedPaymentOptn)}
                                  onChange={(e) => handlePartialPay(e)}
                                  className="with-gap"
                                  name="partial"
                                  type="radio"
                                />
                              )
                            }
                            <span>Partial</span>
                          </label>
                        </div>
                      }

                      {payatHotelStatus &&
                        <div className="at-hotel-wrapper">
                          <label>
                            {(optedForRefund && optedForRefund.status === 1 && check_in_date !== today_date && selectedValueForRefund === 1) ?
                              (
                                <input
                                  value={"payathotel"}
                                  checked={selectedPaymentOptn === "payathotel"}
                                  onChange={(e) => handlePartialPay(e)}
                                  className="with-gap"
                                  name="athotel"
                                  type="radio"
                                  disabled={true}
                                />
                              ) :
                              (
                                <input
                                  value={"payathotel"}
                                  checked={selectedPaymentOptn === "payathotel"}
                                  onChange={(e) => handlePartialPay(e)}
                                  className="with-gap"
                                  name="athotel"
                                  type="radio"
                                />
                              )
                            }
                            <span>At Hotel</span>
                          </label>
                        </div>
                      }
                    </div>
                  </form> */}

                      {hotelData.prepaid === 1 && (
                        <>
                          <div id="partial-pay" className="row">
                            <div className="partial-pay">
                              <ul>
                                <li>
                                  <label>
                                    {optedForRefund &&
                                      optedForRefund.status === 1 &&
                                      check_in_date !== today_date &&
                                      selectedValueForRefund === 1 ? (
                                      <input
                                        value={"prepaid"}
                                        checked={
                                          selectedPaymentOptn === "prepaid"
                                        }
                                        onChange={(e) => handlePartialPay(e)}
                                        type="checkbox"
                                        className="filled-in"
                                      // className="filled-in disable-partial-checkbox"
                                      // disabled={true}
                                      />
                                    ) : (
                                      <input
                                        value={"prepaid"}
                                        checked={
                                          selectedPaymentOptn === "prepaid"
                                        }
                                        onChange={(e) => handlePartialPay(e)}
                                        type="checkbox"
                                        className="filled-in"
                                      />
                                    )}

                                    <span>{`\u00A0`}</span>
                                  </label>
                                </li>

                                <li>Pay Total</li>
                              </ul>
                            </div>
                          </div>
                        </>
                      )}

                      {hotelData.partial_payment === 1 && (
                        <>
                          {partialPayStatus && (
                            <div id="partial-pay" className="row">
                              <div className="partial-pay">
                                <ul>
                                  <li>
                                    <label>
                                      {optedForRefund &&
                                        optedForRefund.status === 1 &&
                                        check_in_date !== today_date &&
                                        selectedValueForRefund === 1 ? (
                                        <input
                                          value={partialPayPercent}
                                          // checked={checkedStatus}
                                          checked={
                                            // parseFloat(partialPayPercent) ===
                                            // parseFloat(selectedPaymentOptn)
                                            selectedPaymentOptn === "partialpayment"
                                          }
                                          onChange={(e) => handlePartialPay(e)}
                                          type="checkbox"
                                          className="filled-in"
                                        // className="filled-in disable-partial-checkbox"
                                        // disabled={true}
                                        />
                                      ) : (
                                        <input
                                          value={partialPayPercent}
                                          // checked={checkedStatus}
                                          checked={
                                            // parseFloat(partialPayPercent) ===
                                            // parseFloat(selectedPaymentOptn)
                                            selectedPaymentOptn === "partialpayment"
                                          }
                                          onChange={(e) => handlePartialPay(e)}
                                          type="checkbox"
                                          className="filled-in"
                                        />
                                      )}

                                      <span>{`\u00A0`}</span>
                                    </label>
                                    <span className="me-3">{`${partialPayPercent}% \u00A0\u00A0`}</span>
                                  </li>
                                  <li>Pay Partial</li>
                                </ul>
                              </div>
                            </div>
                          )}
                        </>
                      )}

                      {hotelData.pay_at_hotel === 1 && (
                        <>
                          {payatHotelStatus && (
                            <div id="partial-pay" className="row">
                              <div className="partial-pay">
                                <ul>
                                  <li>
                                    <label>
                                      {optedForRefund &&
                                        optedForRefund.status === 1 &&
                                        check_in_date !== today_date &&
                                        selectedValueForRefund === 1 ? (
                                        <input
                                          value={"payathotel"}
                                          checked={
                                            selectedPaymentOptn === "payathotel"
                                          }
                                          onChange={(e) => handlePartialPay(e)}
                                          type="checkbox"
                                          className="filled-in"
                                        // className="filled-in disable-partial-checkbox"
                                        // disabled={true}
                                        />
                                      ) : (
                                        <input
                                          value={"payathotel"}
                                          checked={
                                            selectedPaymentOptn === "payathotel"
                                          }
                                          onChange={(e) => handlePartialPay(e)}
                                          type="checkbox"
                                          className="filled-in"
                                        />
                                      )}

                                      <span>{`\u00A0`}</span>
                                    </label>
                                  </li>

                                  <li>Pay at hotel</li>
                                </ul>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </React.Fragment>
                  )}

                  {/* refund protect codes */}
                  {/* {optedForRefund && optedForRefund.status === 1 && check_in_date !== today_date && checkedStatus === false && selectedPaymentOptn !== "payathotel" &&
                <div className="refund-details-checkbox">
                  <a className={selectedValueForRefund === 1 ? "waves-effect waves-light btn-small text-left-align pay-btn active-refund-details-btn" : "waves-effect waves-light btn-small text-left-align pay-btn not-active-refund-details-btn"}>
                    <label>
                      <input className="with-gap" name="group1" type="radio" value="true" checked={selectedValueForRefund === 1 && true} onChange={() => setSelectedValueForRefund(1)} />
                      <span style={selectedValueForRefund === 1 ? { color: '#ffffff' } : { color: '#555555' }}><i className="shield-icon"></i><span className="refundable-text">Refundable Booking</span></span>
                    </label>
                  </a>

                  {optedForRefund.mode === "optional" && <div className="center-align recomended-text">Recommended</div>}
                  {optedForRefund.mode !== "optional" && <div style={{ width: '100%', height: '20px' }}></div>}

                  <div className="refunded-text-size"><i className="fa fa-check" aria-hidden="true"></i> <b>Covid-19</b> infection, isolation and family illness, <a href=" https://refundable.me/covid" className="link-terms-condition" target="_blank">see details</a>.</div>
                  <div className="refunded-text-size"><i className="fa fa-check" aria-hidden="true"></i> Public vehicle failure</div>
                  <div className="refunded-text-size"><i className="fa fa-check" aria-hidden="true"></i> Emergency services recall</div>
                  <div className="refunded-text-size"><i className="fa fa-check" aria-hidden="true"></i> Home emergency</div>
                  <div className="refunded-text-size"><i className="fa fa-check" aria-hidden="true"></i> Immediate relative sickness</div>
                  <div className="refunded-text-size"><i className="fa fa-check" aria-hidden="true"></i> Pre-existing medical condition</div>

                  <div className="refund-terms-and-conditions">
                    Upgrade your booking and receive a 100% refund if you cannot attend and can evidence one of the many reasons in our <a href="https://www.refundable.me/extended/en/" className="link-terms-condition" target="_blank">Terms and Conditions</a>, which you accept when you select a Refundable Booking.
                  </div>

                  {selectedValueForRefund === 1 &&
                    <>
                      <div className="refundable-booking-amount-text">Refundable Booking Charges:</div>

                      <div className="refundable-booking-amount-price">
                        <i className={currencySymbol} />{" "}
                        {
                          currency == baseCurrency ? (
                            parseFloat(parseFloat(refundProtectPrice + refundProtectTaxPrice).toFixed(2))).toLocaleString('en-IN')
                            :
                            ((parseFloat(refundProtectPrice + refundProtectTaxPrice).toFixed(2)) * currencyValue).toFixed(2)
                        }
                      </div>
                    </>}

                  {optedForRefund.mode === "optional" &&
                    <div className="non-refundable-section">
                      <a className={selectedValueForRefund === 0 ? "waves-effect active-refund-details-btn waves-light btn-small text-left-align pay-btn" : "waves-effect not-active-refund-details-btn waves-light btn-small text-left-align pay-btn"}>
                        <label>
                          <input className="with-gap" name="group1" type="radio" value="false" checked={selectedValueForRefund === 0 && true} onChange={() => setSelectedValueForRefund(0)} />
                          <span style={selectedValueForRefund === 0 ? { color: '#ffffff' } : { color: '#555555' }}><i className="non-shield-icon"></i><span className="non-refundable-text">Non-refundable Booking</span></span>
                        </label>
                      </a>
                    </div>}

                </div>} */}
                  {/* refund protect codes */}

                  <div className="row">
                    <div id="full-room-pay" className="full-room-pay">
                      {/* <ul className="clearfix">
                    {payatHotelStatus ? <li>Pay at Hotel</li> : <li>Total Amount</li>}
                    <li>
                      {" "}
                      <i className={currencySymbol} />
                      {parseFloat(parseFloat(partialPaydisplay).toFixed(2)).toLocaleString('en-IN')}
                    </li>
                  </ul>
                  <ul className="clearfix">
                    {payatHotelStatus ? <li>Pay at Hotel</li> : <li>Total Amount</li>}
                    <li>
                      {" "}
                      <i className={currencySymbol} />
                      {parseFloat(parseFloat(partialPaydisplay).toFixed(2)).toLocaleString('en-IN')}
                    </li>
                  </ul> */}
                      <ul className="clearfix">
                        {<li>Pay Now</li>}
                        <li>
                          {" "}
                          <i className={currencySymbol} />
                          {selectedPaymentOptn === "payathotel"
                            ? 0
                            : parseFloat(
                              parseFloat(partialPaydisplay).toFixed(2)
                            ).toLocaleString("en-IN")}
                          {/* {parseFloat(parseFloat(partialPaydisplay).toFixed(2)).toLocaleString('en-IN')} */}
                          {/* {parseFloat(parseFloat(props.cart[0].paid_amount).toFixed(2)).toLocaleString('en-IN')} */}
                        </li>
                      </ul>
                      <ul className="clearfix">
                        {<li>Pay at Hotel</li>}
                        <li>
                          {" "}
                          <i className={currencySymbol} />
                          {selectedPaymentOptn === "payathotel"
                            ? parseFloat(
                              parseFloat(partialPaydisplay).toFixed(2)
                            ).toLocaleString("en-IN")
                            : parseFloat(
                              parseFloat(allTotalPriceDisplay).toFixed(2) -
                              parseFloat(partialPaydisplay).toFixed(2)
                            ).toLocaleString("en-IN")
                            // parseFloat(parseFloat(props.cart[0].paid_amount).toFixed(2)).toLocaleString('en-IN') :
                            // parseFloat(parseFloat(allTotalPriceDisplay).toFixed(2) - parseFloat(props.cart[0].paid_amount).toFixed(2)).toLocaleString('en-IN')
                          }
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div ref={bottomRef}></div>

                  {checkBoxStatus ? <div className="row">
                    <div className="continue-btn-wrap center-align">
                      <a
                        // onClick={() => showUserForm()}
                        onClick={() => paymentOptionToaster()}
                        className="waves-effect waves-light btn-small continue-btn"
                      >
                        {" "}
                        Continue <span className={loading ? "payLoader" : ""} />
                      </a>
                    </div>
                  </div> :
                    !userFormOpen && (
                      <div className="row">
                        <div className="continue-btn-wrap center-align">
                          <a
                            // onClick={() => showUserForm()}
                            onClick={() => showUseFormValidation()}
                            className="waves-effect waves-light btn-small continue-btn"
                          >
                            {" "}
                            Continue <span className={loading ? "payLoader" : ""} />
                          </a>
                        </div>
                      </div>
                    )}


                  {userFormOpen && (
                    <div>
                      <div className="row">
                        <div className="guest-details">
                          <p>Guest Details</p>
                          <div className="row" style={{ marginBottom: "0" }}>
                            <div className="col s12" style={guestTab}>
                              <ul className="tabs">
                                <li className="tab col s6">
                                  <a
                                    href="#personal"
                                    onClick={() => activateTab("personal")}
                                    className={
                                      personalTab && !businessTab ? "active" : ""
                                    }
                                  >
                                    Personal
                                  </a>
                                </li>
                                <li className="tab col s6">
                                  <a
                                    href="#business"
                                    onClick={() => activateTab("business")}
                                    className={
                                      personalTab && businessTab ? "active" : ""
                                    }
                                  >
                                    Business
                                  </a>
                                </li>
                              </ul>
                            </div>
                            {personalTab && (
                              <div id="personal" className="col s12">
                                {userInfoStep == "step1" && (
                                  <div
                                    className="row"
                                    style={{ marginBottom: "0" }}
                                  >
                                    <div className="col s12" style={guestInput}>
                                      <input
                                        value={userState.address}
                                        onChange={(e) =>
                                          handleChange(e.target.value, "address")
                                        }
                                        type="text"
                                        placeholder="Address"
                                      />
                                      {addressErr && (
                                        <p className="red-text text-darken-1">
                                          {addressErr}
                                        </p>
                                      )}
                                    </div>
                                    <div className="col s12" style={guestInput}>
                                      {/* <select
                                    defaultValue={
                                      currency == "BDT" && "Bangladesh"
                                    }
                                    onChange={(e) =>
                                      handleChange(
                                        e.target.value,
                                        "countryName"
                                      )
                                    }
                                    className="browser-default"
                                    style={{ marginTop: "5%" }}
                                  >
                                    {countryCodes &&
                                      countryCodes.map((countryCode, index) => {
                                        return (
                                          <option
                                            key={index}
                                            // selected={
                                            //   currency == 'BDT' &&
                                            //   countryCode.name == 'Bangladesh'
                                            // }
                                            value={countryCode.name}
                                          >
                                            {countryCode.name}
                                          </option>
                                        );
                                      })}
                                  </select> */}

                                      <select
                                        defaultValue={
                                          travelerLocation &&
                                          travelerLocation.country_name
                                        }
                                        onChange={(e) =>
                                          handleChange(
                                            e.target.value,
                                            "countryName"
                                          )
                                        }
                                        className="browser-default"
                                        style={{ marginTop: "5%" }}
                                      >
                                        {allCountry &&
                                          allCountry.map((countryCode, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={countryCode.country_name}
                                              >
                                                {countryCode.country_name}
                                              </option>
                                            );
                                          })}
                                      </select>

                                      {countryNameErr && (
                                        <p className="red-text text-darken-1">
                                          {countryNameErr}
                                        </p>
                                      )}
                                    </div>

                                    <div className="col s12" style={guestInput}>
                                      <select
                                        onChange={(e) =>
                                          handleChange(e.target.value, "state")
                                        }
                                        className="browser-default"
                                        style={{ marginTop: "5%" }}
                                      >
                                        <option>Select State</option>
                                        {allState &&
                                          allState.map((states, idx) => {
                                            return (
                                              <option
                                                key={idx}
                                                value={states.state_name}
                                              >
                                                {states.state_name}
                                              </option>
                                            );
                                          })}
                                      </select>

                                      {stateErr && (
                                        <p className="red-text text-darken-1">
                                          {stateErr}
                                        </p>
                                      )}
                                    </div>

                                    <div className="col s12" style={guestInput}>
                                      <div className="col s7" style={paddingLeft}>
                                        <select
                                          onChange={(e) =>
                                            handleChange(e.target.value, "city")
                                          }
                                          className="browser-default"
                                          style={{ marginTop: "5%" }}
                                        >
                                          <option>Select City</option>
                                          {allCity &&
                                            allCity.map((city, idx) => {
                                              return (
                                                <option
                                                  key={idx}
                                                  value={city.city_name}
                                                >
                                                  {city.city_name}
                                                </option>
                                              );
                                            })}
                                        </select>

                                        {cityErr && (
                                          <p className="red-text text-darken-1">
                                            {cityErr}
                                          </p>
                                        )}
                                      </div>
                                      <div
                                        className="col s5"
                                        style={paddingRight}
                                      >
                                        <input
                                          value={userState.zipcode}
                                          onChange={(e) =>
                                            handleChange(
                                              e.target.value,
                                              "zipcode"
                                            )
                                          }
                                          type="number"
                                          placeholder="Zip Code"
                                        />
                                        {zipCodeErr && (
                                          <p className="red-text text-darken-1">
                                            {zipCodeErr}
                                          </p>
                                        )}
                                      </div>
                                    </div>

                                    {/* Capture guest arrival time and note */}

                                    <div className="col s12" style={guestInput}>
                                      <input
                                        className="class_expected_time_arrival"
                                        value={arrivalTime}
                                        onChange={(e) =>
                                          setArrivalTime(e.target.value)
                                        }
                                        type="text"
                                        placeholder="Expected Time of Arrival"
                                      />
                                    </div>

                                    <div className="col s12" style={guestInput}>
                                      <textarea
                                        className="class_guest_note"
                                        value={guestNote}
                                        onChange={(e) => {
                                          checkGuestNote(e.target.value);
                                        }}
                                        placeholder={guestNoteLabelText}
                                      />

                                      {!guestNote && noteMandatory === 1 && (
                                        <p className="red-text text-darken-1">
                                          {guestNoteLabelText} is mandatory
                                        </p>
                                      )}
                                    </div>

                                    {/* Capture guest arrival time and note */}
                                  </div>
                                )}
                                {userInfoStep == "step0" && (
                                  <div
                                    className="row"
                                    style={{ marginBottom: "0" }}
                                  >
                                    <div className="col s12" style={guestInput}>
                                      <input
                                        value={userState.name || ""}
                                        onChange={(e) =>
                                          handleChange(e.target.value, "name")
                                        }
                                        type="text"
                                        placeholder="Name"
                                      />
                                      {nameErr && (
                                        <p className="red-text text-darken-1">
                                          {nameErr}
                                        </p>
                                      )}
                                    </div>
                                    <div className="col s12" style={guestInput}>
                                      <input
                                        value={userState.emailId || ""}
                                        onChange={(e) =>
                                          handleChange(e.target.value, "emailId")
                                        }
                                        type="email"
                                        placeholder="Email Id"
                                      />
                                      {emailIdErr && (
                                        <p className="red-text text-darken-1">
                                          {emailIdErr}
                                        </p>
                                      )}
                                    </div>
                                    <div className="col s12" style={guestInput}>
                                      <div className="row">
                                        <div className="col s4">
                                          {/* <select
                                        defaultValue={
                                          currency == "BDT" ? "+880" : "+91"
                                        }
                                        onChange={(e) =>
                                          handleChange(
                                            e.target.value,
                                            "countryCode"
                                          )
                                        }
                                        className="browser-default"
                                      >
                                        {countryCodes &&
                                          countryCodes.map(
                                            (countryCode, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={countryCode.dial_code}
                                                  selected={
                                                    currency == "BDT" &&
                                                    countryCode.dial_code ==
                                                    "+880"
                                                  }
                                                >
                                                  {countryCode.dial_code}
                                                </option>
                                              );
                                            }
                                          )}
                                      </select> */}
                                          <select
                                            defaultValue={
                                              travelerLocation &&
                                              travelerLocation.country_dial_code
                                            }
                                            onChange={(e) =>
                                              handleChange(
                                                e.target.value,
                                                "countryCode"
                                              )
                                            }
                                            className="browser-default"
                                          >
                                            ,
                                            {allCountry &&
                                              allCountry.map(
                                                (countryCode, index) => {
                                                  return (
                                                    <option
                                                      key={index}
                                                      value={
                                                        countryCode.country_dial_code
                                                      }
                                                    >
                                                      {
                                                        countryCode.country_dial_code
                                                      }
                                                    </option>
                                                  );
                                                }
                                              )}
                                          </select>
                                          {countryCodeErr && (
                                            <p className="red-text text-darken-1">
                                              {countryCodeErr}
                                            </p>
                                          )}
                                        </div>
                                        <div className="col s8">
                                          <input
                                            value={userState.mobile || ""}
                                            onChange={(e) =>
                                              handleChange(
                                                e.target.value,
                                                "mobile"
                                              )
                                            }
                                            type="number"
                                            placeholder="Mobile Number"
                                          />
                                          {mobileErr && (
                                            <p className="red-text text-darken-1">
                                              {mobileErr}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {userInfoStep === "step4" && (
                                  <div
                                    className="row"
                                    style={{ marginBottom: "0" }}
                                  >
                                    <div
                                      className="col s12 selectMarginTop"
                                      style={guestInput}
                                    >
                                      <select
                                        style={{ marginTop: "0px" }}
                                        value={userState.identity}
                                        onChange={(e) =>
                                          handleChange(e.target.value, "identity")
                                        }
                                        className="browser-default"
                                      >
                                        {identityData &&
                                          identityData.map(
                                            (identityData, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={identityData.name}
                                                >
                                                  {identityData.name}
                                                </option>
                                              );
                                            }
                                          )}
                                      </select>
                                    </div>

                                    <div
                                      className="col s12 selectMarginTop"
                                      style={guestInput}
                                    >
                                      <input
                                        style={{ marginTop: "0px" }}
                                        value={userState.identityno}
                                        onChange={(e) =>
                                          handleChange(
                                            e.target.value,
                                            "identityno"
                                          )
                                        }
                                        type="text"
                                        placeholder="Identity No"
                                      />
                                      {/* {identityNoErr && (
                                    <p className='red-text text-darken-1'>
                                      {identityNoErr}
                                    </p>
                                  )} */}
                                    </div>

                                    {/* <div className="col s12" style={guestInput}>
                                  <div
                                    className="col s6 date_picker_border"
                                    style={paddingLeft}
                                  >
                                    <DatePicker
                                      placeholder="Expiry Date"
                                      value={expiry_date}
                                      onChange={(date) => setExpiryData(date)}
                                      dayPlaceholder="Expiry Date"
                                    />
                                    
                                  </div>

                                  <div className="col s6 date_picker_border">
                                    <DatePicker
                                      value={dob}
                                      onChange={(date) => setDob(date)}
                                      dayPlaceholder="Date of Birth"
                                      selected={new Date()}
                                      maxDate={new Date()}
                                    />
                                    {dobErr && (
                                      <p className="red-text text-darken-1">
                                        {dobErr}
                                      </p>
                                    )}
                                  </div>
                                </div> */}
                                    {/* ------------identity expiry date and date of birth------------- */}
                                    <div className="row">
                                      <div className="col s12">
                                        <DatePicker
                                          placeholder="Expiry Date"
                                          value={expiry_date}
                                          onChange={(date) => setExpiryData(date)}
                                          dayPlaceholder="Expiry Date"
                                          minDate={dateForExpiryDatepicker} //-------calendar start from check-out date------//
                                        />
                                        {/* {expiryDateErr && <p className="red-text text-darken-1">{expiryDateErr}</p> } */}
                                      </div>
                                      <div className="col s12">
                                        <DatePicker
                                          value={dob}
                                          onChange={(date) => setDob(date)}
                                          dayPlaceholder="Date of Birth"
                                          // selected={new Date()}
                                          // maxDate={new Date()}
                                          activeStartDate={dobDatePickerDate} //----land the calendar from the enable date--------//
                                          maxDate={dobDatePickerDate} //----enable date from 18 years ago from currecnt date-----//
                                        />
                                        {dobErr && (
                                          <p className="red-text text-darken-1">
                                            {dobErr}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                    {/* ------------end of identity expiry date and date of birth------------- */}
                                  </div>
                                )}
                              </div>
                            )}

                            {businessTab &&
                              userInfoStep !== "step1" &&
                              userInfoStep !== "step4" && (
                                <div id="business" className="col s12">
                                  <div className="row">
                                    <div className="col s12" style={guestInput}>
                                      <input
                                        style={{ marginTop: "0px" }}
                                        value={userState.companyName}
                                        onChange={(e) =>
                                          handleChange(
                                            e.target.value,
                                            "companyName"
                                          )
                                        }
                                        type="text"
                                        placeholder="Company Name"
                                      />
                                      {companyNameErr && (
                                        <p className="red-text text-darken-1">
                                          {companyNameErr}
                                        </p>
                                      )}
                                    </div>
                                    <div className="col s12" style={guestInput}>
                                      <input
                                        value={userState.GSTIN}
                                        onChange={(e) =>
                                          handleChange(e.target.value, "GSTIN")
                                        }
                                        type="text"
                                        placeholder="GSTIN"
                                      />
                                      {GSTINErr && (
                                        <p className="red-text text-darken-1">
                                          {GSTINErr}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                        {userInfoStep === "step0" && (
                          <div className="pay-btn-wrap center-align">
                            <a
                              onClick={() => continueAddInfo()}
                              className="waves-effect waves-light btn-small pay-btn"
                            >
                              continue
                            </a>
                          </div>
                        )}
                        {/* {userInfoStep === "step1" && currency !== "INR" && ( */}
                        {userInfoStep === "step1" &&
                          hotelData &&
                          hotelData.country_id !== countryID && (
                            <div className="pay-btn-wrap center-align">
                              <a
                                onClick={() => continueAddDetails()}
                                className="waves-effect waves-light btn-small pay-btn"
                              >
                                continue
                              </a>
                            </div>
                          )}
                      </div>

                      {/* {userInfoStep === "step1" && currency === "INR" && ( */}
                      {userInfoStep === "step1" &&
                        hotelData &&
                        hotelData.country_id === countryID && (
                          <div className="row">
                            <div
                              className="pay-btn-wrap center-align"
                              style={{ paddingTop: "0px" }}
                            >
                              {/* {parseFloat(partialPayPercent) === 0 ? */}
                              {policyData.cancel_policy.length > 0 ||
                                policyData.child_policy.length > 0 ||
                                policyData.hotel_policy.length > 0 ||
                                policyData.terms_and_cond.length > 0 ? (
                                <div className="terms-condition">
                                  <ul>
                                    <li
                                      className="modal-trigger"
                                      data-target="modal-terms-condition"
                                    >
                                      <span>
                                        <label>
                                          <input
                                            value={termsCondtionState}
                                            checked={termsCondtionState}
                                            onChange={(e) =>
                                              handleTermsCondition(e)
                                            }
                                            type="checkbox"
                                            className="filled-in"
                                          />
                                          <span>{`\u00A0`}</span>
                                        </label>
                                      </span>
                                      I have read and accept the Hotel's&nbsp;
                                      <span
                                        className="terms_condition_modal"
                                        onClick={toggleTermsConditionModal}
                                      >
                                        Terms & conditions, Privacy policies,
                                        Cancellation Policies.
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              ) : null}

                              {policyData.payment_mode !== 0 &&
                                policyData.payment_mode === 1 && (
                                  <div className="terms-condition">
                                    <ul>
                                      <li
                                        className="modal-trigger"
                                        style={{ fontSize: "0.85rem" }}
                                      >
                                        The payment gateway is currently
                                        undergoing testing, and during this
                                        period, all bookings will be treated as
                                        test bookings.
                                      </li>
                                    </ul>
                                  </div>
                                )}

                              {payatHotelStatus ? (
                                termsCondtionState ? (
                                  <a
                                    onClick={() => payNow()}
                                    className="waves-effect waves-light btn-small pay-btn"
                                  >
                                    Book Now
                                    {/* Pay <i className={currencySymbol} />
                            {parseFloat(partialPaydisplay).toLocaleString('en-IN')}{" "}*/}
                                    <span
                                      className={loading ? "payLoader" : ""}
                                    />
                                  </a>
                                ) : (
                                  <a className="waves-effect waves-light btn-small pay-btn disbale-the-btn">
                                    Book Now
                                    {/* Pay <i className={currencySymbol} />
                            {parseFloat(partialPaydisplay).toLocaleString('en-IN')}{" "}*/}
                                    <span
                                      className={loading ? "payLoader" : ""}
                                    />
                                  </a>
                                )
                              ) : termsCondtionState ? (
                                <a
                                  onClick={() => payNow()}
                                  className="waves-effect waves-light btn-small pay-btn"
                                >
                                  Pay <i className={currencySymbol} />
                                  {parseFloat(partialPaydisplay).toLocaleString(
                                    "en-IN"
                                  )}{" "}
                                  <span className={loading ? "payLoader" : ""} />
                                </a>
                              ) : (
                                <a className="waves-effect waves-light btn-small pay-btn disbale-the-btn">
                                  Pay <i className={currencySymbol} />
                                  {parseFloat(partialPaydisplay).toLocaleString(
                                    "en-IN"
                                  )}{" "}
                                  <span className={loading ? "payLoader" : ""} />
                                </a>
                              )}
                              {/* <a
                          onClick={() => payNow()}
                          className="waves-effect waves-light btn-small pay-btn"
                        >
                          Pay <i className={currencySymbol} />
                          {parseFloat(partialPaydisplay).toLocaleString('en-IN')}{" "}
                          <span className={loading ? "payLoader" : ""} />
                        </a> */}
                            </div>
                          </div>
                        )}


                      {/* {userInfoStep === "step4" && currency !== "INR" && ( */}
                      {userInfoStep === "step4" &&
                        hotelData &&
                        hotelData.country_id !== countryID && (
                          <div className="row">
                            <div className="pay-btn-wrap center-align">
                              {termsCondtionState ? (
                                <a
                                  onClick={() => payNow()}
                                  className="waves-effect waves-light btn-small pay-btn"
                                >
                                  Pay <i className={currencySymbol} />
                                  {parseFloat(partialPaydisplay).toLocaleString(
                                    "en-IN"
                                  )}{" "}
                                  <span className={loading ? "payLoader" : ""} />
                                </a>
                              ) : (
                                <a className="waves-effect waves-light btn-small pay-btn disbale-the-btn">
                                  Pay <i className={currencySymbol} />
                                  {parseFloat(partialPaydisplay).toLocaleString(
                                    "en-IN"
                                  )}{" "}
                                  <span className={loading ? "payLoader" : ""} />
                                </a>
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        {loading && (
          <div className="modal-wrapper">
            <div id="model1" className="modal scroll" />
            <div
              className="modal-overlay"
              style={{
                zIndex: "1002",
                display: "block",
                position: "fixed",
                opacity: "0.1",
              }}
            />
          </div>
        )}
      </div>
      {/*Modal Structure */}
      {isAddOnServiceModelOpen && (
        <div className="modal-wrapper">
          <div id="modal12" className="modal scroll z-value room-details-modal">
            <div className="card" style={{ margin: "0rem" }}>
              <div className="modal-content coupon-box">
                <span
                  onClick={() => addonServicetoggleModal()}
                  className="modalBtnClose"
                />
                <div className="row">
                  <h3 className="center-align">
                    Get the extra paid services
                  </h3>
                  <hr />
                  <div className="coupon-wrapper">
                    {paidServices && paidServices.length == 0 && (
                      <div className="row center-align">
                        <p> No paid services available!</p>
                      </div>
                    )}

                    {paidServices && paidServices.length > 0 && (
                      <div className="row center-align">
                        <div
                          className={
                            isTaxable == 1
                              ? "col s12 m3"
                              : "col s12 m4"
                          }
                        >
                          <p className="paid-service-headings">
                            Service Name
                          </p>
                        </div>
                        <div
                          className={
                            isTaxable == 1
                              ? "col s12 m3"
                              : "col s12 m4"
                          }
                        >
                          <p className="paid-service-headings">
                            Service Amount
                          </p>
                        </div>

                        {isTaxable == 1 && (
                          <div className="col s12 m3">
                            <p className="paid-service-headings">
                              Tax{" "}
                            </p>
                          </div>
                        )}

                        <div
                          className={
                            isTaxable == 1
                              ? "col s12 m3"
                              : "col s12 m4"
                          }
                        />
                      </div>
                    )}

                    {paidServices &&
                      paidServices.length > 0 &&
                      paidServices.map((paidService, index) => {
                        return (
                          <div
                            key={index}
                            className="row center-align"
                          >
                            <div
                              className={
                                isTaxable == 1
                                  ? "col s12 m3 paid-service-data"
                                  : "col s12 m4 paid-service-data"
                              }
                            >
                              {paidService.service_name}
                            </div>
                            <div
                              className={
                                isTaxable == 1
                                  ? "col s12 m3 paid-service-data"
                                  : "col s12 m4 paid-service-data"
                              }
                            >
                              <i className={currencySymbol} />

                              {currency == baseCurrency
                                ? paidService.service_amount.toLocaleString(
                                  "en-IN"
                                )
                                : (
                                  paidService.service_amount *
                                  currencyValue
                                ).toFixed(2)}
                            </div>

                            {/* Paid Service New Codes */}
                            {isTaxable == 1 && (
                              <div className="col s12 m3 paid-service-data">
                                {paidService.service_tax_price !==
                                  "-" ? (
                                  <>
                                    <i
                                      className={currencySymbol}
                                    />
                                    {currency == baseCurrency
                                      ? paidService.service_tax_price.toLocaleString(
                                        "en-IN"
                                      )
                                      : (
                                        paidService.service_tax_price *
                                        currencyValue
                                      ).toFixed(2)}{" "}
                                    (
                                    {currency == baseCurrency
                                      ? paidService.service_tax.toLocaleString(
                                        "en-IN"
                                      )
                                      : (
                                        paidService.service_tax *
                                        currencyValue
                                      ).toFixed(2)}{" "}
                                    % )
                                  </>
                                ) : (
                                  <p>-</p>
                                )}
                              </div>
                            )}
                            {/* Paid Service New Codes */}

                            <div
                              className={
                                isTaxable == 1
                                  ? "col s12 m3"
                                  : "col s12 m4"
                              }
                            >
                              <a
                                onClick={() =>
                                  addService(paidService)
                                }
                                className="waves-effect waves-light btn-small service-btn"
                              >
                                Add Service
                              </a>
                            </div>
                          </div>
                        );
                      })}

                    {/* {paidServices &&
                                  paidServices.length > 0 &&
                                  paidServices.map((paidService, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="row center-align"
                                      >
                                        <div className="col s12 m4">
                                          {paidService.service_name}
                                        </div>
                                        <div className="col s12 m4">
                                          <i className={currencySymbol} />

                                          {currency == baseCurrency ? (paidService.service_amount) : (paidService.service_amount * currencyValue).toFixed(2)}
                                        </div>
                                        <div className="col s12 m4">
                                          <a
                                            onClick={() =>
                                              addService(paidService)
                                            }
                                            className="waves-effect waves-light btn-small service-btn"
                                          >
                                            Add Service
                                          </a>
                                        </div>
                                      </div>
                                    );
                                  })} */}
                  </div>
                </div>
                <p
                  className="warning"
                  style={{ textAlign: "center" }}
                >
                  {limitMessage}
                </p>
                <div className="row">
                  <div className="col s12 m3" />
                  <div className="col s12 m6">
                    {serviceAddMessage && (
                      <div className="couponStitched">
                        {serviceAddMessage}
                      </div>
                    )}
                  </div>
                  <div className="col s12 m3" />
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-overlay"
            style={{
              zIndex: "1002",
              display: "block",
              opacity: "0.5",
            }}
          />
        </div>
      )}

      {/* Model for tems and condition */}
      {modalTermsCondition && (
        <>
          <div className="modal-wrapper">
            <div
              id="modal-terms-condition"
              className="modal scroll"
            >
              <div className="card" style={{ margin: "0rem" }}>
                <div className="modal-content">
                  <span
                    onClick={toggleTermsConditionModal}
                    className="modalBtnClose"
                  />

                  <div>
                    {policyData.terms_and_cond.length > 0 && (
                      <div className="terms__condi__wrapper">
                        <div className="terms__condi__block">
                          Terms And Conditions
                        </div>
                        <div>
                          {parse(policyData.terms_and_cond)}
                        </div>
                      </div>
                    )}

                    {policyData.hotel_policy.length > 0 && (
                      <div className="terms__condi__wrapper">
                        <div className="terms__condi__block">
                          Privacy Policy
                        </div>
                        <div>
                          {parse(policyData.hotel_policy)}
                        </div>
                      </div>
                    )}

                    {policyData.child_policy.length > 0 && (
                      <div className="terms__condi__wrapper">
                        <div className="terms__condi__block">
                          Child Policy
                        </div>
                        <div>
                          {parse(policyData.child_policy)}
                        </div>
                      </div>
                    )}

                    {policyData.cancel_policy.length > 0 && (
                      <div className="terms__condi__wrapper">
                        <div className="terms__condi__block">
                          Cancellation Policy
                        </div>
                        <div>
                          {parse(policyData.cancel_policy)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal-overlay"
            style={{
              zIndex: "1002",
              display: "block",
              opacity: "0.5",
            }}
          />
        </>
      )}

      {/*Modal Structure */}
      {isModalOpen && (
        <div className="modal-wrapper coupon-modal">
          <div
            id="model1"
            className={`modal scroll maxHeight maxHeightUnset ${allAppliedPublicCoupons.length >
              3 && "maxCouponHeight"}`}
          >
            <div className="card" style={{ margin: "0rem" }}>
              <div className="modal-content coupon-box">
                <span
                  onClick={() => toggleModal()}
                  className="modalBtnClose"
                />
                <div className="row">
                  <h3 className="center-align">
                    Apply Coupon Here
                  </h3>

                  {allAppliedPublicCoupons.length > 0 ? (
                    allAppliedPublicCoupons.map(
                      (coupon, index, coupons) => {
                        return (
                          <div
                            key={index}
                            className="col s12 m4 col-margin-top"
                          >
                            <div className="coupon-card gradient">
                              <p className="coupon-message center-align">
                                <span>
                                  Applicable For:{" "}
                                  {coupon.room_type_id === 0
                                    ? "ALL"
                                    : props.cart.map(
                                      (cartItem) => {
                                        if (
                                          cartItem.room_type_id ===
                                          coupon.room_type_id
                                        ) {
                                          return cartItem.room_type;
                                        }
                                      }
                                    )}
                                </span>
                              </p>
                              <h3 className="center-align">
                                Save Upto
                              </h3>
                              <div
                                className={
                                  manageAllAppliedPublicCoupons[
                                    index
                                  ].status === true
                                    ? "coupon-discount green"
                                    : "coupon-discount"
                                }
                              >
                                <span>
                                  {coupon.discount}
                                  <div>%</div>
                                </span>
                              </div>
                              <div className="coupon-code">
                                <span>{coupon.coupon_code}</span>
                              </div>
                              <div className="center-align apply-button-wrapper">
                                {manageAllAppliedPublicCoupons[
                                  index
                                ].status === true ? (
                                  <a className="center-align waves-effect waves-light btn-small apply-btn green">
                                    <span
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      Applied{" "}
                                      <i className="material-icons icon">
                                        check
                                      </i>{" "}
                                    </span>
                                  </a>
                                ) : (
                                  <a className="center-align waves-effect waves-light btn-small apply-btn red">
                                    <span
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      onClick={() =>
                                        applyPublicCoupon(
                                          coupon,
                                          index
                                        )
                                      }
                                    >
                                      Apply
                                    </span>
                                  </a>
                                )}
                              </div>
                              <div>
                                <p className="coupon-message center-align">
                                  <span className="coupon-border">
                                    Valid From:
                                    {moment(
                                      coupon.valid_from
                                    ).format("DD-MMM")}
                                  </span>

                                  <span className="coupon-border">
                                    Valid To:
                                    {moment(
                                      coupon.valid_to
                                    ).format("DD-MMM")}
                                  </span>
                                </p>
                                <p>{coupon.room_type}</p>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )
                  ) : (
                    <h5
                      style={{
                        color: "#000",
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                    >
                      No Public Coupons Applied!
                    </h5>
                  )}
                </div>
                <div className="row">
                  <div className="coupon-input-wrap">
                    <div className="col s12 m10">
                      <input
                        defaultValue={
                          sessionStorage.getItem(
                            "privateCoupon"
                          ) || ""
                        }
                        onChange={(e) =>
                          handlePrivateCoupon(
                            e.target.value,
                            "privateCoupon"
                          )
                        }
                        type="text"
                        placeholder="Have a coupon code type here"
                      />
                      {privateCuponErr && (
                        <p className="red-text text-darken-1">
                          {privateCuponErr}
                        </p>
                      )}
                    </div>

                    <div className="col s12 m2 center-align">
                      {privateCouponApplied ? (
                        <a className="btn coupon-btn green">
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Applied{" "}
                            <i className="material-icons icon">
                              check
                            </i>{" "}
                          </span>
                        </a>
                      ) : (
                        <a
                          className="waves-effect waves-light btn coupon-btn"
                          onClick={() => applyPrivateCupon()}
                        >
                          Apply{" "}
                        </a>
                      )}
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-overlay"
            style={{
              zIndex: "1002",
              display: "block",
              opacity: "0.5",
            }}
          />
        </div>
      )}

      {/* Modal Structure for Non Refundable Bookings  */}
      {isModalOpenNonRefund && (
        <div className="modal-wrapper refund-protect-modal">
          <div
            id="model1"
            className={`modal refund-protect-modal-box scroll maxHeight maxHeightUnset`}
          >
            <div className="card" style={{ margin: "0rem" }}>
              <div className="modal-content coupon-box">
                {/* <span
                              onClick={() => toggleModalNonRefund()}
                              className="modalBtnClose"
                            /> */}
                <div className="row">
                  <h3 className="center-align">
                    Non Refundable <i className="fa fa-shield" />
                  </h3>
                  <hr />
                </div>
                <div className="row center-align">
                  <h6>You selected Non-Refundable Booking!</h6>
                  <h6>Are you sure?</h6>
                  <br />
                  <p>
                    By selecting "Non-Refundable" the basic refund
                    terms of sale will apply which means{" "}
                    <b>
                      you may not be eligible for a refund under
                      any condition.
                    </b>{" "}
                    We would{" "}
                    <b>
                      <u>
                        highly recommended selecting a Refundable
                        Booking
                      </u>
                    </b>{" "}
                    in case any of these risks prevent you from
                    attending your booking.
                  </p>
                  <br />
                  <p>
                    <b>
                      Please reconsider so we can give you the
                      best service so you do not lose &#x20B9;{" "}
                      {parseFloat(
                        parseFloat(partialPaydisplay).toFixed(2)
                      ).toLocaleString("en-IN")}{" "}
                      entirely.
                    </b>
                  </p>
                </div>
                <div className="row">
                  <div className="center-align">
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => reconsiderHandler()}
                    >
                      Reconsider
                    </button>
                  </div>
                </div>
                <div className="row">
                  <b>
                    <u>
                      <p
                        className="center-align proceed-without-refund"
                        onClick={() => proceedNonRefund()}
                      >
                        No, Proceed with Non-Refundable Booking
                      </p>
                    </u>
                  </b>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-overlay"
            style={{
              zIndex: "1002",
              display: "block",
              opacity: "0.5",
            }}
          />
        </div>
      )}
    </>
  );
};
//Export the Cart
export default Cart;
